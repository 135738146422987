import axios from "axios";

let url = process.env.REACT_APP_API_URL
// let url = 'https://api.kapitalboost.com';
// if (process.env.REACT_APP_IS_STAGING) {
//   url = 'https://stagingapi.kapitalboost.com/api/admin'
// }else{
//   url = process.env.NODE_ENV !== "production"
//     ? "http://localhost:8000/api/admin/"
//     : "https://api.kapitalboost.com/api/admin/";
// }

export default axios.create({
  baseURL: url,
});
