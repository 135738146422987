import React, { Component } from "react";

import {
  Container,
  Button,
} from "@material-ui/core";
import api from "services/api";
import MaterialTable, { MTableToolbar } from "material-table";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      campaign: "", //props.location.state.campaign
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
  }

  onStart = async () => {
    const { id } = this.state;
    let campaign_res = await api.get(`campaign/${id}`);
    let campaign = campaign_res.data;
    this.setState({ campaign });
  };

  getPayout = async () => {
    const { id } = this.state;
    console.log(id);
  };

  exportToExcel = async (id) => {
    const { campaign } = this.state;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const dataInvestments = await api.get(
      `campaign-master-payout-report-export/${id}`
    );
    // console.log(dataInvestments);
    const ws = XLSX.utils.json_to_sheet(dataInvestments.data.data);
    // console.log(ws);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    // console.log(wb);
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    // console.log(excelBuffer);
    const blob = new Blob([excelBuffer], {
      type: fileType,
    });
    // console.log(blob);
    return FileSaver.saveAs(
      blob,
      `Payout-report-for-${campaign.name}` + fileExtension
    );
  };

  render() {
    const { id, campaign } = this.state;
    console.log(id);
    console.log(campaign);
    return (
      <Container id="investment-report" maxWidth="xl">
        <MaterialTable
          columns={[
            {
              title: "No",
              field: "ID Investor",
            },
            // { title: 'FX', field: 'fx', },
            { title: "Investor", field: "Investor" },
            { title: "BIC Code", field: "BIC Code" },
            { title: "Account Name", field: "Account Name", type: "text" },
            { title: "Email", field: "Email", type: "email" },
            {
              title: "Account Number",
              field: "Account Number",
              type: "number",
            },
            {
              title: "Payout 1",
              field: "Payout 1",
              type: "currency",
            },
            {
              title: "Payout 2",
              field: "Payout 2",
              type: "currency",
            },
            {
              title: "Payout 3",
              field: "Payout 3",
              type: "currency",
            },
            {
              title: "Payout 4",
              field: "Payout 4",
              type: "currency",
            },
            {
              title: "Payout 5",
              field: "Payout 5",
              type: "currency",
            },
            {
              title: "Payout 6",
              field: "Payout 6",
              type: "currency",
            },
            {
              title: "Payout 7",
              field: "Payout 7",
              type: "currency",
            },
            {
              title: "Payout 8",
              field: "Payout 8",
              type: "currency",
            },
          ]}
          title={`${campaign.name}'s Payout Report`}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: "0px 10px" }}>
                  <Button
                    type="button"
                    variant="contained"
                    style={{
                      marginLeft: 5,
                      backgroundColor: "#28a745",
                      color: "#fff",
                    }}
                    startIcon={<SaveAltIcon />}
                    onClick={() => this.exportToExcel(id)}
                  >
                    Export to Excel
                  </Button>
                </div>
              </div>
            ),
          }}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 100, 200],
            actionsColumnIndex: -1,
            filtering: true,
            search: true,
          }}
          data={(query) =>
            new Promise(async (resolve) => {
              console.log(query);
              let filters = [];
              if (query.filters.length > 0) {
                let column = [];
                let value = [];
                query.filters.map((data) => {
                  column.field = data.column.field;
                  value = data.value;
                  filters.push({ column: column, value: value });
                  return data;
                });
                filters = JSON.stringify(filters);
              }
              const result = await api.get(
                `campaign-master-payout-report?id=${id}&limit=${
                  query.pageSize
                }&offset=${query.page * query.pageSize}&search=${query.search}`
              );
              const { data } = result;
              console.log(data);
              resolve({
                data: data.data,
                page: query.page,
                totalCount: data.total,
              });
            })
          }
        />
      </Container>
    );
  }
}

export default Page;
