import React, { Component, Fragment } from "react";

import {
  Container,
  CircularProgress,
  Paper,
  Snackbar,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  TextField,
  Grid,
  Select,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import api from "services/api";
import hp from "services/hp";
import { format } from "date-fns";
import MaterialTable from "material-table";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      investment: null,
      investmentUpdate: {
        status: null,
        message: "",
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
  }

  onStart = async () => {
    const { id } = this.state;
    const res = await api.get(`investment/${id}`);
    const investment = res.data;
    this.setState({ investment });
  };

  onAttachSub = async (e, props) => {
    let formData = new FormData();
    formData.append("dir", "investment");
    formData.append("image", e.target.files[0]);
    let image_url_res = await api.post("upload-image", formData);
    props.onChange(image_url_res.data.file_name);
  };

  onUpdate = async (e) => {
    e.preventDefault();
    const { id } = this.state;
    await api.post(`investment/${id}`, {
      follow_master_payout: e.target.value,
    });
    this.setState({ openSuccessSnack: true });
    this.onStart();
  };

  onChangePaymentStatus = (e, f) => {
    let { investment } = this.state;
    investment[f] = e.target.value;
    this.setState({ investment });
  };
  onUpdatePayment = async (e) => {
    e.preventDefault();
    const { id, investment } = this.state;
    const res = await api.post(`investment/${id}`, {
      is_paid: investment.is_paid,
      payment_method: investment.payment_method,
    });
    console.log(res);
    if (res.data.error) {
      alert(res.data.message);
    }
    else {
      this.setState({ openSuccessSnack: true });
    }
    this.onStart();
  };

  render() {
    const { id, investment } = this.state;
    return (
      <Container id="investment" maxWidth="xl">
        {investment ? (
          <Fragment>
            <Paper className="padding spaceBottom">
              <Typography variant="h5">
                Investment Detail of <b>{investment.campaign.name}</b>
              </Typography>

              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <TextField
                    label="Investor Full Name"
                    value={
                      investment.user.firstname + " " + investment.user.lastname
                    }
                    fullWidth
                    margin="normal"
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    label="Investor Email Address"
                    value={investment.user.email}
                    fullWidth
                    margin="normal"
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    label="Investor Country"
                    value={investment.user.country}
                    fullWidth
                    margin="normal"
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    label="Investment Date"
                    value={investment.created_at}
                    fullWidth
                    margin="normal"
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Campaign Name"
                    value={investment.campaign.name}
                    fullWidth
                    margin="normal"
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    label="Project Type"
                    value={hp.format_campaign_type(investment.campaign.type)}
                    fullWidth
                    margin="normal"
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    label="Total Funding"
                    value={hp.format_money(investment.amount)}
                    fullWidth
                    margin="normal"
                    InputProps={{ readOnly: true }}
                  />
                  <TextField
                    label="Expected Payout"
                    value={hp.format_money(investment.payout)}
                    fullWidth
                    margin="normal"
                    InputProps={{ readOnly: true }}
                  />
                </Grid>
              </Grid>
            </Paper>
            <Paper className="padding spaceBottom">
              <Typography variant="h5">
                Payment Detail of <b>{investment.campaign.name}</b>
              </Typography>

              <Grid container spacing={4}>
                <Grid item xs={6}>
                  {this.state.investmentUpdate.status === false && (
                    <Alert severity="error">
                      {this.state.investmentUpdate.message}
                    </Alert>
                  )}
                  <FormControl fullWidth margin="normal">
                    <Select
                      value={investment.payment_method}
                      onChange={(e) =>
                        this.onChangePaymentStatus(e, "payment_method")
                      }
                    >
                      <MenuItem value="bank-transfer">Bank Transfer</MenuItem>
                      <MenuItem value="kb-wallet">KB Wallet</MenuItem>
                      <MenuItem value="paypal">Paypal</MenuItem>
                    </Select>
                  </FormControl>

                  {investment.payment_method === "bank-transfer" && (
                    <Fragment>
                      <TextField
                        label="Bank Name"
                        value={investment.bank_name}
                        fullWidth
                        margin="normal"
                        InputProps={{ readOnly: true }}
                      />
                      <TextField
                        label="Account Name"
                        value={investment.bank_account}
                        fullWidth
                        margin="normal"
                        InputProps={{ readOnly: true }}
                      />
                      <TextField
                        label="Account Number"
                        value={investment.bank_number}
                        fullWidth
                        margin="normal"
                        InputProps={{ readOnly: true }}
                      />
                    </Fragment>
                  )}
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Payment Status</InputLabel>
                    <Select
                      value={investment.is_paid}
                      onChange={(e) => this.onChangePaymentStatus(e, "is_paid")}
                    >
                      <MenuItem value={1}>Paid</MenuItem>
                      <MenuItem value={2}>Pending Approval</MenuItem>   
                      <MenuItem value={0}>Unpaid</MenuItem>   
                    </Select>
                  </FormControl>
                  <Button
                    variant="contained"
                    fullWidth
                    className="spaceBig"
                    color="primary"
                    onClick={this.onUpdatePayment}
                  >
                    Update Payment Status
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  {investment.payment_method === "bank-transfer" &&
                  investment.bank_proof ? (
                    <img src={investment.bank_proof} width="100%" alt="img" />
                  ) : (
                    <Typography variant="h6">No document uploaded</Typography>
                  )}
                </Grid>
              </Grid>
            </Paper>
            <Paper className="padding spaceBottom">
              <FormControl fullWidth margin="normal" className="spaceBottomX">
                <InputLabel>Master Payout</InputLabel>
                <Select
                  value={investment.follow_master_payout}
                  onChange={(e) => this.onUpdate(e)}
                >
                  <MenuItem value={true}>Follow</MenuItem>
                  <MenuItem value={false}>Do Not Follow</MenuItem>
                </Select>
              </FormControl>

              <MaterialTable
                columns={[
                  {
                    title: "No",
                    render: (rowData) =>
                      rowData ? rowData.tableData.id + 1 : "",
                  },
                  { title: "Date", field: "date", type: "date" },
                  { title: "Amount", field: "amount", type: "currency" },
                  {
                    title: "Final Amount",
                    field: "final_amount",
                    type: "currency",
                  },
                  {
                    title: "Status",
                    field: "status",
                    lookup: {
                      "on-going": "On Going",
                      paid: "Paid",
                      delayed: "Delayed",
                    },
                  },
                ]}
                title={`Payout Schedule`}
                options={{
                  pageSize: 5,
                  pageSizeOptions: [5, 10, 20],
                  actionsColumnIndex: -1,
                  search: false,
                }}
                data={(query) =>
                  new Promise(async (resolve) => {
                    const result = await api.get(
                      `payout?investment_id=${id}&limit=${
                        query.pageSize
                      }&offset=${query.page * query.pageSize}`
                    );
                    const { data } = result;
                    resolve({
                      data: data.data,
                      page: query.page,
                      totalCount: data.total,
                    });
                  })
                }
                editable={{
                  onRowAdd: (newData) =>
                    new Promise(async (resolve) => {
                      newData.investment_id = id;
                      newData.date = format(
                        new Date(newData.date),
                        "yyyy-MM-dd"
                      );
                      await api.post("payout", newData);
                      resolve();
                    }),
                  onRowUpdate: (newData) =>
                    new Promise(async (resolve) => {
                      delete newData.created_at;
                      delete newData.updated_at;
                      await api.post(`payout/${newData.id}`, newData);
                      resolve();
                    }),
                  onRowDelete: (oldData) =>
                    new Promise(async (resolve) => {
                      await api.delete(`payout/${oldData.id}`);
                      resolve();
                    }),
                }}
              />
            </Paper>
          </Fragment>
        ) : (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.openSuccessSnack}
          autoHideDuration={6000}
          onClose={() => this.setState({ openSuccessSnack: false })}
        >
          <div className="success-alert">
            <Typography>Successfully updated</Typography>
          </div>
        </Snackbar>
      </Container>
    );
  }
}

export default Page;
