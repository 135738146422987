import React, { Component } from "react";

import {
  Container,
  Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import api from "services/api";
import MaterialTable from "material-table";
import { format } from "date-fns";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.tableRef = React.createRef();
    this.state = {
      hasError: false,
      errorMessage: "Something went wrong.",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onDuplicate = async (id) => {
    let c = window.confirm("Please confirm to duplicate campaign");
    if (c === true) {
      await api.post("campaign-duplicate", { campaign_id: id });
      this.tableRef.current && this.tableRef.current.onQueryChange();
    }
  };

  setError = (msg) => {
    this.setState({
      hasError: true,
      errorMessage: msg,
    });
  };

  closeError = () => {
    this.setState({
      hasError: false,
    });
  };

  render() {
    return (
      <Container id="get-funded" maxWidth="xl">
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            { title: "No", field: "id", editable: "never" },
            { title: "Acronim", field: "acronim" },
            { title: "Name", field: "name" },
            {
              title: "Type",
              field: "type",
              initialEditValue: "sme",
              lookup: { sme: "SME", donation: "Donation", private: "Private" },
            },
            {
              title: "Release Date",
              field: "release_datetime",
              type: "datetime",
            },
            {
              title: "Closing Date",
              field: "expiry_datetime",
              type: "datetime",
            },
            {
              title: "Enable",
              field: "is_enable",
              type: "boolean",
              editable: "onUpdate",
            },
            {
              title: "Current Funding",
              field: "investments_count",
              type: "currency",
              editable: "never",
              filtering: false,
            },
            {
              title: "Total Funding",
              field: "total_invest_amount",
              type: "currency",
              filtering: false,
            },
            { title: "Tenor", field: "tenor" },
            {
              title: "Created on",
              field: "created_at",
              editable: "never",
              type: "datetime",
            },
          ]}
          title="Campaigns"
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100, 200],
            actionsColumnIndex: -1,
            filtering: true,
          }}
          data={(query) =>
            new Promise(async (resolve) => {
              let field = query.orderBy ? query.orderBy.field : "";
              let filters = [];
              if (query.filters.length > 0) {
                query.filters.map((data) => {
                  let column = {};
                  let value = [];
                  column.field = data.column.field;
                  value = data.value;
                  filters.push({ column: column, value: value });
                  return data;
                });
                filters = JSON.stringify(filters);
              }
              const result = await api.get(`campaign?limit=${
                query.pageSize
              }&offset=${query.page * query.pageSize}&search=${query.search}
                     &orderDirection=${
                       query.orderDirection
                     }&field=${field}&filters=${filters}`);
              const { data } = result;
              console.log(data);
              resolve({
                data: data.data,
                page: query.page,
                totalCount: data.total,
              });
            })
          }
          editable={{
            onRowAdd: (newData) =>
              new Promise(async (resolve) => {
                newData.release_datetime = format(
                  new Date(newData.release_datetime),
                  "yyyy-MM-dd hh:mm:ss"
                );
                newData.expiry_datetime = format(
                  new Date(newData.expiry_datetime),
                  "yyyy-MM-dd hh:mm:ss"
                );
                await api.post("campaign", newData);
                resolve();
              }),
            onRowDelete: async (oldData) => {
              await api.delete(`campaign/${oldData.id}`).then((res) => {
                if (
                  res.response &&
                  res.response.data &&
                  !res.response.data.status
                ) {
                  this.setError(res.response.data.message);
                }
              });
            },
          }}
          actions={[
            {
              icon: "more_horiz",
              tooltip: "Detail",
              onClick: (e, rowData) => {
                window.open(`/campaign/${rowData.id}`, "_blank");
              },
            },
            {
              icon: "picture_as_pdf",
              tooltip: "Contract",
              onClick: (e, rowData) => {
                window.open(`/campaign-contract/${rowData.id}`);
                // this.props.history.push(`/campaign-contract/${rowData.id}`, {
                //   campaign: rowData,
                // });
              },
            },
            {
              icon: "library_books",
              tooltip: "Investment Report",
              onClick: (e, rowData) => {
                window.open(`/campaign-investment/${rowData.id}`);
                // this.props.history.push(`/campaign-investment/${rowData.id}`, {
                //   campaign: rowData,
                // });
              },
            },
            {
              icon: "assessment",
              tooltip: "Payout Report",
              onClick: (e, rowData) => {
                window.open(`/campaign-master-payout-report/${rowData.id}`);
                // this.props.history.push(
                //   `/campaign-master-payout-report/${rowData.id}`,
                //   {
                //     campaign: rowData,
                //   }
                // );
              },
            },
            {
              icon: "local_atm",
              tooltip: "Master Payout",
              onClick: (e, rowData) => {
                window.open(`/campaign-master-payout/${rowData.id}`);
                // this.props.history.push(
                //   `/campaign-master-payout/${rowData.id}`,
                //   { campaign: rowData }
                // );
              },
            },
            {
              icon: "refresh",
              tooltip: "Refresh Data",
              isFreeAction: true,
              onClick: () =>
                this.tableRef.current && this.tableRef.current.onQueryChange(),
            },
            {
              icon: "update_outlined",
              tooltip: "Campaign Update",
              onClick: (e, rowData) => {
                window.open(`/campaign-updates/${rowData.id}`);
                // this.props.history.push(`/campaign-updates/${rowData.id}`, {
                //   campaign: rowData,
                // });
              },
            },
            {
              icon: "file_copy",
              tooltip: "Duplicate",
              onClick: (e, rowData) => this.onDuplicate(rowData.id),
            },
          ]}
        />
        <Snackbar
          open={this.state.hasError}
          autoHideDuration={6000}
          onClose={this.closeError}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <Alert onClose={this.closeError} severity="error">
            {this.state.errorMessage}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export default Page;
