import React, { Component, Fragment } from "react";
import { Link } from 'react-router-dom';
import Table from "material-table";
import api from "services/api";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

class ContractTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      template: null,
      deleteDialogOpen: false,
    };
    this.tableRef = React.createRef();
    this.btnDeleteRef = React.createRef();
  }

  componentDidMount() {
    //
  }

  loadData = async (query) => {
    const result = {
      data: [],
      page: query.page,
      totalCount: 0,
    };

    const res = await api.get('contract-template', {
      params: query
    });

    if (!res.response) {
      if (!res.data.data.length && query.page > 0) {
        query.page--;
        return this.loadData(query);
      }
      result.data = res.data.data;
      result.totalCount = res.data.total;
    }
    return result;
  }

  reloadData = () => {
    this.tableRef.current && this.tableRef.current.onQueryChange()
  }

  toggleDeleteDialog = async (deleteDialogOpen = false, template = null) => {
    await this.setState({deleteDialogOpen, template});
    this.btnDeleteRef.current && this.btnDeleteRef.current.focus();
  }

  deleteTemplate = async () => {
    if(!this.state.template) return;

    const res = await api.delete(`contract-template/${this.state.template.id}`);
    if (res.response) {
      // error
    } else if (res.data.success) {
      this.toggleDeleteDialog();
      this.reloadData();
    }
  }

  render() {
    return (
      <Fragment>
        <Container>
          <Table 
            tableRef={this.tableRef}
            title="Contract Template"
            options={{
              filtering: false,
              actionsColumnIndex: -1,
              pageSize: 10,
            }}
            columns={[
              { title: 'ID', field: 'id', 
                cellStyle: {
                  width: '1%',
                  maxWidth: '1%'
                }, 
                headerStyle: {
                  width: '1%',
                  maxWidth: '1%'
                }, 
              },
              { 
                title: 'Name',
                field: 'name',
                render: (rowData) =>
                  rowData ? (
                    <Link to={`/contract-template/${rowData.id}`}>{rowData.name}</Link>
                  ) : (
                    ""
                  ), 
              },
            ]}
            data={ (query) => new Promise( (resolve, reject) =>  {
              resolve(this.loadData(query));
            })}
            actions={[
              { 
                icon: 'delete', 
                tooltip: 'Delete', 
                onClick: (e, rowData) => {
                  this.toggleDeleteDialog(true, rowData);
                }
              },
              {
                icon: 'refresh',
                tooltip: 'Refresh Data',
                isFreeAction: true,
                onClick: e => this.reloadData(),
              },
              {
                icon: 'add',
                tooltip: 'Add new template',
                isFreeAction: true,
                onClick: async e => {
                  await api.post('contract-template').then(res => {
                    this.props.history.push(`/contract-template/${res.data.data.id}`);
                    // window.open(`contract-template/${res.data.data.id}`);
                  });
                },
              }
            ]}
          />
        </Container>
        
        {
          this.state.template &&
          <Dialog onClose={(e) => this.toggleDeleteDialog(false)} open={this.state.deleteDialogOpen}>
            <DialogTitle style={{textAlign: 'center'}}>Are you sure ?</DialogTitle>
            <DialogContent className="space">
              data : {this.state.template.name}
            </DialogContent>
            <DialogActions>
              <Button onClick={e => this.toggleDeleteDialog(false)}>Cancel</Button>
              <Button ref={this.btnDeleteRef} variant="contained" className="btn-danger" onClick={this.deleteTemplate}>Delete</Button>
            </DialogActions>
          </Dialog>
        }

      </Fragment>
    );
  }
}

export default ContractTemplate;