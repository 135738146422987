import { Container } from "@material-ui/core";
import React, { Component } from "react";
import Table from "material-table";
import api from "services/api";

class Referal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      //
    };
    this.tableRef = React.createRef();
  }

  loadData = async (query) => {
    const result = {
      data: [],
      page: query.page,
      totalCount: 0,
    };

    const res = await api.get("referal", {
      params: query,
    });

    if (!res.response) {
      if (!res.data.data.length && query.page > 0) {
        query.page--;
        return this.loadData(query);
      }
      result.data = res.data.data;
      console.log(result.data);
      result.totalCount = res.data.total;
    }
    return result;
  };

  render() {
    return (
      <Container>
        <Table
          ref={this.tableRef}
          title="Referal"
          options={{
            filtering: false,
            pageSize: 10,
          }}
          columns={[
            { title: "No", field: "id" },
            { title: "Referer Name", field: "upline_user.ic_name" },
            { title: "Referer Email", field: "upline_user.email" },
            { title: "Referal Name", field: "user.ic_name" },
            { title: "Referal Email", field: "user.email" },
            { title: "Invest Amount", field: "investment.amount" },
            { title: "Campaign Name", field: "investment.campaign.name" },
            { title: "Reward for Referer", field: "cashback" },
            { title: "Reward for Referal", field: "cashback" },
          ]}
          data={(query) =>
            new Promise( async(resolve) => {
              let field = query.orderBy ? query.orderBy.field : "";
              let filters = [];
              if (query.filters.length > 0) {
                let column = {};
                let value = [];
                query.filters.map((data) => {
                  column.field = data.column.field;
                  value = data.value;
                  filters.push({ column: column, value: value });
                  return data;
                });
                filters = JSON.stringify(filters);
              }
              const result = await api.get(`referal?limit=${
                query.pageSize
              }&offset=${query.page * query.pageSize}&search=${query.search}
                     &orderDirection=${
                       query.orderDirection
                     }&field=${field}&filters=${filters}`);
              const { data } = result;
              resolve({
                data: data.data,
                page: query.page,
                totalCount: data.total,
              });
            })
          }
        ></Table>
      </Container>
    );
  }
}

export default Referal;
