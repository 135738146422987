import { Container } from "@material-ui/core";
import MaterialTable from "material-table";
import React, { Component } from "react";
import api from "services/api";

export default class Banks extends Component {
  render() {
    return (
      <Container>
        <MaterialTable
          columns={[
            { title: "Bank Code", field: "bank_code", type: "numeric" },
            { title: "BIC Code", field: "bic_code" },
            { title: "IBAN Code", field: "iban_code" },
            { title: "Name", field: "bank_name" },
            { title: "Currency", field: "currency" },
            { title: "Country", field: "country" },
            {
              title: "Status",
              lookup: { 1: "Enable", 0: "Disable" },
              field: "is_enable",
            },
          ]}
          title="Banks"
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100, 200],
            actionsColumnIndex: -1,
            sorting: true,
          }}
          data={(query) =>
            new Promise(async (resolve) => {
              console.log(query);
              const result = await api.get(`banks`, {
                params: query,
              });
              const { data } = result;
              resolve({
                data: data.data,
                page: query.page,
                totalCount: data.total,
              });
            })
          }
          editable={{
            onRowAdd: (newData) =>
              new Promise(async (resolve) => {
                await api.post(`banks`, newData);
                resolve();
              }),
            onRowUpdate: (newData, oldData) =>
              new Promise(async (resolve) => {
                await api.put(`banks/${oldData.id}`, newData);
                resolve();
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve) => {
                await api.delete(`banks/${oldData.id}`);
                resolve();
              }),
          }}
        />
      </Container>
    );
  }
}
