import React, { Component } from "react";

import {
  Container,
  CircularProgress,
  Checkbox,
  Paper,
  Snackbar,
  Typography,
  Button,
  TextField,
  FormControlLabel,
} from "@material-ui/core";
import api from "services/api";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      partner: null,
      openSuccessSnack: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
  }
  onStart = async () => {
    const { id } = this.state;
    const res = await api.get(`partner/${id}`);
    const partner = res.data;
    this.setState({ partner });
  };

  onUpdate = async (e) => {
    e.preventDefault();
    const { partner, id } = this.state;

    const res = await api.post(`partner/${id}`, partner);
    const { data } = res;
    if (data.error) {
      alert(data.message);
    }
    window.scrollTo(0, 0);
    this.setState({ openSuccessSnack: true });
    this.props.history.push(`/partner`);
    this.onStart();
  };

  onChange = (e, f) => {
    let { partner } = this.state;
    partner[f] = e.target.value;
    this.setState({ partner });
  };

  render() {
    const { partner } = this.state;
    return (
      <Container id="partner" maxWidth="xl">
        {partner ? (
          <Paper className="padding">
            <form onSubmit={this.onUpdate}>
              <TextField
                margin="normal"
                label="Partner"
                type="text"
                fullWidth
                required
                value={partner.name}
                onChange={(e) => this.onChange(e, "name")}
              />
              <TextField
                margin="normal"
                label="Url"
                type="text"
                fullWidth
                required
                value={
                  process.env.REACT_APP_LP_URL+"/campaign?page=1&a=" + partner.id
                }
                readOnly
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={partner.active}
                    onChange={(e) => {
                      const {partner} = this.state;
                      partner.active = !partner.active;
                      this.setState({partner});
                    }}
                  />
                }
                label="Active"
              />
              <Button
                variant="contained"
                type="submit"
                fullWidth
                className="spaceBig"
                color="primary"
              >
                Update
              </Button>
            </form>
          </Paper>
        ) : (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.openSuccessSnack}
          autoHideDuration={6000}
          onClose={() => this.setState({ openSuccessSnack: false })}
        >
          <div className="success-alert">
            <Typography>Successfully updated</Typography>
          </div>
        </Snackbar>
      </Container>
    );
  }
}

export default Page;
