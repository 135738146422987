import React, { Component } from "react";

import {
  Container,
  CircularProgress,
  Paper,
  Snackbar,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  TextField,
  Select,
} from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";
import api from "services/api";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      faq: null,
      openSuccessSnack: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
  }
  onStart = async () => {
    const { id } = this.state;
    const res = await api.get(`faq/${id}`);
    const faq = res.data;
    this.setState({ faq });
  };

  onUpdate = async (e) => {
    e.preventDefault();
    const { faq, id } = this.state;

    const res = await api.post(`faq/${id}`, faq);
    const { data } = res;
    if (data.error) {
      alert(data.message);
    }
    window.scrollTo(0, 0);
    this.setState({ openSuccessSnack: true });
    this.props.history.push(`/faq`);
    this.onStart();
  };

  onChange = (e, f) => {
    let { faq } = this.state;
    faq[f] = e.target.value;
    this.setState({ faq });
  };

  handleEditorChange = (content, editor) => {
    let { faq } = this.state;
    faq.answer = content;
    this.setState({ faq });
  };

  render() {
    const { faq } = this.state;
    return (
      <Container id="faq" maxWidth="xl">
        {faq ? (
          <Paper className="padding">
            <form onSubmit={this.onUpdate}>
              <TextField
                margin="normal"
                label="Question"
                type="text"
                fullWidth
                required
                value={faq.question}
                onChange={(e) => this.onChange(e, "question")}
              />

              <FormControl fullWidth margin="normal">
                <InputLabel>Category</InputLabel>
                <Select
                  value={faq.category}
                  onChange={(e) => this.onChange(e, "category")}
                >
                  <MenuItem value="sme">For SME</MenuItem>
                  <MenuItem value="financier">For Financiers(Members)</MenuItem>
                  <MenuItem value="donation">For Donation</MenuItem>
                  <MenuItem value="ewallet">e-Wallet</MenuItem>
                </Select>
              </FormControl>

              <Editor
                apiKey="kjwvahkftdzi9tbun3uhmtrvb01k9419m6vy9eiahopwc8e0"
                value={faq.answer}
                id="faq-answer"
                className="spaceBottom"
                init={{
                  height: 500,
                  menubar:
                    "file edit view insert format tools table help custom",
                  menu: {
                    custom: {
                      title: "Dynamic Data",
                      items:
                        "usermenu campaignmenu masterpayoutmenu contractmenu",
                    },
                  },
                  plugins: [
                    "advlist autolink lists link image charmap anchor pagebreak",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  placeholder: "Type answer here",
                  toolbar:
                    "undo redo | formatselect fontsizeselect | bold italic forecolor backcolor | image searchreplace | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | pagebreak | fullscreen",
                  automatic_uploads: false,
                  images_upload_url:
                    "https://api.kapitalboost.com/api/admin/upload-image",
                  content_style:
                    "code { color: white; background-color: #2f628a !important; }; ",
                  fontsize_formats:
                    "8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 36pt",
                }}
                onEditorChange={this.handleEditorChange}
              />

              <Button
                variant="contained"
                type="submit"
                fullWidth
                className="spaceBig"
                color="primary"
              >
                Update
              </Button>
            </form>
          </Paper>
        ) : (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.openSuccessSnack}
          autoHideDuration={6000}
          onClose={() => this.setState({ openSuccessSnack: false })}
        >
          <div className="success-alert">
            <Typography>Successfully updated</Typography>
          </div>
        </Snackbar>
      </Container>
    );
  }
}

export default Page;
