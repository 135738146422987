import React, { Component } from "react";

import {
  Container,
} from "@material-ui/core";
import api from "services/api";
import hp from "services/hp";
import MaterialTable from "material-table";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      campaign: props.location.state.campaign,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { id, campaign } = this.state;
    return (
      <Container id="investment-report" maxWidth="xl">
        <MaterialTable
          columns={[
            {
              title: "No",
              render: (rowData) => (rowData ? rowData.tableData.id + 1 : ""),
            },
            // { title: 'FX', field: 'fx', },
            { title: "Percentage", field: "percentage" },
            { 
              title: "Project Return", 
              field: "return",
              initialEditValue: this.state.campaign.return,
            },
            { title: "Date", field: "date", type: "date" },
            {
              title: "Status",
              field: "status",
              initialEditValue: "on-going",
              lookup: {
                "on-going": "On Going",
                paid: "Paid",
                delayed: "Delayed",
              },
            },
            {
              title: "Payout Amount",
              field: "percentage",
              type: "currency",
              render: (rowData) =>
                hp.format_money(
                  parseInt(rowData.current_amount) *
                    (1 + rowData.return * 0.01) *
                    (rowData.percentage * 0.01) *
                    rowData.fx
                ),
              editComponent: (props) => <div></div>,
            },
          ]}
          title={`${campaign.name}'s Master Payout History`}
          options={{
            pageSize: 20,
            pageSizeOptions: [20, 100, 200],
            actionsColumnIndex: -1,
            search: false,
          }}
          data={(query) =>
            new Promise(async (resolve) => {
              const result = await api.get(
                `campaign-master-payout?campaign_id=${id}&history=1&limit=${
                  query.pageSize
                }&offset=${query.page * query.pageSize}&search=${query.search}`
              );
              const { data } = result;
              resolve({
                data: data.data,
                page: query.page,
                totalCount: data.total,
              });
            })
          }
          actions={[
            {
              icon: "more_horiz",
              tooltip: "Summary",
              onClick: (e, rowData) => {
                window.open(
                  `/campaign-master-payout-summary/${rowData.id}`,
                  "_blank"
                );
              },
            },
          ]}
        />
      </Container>
    );
  }
}

export default Page;
