import React, {Component} from 'react'

import {Container, Chip, CircularProgress, Paper, Snackbar, MenuItem, FormControl, Typography, Button, InputLabel, TextField, Grid, Select} from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import api from 'services/api';

const TINY_APIKEY = process.env.REACT_APP_TINY_APIKEY

class Blog extends Component {
   constructor(props) {
      super(props)
      this.state = {
         id: props.match.params.id,
         blog: null,
         openSuccessSnack: false,
         image: null,
         new_tag: '',
      }
   }

   componentDidMount() {
      window.scrollTo(0,0)
      this.onStart()
   }

   onStart = async() => {
      const {id} = this.state
      const res = await api.get(`blog/${id}`)
      const blog = res.data
      this.setState({ blog })
   }

   onUpdate = async(e) => {
      e.preventDefault()
      const {blog, id, image} = this.state
      let formData = new FormData()
      if(image) {
         formData.append('image', image)
      }
      let required = ['title', 'is_enable', 'content', 'language', 'tags']
      required.forEach((require, idx) => {
         if(require === 'is_enable') {
            if(blog.is_enable) {
               blog.is_enable = 1
            }else {
               blog.is_enable = 0
            }
         }
         if(require === 'tags') {
            if(blog.tags && blog.tags.length > 0) {
               for (var i = 0; i < blog.tags.length; i++) {
                  formData.append('tags[]',blog.tags[i])
               }
            }else {
               formData.append('tags', [""])
            }
         }else {
            formData.append(require, blog[require])
         }
      })
      const res = await api.post(`blog/${id}`, formData)
      const {data} = res
      if(data.error) {
         alert(data.message)
      }
      window.scrollTo(0, 0)
      this.setState({ openSuccessSnack: true, image: null })
      this.onStart()
   }

   onChange = (e, f) => {
      let {blog} = this.state
      blog[f] = e.target.value
      this.setState({ blog })
   }
   onAttach = (e) => {
      let image = e.target.files[0]
      this.setState({ image })
   }
   onDeleteChip = (i) => {
      let {blog} = this.state
      blog.tags.splice(i, 1)
      this.setState({ blog })
   }
   onAddTag = (e) => {
      let {blog, new_tag} = this.state
      if(blog.tags) {
         blog.tags.unshift(new_tag)
      }else{
         blog.tags = [new_tag]
      }
      this.setState({ blog, new_tag: '' })
   }

   handleEditorChange = (content, editor) => {
     let {blog} = this.state
     blog.content = content
     this.setState({ blog })
   }

   render() {
      const {blog, image, new_tag} = this.state
      return (
         <Container id="blog" maxWidth="xl">
            {blog ?
               <Paper className="padding">
                  <form onSubmit={this.onUpdate}>

                     <TextField margin="normal" label="Title" type="text" fullWidth required value={blog.title} onChange={(e)=>this.onChange(e, 'title')} />

                     <Grid container spacing={4} className="spaceBig" >
                        <Grid item xs={6}  >
                           {blog.image && <img src={blog.image} width="100%" alt="img" /> }
                           <input accept="image/*" id="blog-image-file" type="file" style={{ display: 'none' }} onChange={this.onAttach} />
                           <label htmlFor="blog-image-file">
                              <Button variant="contained" color="secondary" component="span" className="space"  >
                                 Browse
                              </Button>
                           </label>
                           {image &&
                              <Typography>Attached: {image.name}</Typography>
                           }
                        </Grid>
                        <Grid item xs={6} >
                           <FormControl fullWidth margin="normal" required  >
                              <InputLabel >Status</InputLabel>
                              <Select value={blog.is_enable} onChange={(e)=>this.onChange(e, 'is_enable')}  >
                                 <MenuItem value={true} >Enable</MenuItem>
                                 <MenuItem value={false} >Disable</MenuItem>
                              </Select>
                           </FormControl>
                           <FormControl fullWidth margin="normal" required  >
                              <InputLabel >Language</InputLabel>
                              <Select value={blog.language} onChange={(e)=>this.onChange(e, 'language')}  >
                                 <MenuItem value='english' >English</MenuItem>
                                 <MenuItem value='bahasa' >Bahasa</MenuItem>
                              </Select>
                           </FormControl>

                           <Grid container alignItems="center" className="spaceBig" >
                              <Grid item xs={9} >
                                 <TextField label="New Tag" type="text"  value={new_tag} onChange={(e)=>this.setState({ new_tag: e.target.value })} fullWidth />
                              </Grid>
                              <Grid item xs={3} >
                                 <Button variant="contained" onClick={this.onAddTag} color="secondary" fullWidth >Add</Button>
                              </Grid>
                           </Grid>
                           <Typography className="dim small space">Current Tags</Typography>
                           {blog.tags && blog.tags.length > 0 && blog.tags.map((tag, i) => (
                              <Chip key={i} label={tag} onDelete={()=>this.onDeleteChip(i)} color="primary" />
                           ))}

                        </Grid>

                     </Grid>

                     <Editor apiKey={TINY_APIKEY}
                       value={blog.content} id="blog-content"
                       init={{
                         height: 500,
                         menubar: "file edit view insert format tools table help custom",
                         menu: {
                           custom: { title: 'Dynamic Data', items: "usermenu campaignmenu masterpayoutmenu contractmenu"},
                         },
                         plugins: [
                           'advlist autolink lists link image charmap anchor pagebreak',
                           'searchreplace visualblocks code fullscreen',
                           'insertdatetime media table paste code help wordcount'
                         ],
                         toolbar:
                         'undo redo | formatselect fontsizeselect | bold italic forecolor backcolor | image searchreplace | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | pagebreak | fullscreen',
                         automatic_uploads: false,
                         images_upload_url: 'https://api.kapitalboost.com/api/admin/upload-image',
                         content_style: 'code { color: white; background-color: #2f628a !important; }; ',
                         fontsize_formats: "8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 36pt"
                       }}
                       onEditorChange={this.handleEditorChange}
                     />


                     <Button variant="contained" type="submit" fullWidth className="spaceBig" color="primary" >Update</Button>

                  </form>
               </Paper>
               :
               <div className="loading"><CircularProgress /></div>
            }
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right', }} open={this.state.openSuccessSnack} autoHideDuration={6000} onClose={()=>this.setState({ openSuccessSnack: false })}>
               <div className="success-alert" >
                  <Typography>Successfully updated</Typography>
               </div>
            </Snackbar>
         </Container>
      )
   }
}

export default Blog
