import React, { Component } from "react";

import {
  Container,
  CircularProgress,
  Paper,
  Snackbar,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import api from "services/api";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      campaign: "", //props.location.state.campaign,
      update: null,
      openSuccessSnack: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
  }
  onStart = async () => {
    const { id } = this.state;
    let update_res = await api.get(`campaign-update/${id}`);
    let campaign_res = await api.get(`campaign/${update_res.data.campaign_id}`);
    let update = update_res.data;
    let campaign = campaign_res.data;
    console.log(campaign);
    console.log(update);
    this.setState({ update, campaign });
  };
  onChange = (e, f) => {
    let { update } = this.state;
    update[f] = e.target.value;
    this.setState({ update });
  };
  onAttach = async (e) => {
    let { update } = this.state;
    let formData = new FormData();
    formData.append("dir", "campaign-update");
    formData.append("image", e.target.files[0]);
    let image_url_res = await api.post("upload-image", formData);
    if (update.images) {
      update.images.push(image_url_res.data.file_name);
    } else {
      update.images = [image_url_res.data.file_name];
    }
    this.setState({ update });
  };

  onUpdate = async (e) => {
    e.preventDefault();
    let { update, id } = this.state;
    await api.post(`campaign-update/${id}`, update);
    this.setState({ openSuccessSnack: true });
  };

  render() {
    const {  campaign, update } = this.state;
    return (
      <Container maxWidth="xl" id="campaign-update">
        {update ? (
          <form onSubmit={this.onUpdate}>
            <Paper className="padding spaceBottom">
              <Typography>Campaign update for {campaign.name} </Typography>

              <TextField
                margin="normal"
                label="Title"
                type="text"
                fullWidth
                value={update.title}
                onChange={(e) => this.onChange(e, "title")}
              />
              <TextField
                margin="normal"
                label="Content"
                type="text"
                fullWidth
                value={update.content}
                onChange={(e) => this.onChange(e, "content")}
                multiline={true}
                variant="outlined"
                rows={10}
              />

              <Typography className="space">Files</Typography>
              {update.images &&
                update.images.length > 0 &&
                update.images.map((image, i) => (
                  <div key={i}>
                    <a href={image} target="_blank" rel="noopener noreferrer">
                      Open
                    </a>
                  </div>
                ))}
              <input
                id="campaign-update-file"
                type="file"
                style={{ display: "none" }}
                onChange={(e) => this.onAttach(e)}
              />
              <label htmlFor="campaign-update-file">
                <Button
                  variant="contained"
                  color="secondary"
                  component="span"
                  className="spaceBig"
                >
                  Add File
                </Button>
              </label>
            </Paper>
            <Paper className="spaceBottomX padding">
              <Button
                variant="contained"
                type="submit"
                fullWidth
                className="spaceBig"
                color="primary"
              >
                Update
              </Button>
            </Paper>
          </form>
        ) : (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.openSuccessSnack}
          autoHideDuration={6000}
          onClose={() => this.setState({ openSuccessSnack: false })}
        >
          <div className="success-alert">
            <Typography>Successfully updated</Typography>
          </div>
        </Snackbar>
      </Container>
    );
  }
}

export default Page;
