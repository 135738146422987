import React from 'react'
import { Checkbox, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CustomFilter = props => {
    const { data } = props
    return (
        <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={data}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(option, { selected }) => (
                <React.Fragment>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                    />
                    {option.name}
                </React.Fragment>
            )}
            style={{ width: 200 }}
            size="small"
            renderInput={(params) => (
                <TextField {...params} variant="outlined" label="Search" />
            )}
            onChange={(event, newValue) => {
                props.onFilterChanged(props.columnDef.tableData.id, newValue);
            }}
        />
    );
}
export default CustomFilter