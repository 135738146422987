import React, { Component } from 'react'

import { Container } from '@material-ui/core'
import api from 'services/api'
import MaterialTable from "material-table"

class Page extends Component {

   constructor(props) {
      super(props)
      this.state = {

      }
      this.tableRef = React.createRef();
   }
   componentDidMount() {
      window.scrollTo(0, 0)
   }

   render() {

      return (
         <Container id="contacts" maxWidth="xl" >
            <MaterialTable tableRef={this.tableRef}
               columns={[
                  { title: 'No', field: 'id' },
                  { title: "Email", field: "email" },
                  { title: "Message", field: "info", cellStyle: { whiteSpace: 'pre-line' } },

                  { title: 'Created on', field: 'created_at', editable: 'never', type: 'datetime' },
               ]}
               title="Contact Form"
               options={{
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100, 200],
                  actionsColumnIndex: -1,
                  filtering: true
               }}
               data={query =>
                  new Promise(async (resolve) => {
                     try {
                        let field = query.orderBy ? query.orderBy.field : ''
                        let filters = []
                        if (query.filters.length > 0) {
                           let column = {}
                           let value = []
                           query.filters.map(data => {
                              column.field = data.column.field
                              value = data.value
                              filters.push({ 'column': column, 'value': value })
                              return data;
                           })
                           filters = JSON.stringify(filters)
                        }
                        const result = await api.get(`contact?limit=${query.pageSize}&offset=${query.page * query.pageSize}&search=${query.search}
                        &orderDirection=${query.orderDirection}&field=${field}&filters=${filters}`)
                        const { data } = result
                        resolve({
                           data: data.data,
                           page: query.page,
                           totalCount: data.total,
                        })
                     } catch (e) { }
                  })
               }
               onRowClick={(e, rowData) => this.props.history.push(`/contact/${rowData.id}`)}

               editable={{
                  onRowAdd: newData =>
                     new Promise(async (resolve) => {
                        await api.post('contact', newData)
                        resolve()
                     }),
                  onRowDelete: oldData =>
                     new Promise(async (resolve) => {
                        await api.delete(`contact/${oldData.id}`)
                        resolve()
                     })
               }}
               actions={[
                  {
                     icon: 'refresh',
                     tooltip: 'Refresh Data',
                     isFreeAction: true,
                     onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
                  },
               ]}

            />
         </Container>
      )
   }
}

export default Page
