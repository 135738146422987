import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import {
  CircularProgress,
  Paper,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import api_public from "services/api_public";
import hp from "services/hp";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";

import Logo from "images/kapitalboost-logo.png";

class Page extends Component {
  state = {
    login_loading: false,
    email: "",
    password: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onChange = (e, f) => {
    let { state } = this;
    state[f] = e.target.value;
    this.setState({ state });
  };
  onLogin = async (e) => {
    e.preventDefault();
    const { email, password } = this.state;
    this.setState({ login_loading: true });
    const recaptcha_token = await this.props.googleReCaptchaProps.executeRecaptcha(
      "login"
    );
    const res = await api_public.post("login", {
      email,
      password,
      recaptcha_token,
    });
    const { data } = res;
    this.setState({ login_loading: false });
    if (data.error) {
      // alert("error");
      alert(data.message);
      return;
    }
    if (data.expired) {
      alert(data.message);
      window.location.href = "/create-new-password";
    }
    hp.save_admin(data.admin);
    localStorage.setItem("id", data.login.id);
    localStorage.setItem("token", data.login.token);
    window.location.href = "/";
  };

  render() {
    const { login_loading } = this.state;
    const { from } = this.props.location.state || { from: { pathname: "/" } };

    if (hp.is_login()) {
      return <Redirect to={from} />;
    } else {
      return (
        <div id="login">
          <Paper elevation={3} id="paper">
            <div className="padding">
              <div className="center space">
                <img src={Logo} width="70%" alt="img" />
              </div>

              <Typography className="spaceBig">
                Welcome to Kapitalboost Admin
              </Typography>

              <form onSubmit={this.onLogin}>
                <TextField
                  autoFocus
                  margin="normal"
                  label="Email address"
                  type="email"
                  fullWidth
                  required
                  onChange={(e) => this.onChange(e, "email")}
                />
                <TextField
                  margin="normal"
                  label="Password"
                  type="password"
                  fullWidth
                  required
                  onChange={(e) => this.onChange(e, "password")}
                />

                {login_loading ? (
                  <Button
                    margin="normal"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className="space spaceBottomX"
                  >
                    <CircularProgress color="inherit" size={25} />
                  </Button>
                ) : (
                  <Button
                    margin="normal"
                    variant="contained"
                    color="primary"
                    fullWidth
                    type="submit"
                    className="space spaceBottomX"
                  >
                    Login
                  </Button>
                )}
              </form>
            </div>
          </Paper>
        </div>
      );
    }
  }
}

export default withGoogleReCaptcha(Page);
