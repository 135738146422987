import React, { Component } from "react";

import {
  Container,
  Button,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import api from "services/api";
import MaterialTable, { MTableToolbar } from "material-table";

class Page extends Component {
  state = {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Container id="faqs" maxWidth="xl">
        <MaterialTable
          columns={[
            { title: "No", field: "id" },
            {
              title: "Question",
              field: "question",
              width: "10%",
              editComponent: (props) => (
                <TextField
                  type="text"
                  value={props.value}
                  onChange={(e) => props.onChange(e.target.value)}
                  multiline
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              ),
            },
            {
              title: "Answer",
              field: "answer",
              width: "10%",
              editComponent: (props) => (
                <TextField
                  type="text"
                  value={props.value}
                  onChange={(e) => props.onChange(e.target.value)}
                  multiline
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              ),
            },
            {
              title: "Category",
              field: "category",
              lookup: {
                sme: "For SMEs",
                financier: "For Financiers(Members)",
                donation: "For Donation",
                ewallet: "E-Wallet",
              },
            },
            {
              title: "Last Update",
              field: "updated_at",
              editable: "never",
              type: "datetime",
            },
            {
              title: "Created on",
              field: "created_at",
              editable: "never",
              type: "datetime",
            },
          ]}
          title="Faqs"
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: "0px 10px" }}>
                  <Button
                    type="button"
                    variant="contained"
                    style={{
                      marginLeft: 5,
                      backgroundColor: "#0069d9",
                      color: "#fff",
                    }}
                    startIcon={<AddIcon />}
                    onClick={() => this.props.history.push("faqs-add")}
                  >
                    Add FAQ's
                  </Button>
                </div>
              </div>
            ),
          }}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100, 200],
            actionsColumnIndex: -1,
            filtering: true,
          }}
          data={(query) =>
            new Promise(async (resolve) => {
              let field = query.orderBy ? query.orderBy.field : "";
              let filters = [];
              if (query.filters.length > 0) {
                let column = {};
                let value = [];
                query.filters.map((data) => {
                  column.field = data.column.field;
                  value = data.value;
                  filters.push({ column: column, value: value });
                  return data;
                });
                filters = JSON.stringify(filters);
              }
              const result = await api.get(`faq?limit=${
                query.pageSize
              }&offset=${query.page * query.pageSize}&search=${query.search}
                     &orderDirection=${
                       query.orderDirection
                     }&field=${field}&filters=${filters}`);
              const { data } = result;
              resolve({
                data: data.data,
                page: query.page,
                totalCount: data.total,
              });
            })
          }
          onRowClick={(e, rowData) =>
            this.props.history.push(`/faq/${rowData.id}`)
          }
          editable={{
            onRowDelete: (oldData) =>
              new Promise(async (resolve) => {
                await api.delete(`faq/${oldData.id}`);
                resolve();
              }),
          }}
        />
      </Container>
    );
  }
}

export default Page;
