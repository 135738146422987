import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import {
  Container,
  IconButton,
  CircularProgress,
  Paper,
  Snackbar,
  Tabs,
  Tab,
  Typography,
  Icon,
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
} from "@material-ui/core";
import WorkIcon from '@material-ui/icons/Work';
import api from "services/api";
import hp from "services/hp";
import MaterialTable from "material-table";
import ContractData from './Contract/ContractData';
import ContractEditor from './Contract/ContractEditor';
import DataReminder from './Contract/DataReminder';
import moment from 'moment-timezone';


class CampaignContract extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      campaign: "",
      contract: null,
      openSnack: false,
      snackType: true,
      snackMessage: '',
      fee_kb: "",
      fee_return: "",
      total_payout: "",
      total_funding: "",
      investments: null,
      preview_investment_id: null,
      open_saved_template: false,
      saved_templates: null,
      saved_template_show_hidden: false,
      selected_st: null,
      show_rename: false,
      show_save: false,
      save_name: "",
      tab: 0,
      send_loading: false,
      eversignLogOpen: false,
      eversignDocument: null,
    };
    this.contractTable = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getInvestment();
    this.onStart();
  }

  onStart = async () => {
    const { id } = this.state;
    let contract_res = await api.get(`campaign-contract/${id}`);
    let campaign_res = await api.get(`campaign/${id}`);
    let contract = contract_res.data;
    let campaign = campaign_res.data;
    let total_funding = campaign.investments_count;
    let fee_kb = total_funding * (contract.agent_fee / 100);
    let fee_return = total_funding * (contract.campaign.return / 100);
    let total_payout = parseFloat(total_funding) + parseFloat(fee_kb) + parseFloat(fee_return);
    this.setState({ contract, campaign });
  };

  getInvestment = async() => {
    let investments = await hp.get_investments(this.state.id)
    this.setState({ investments })
  }

  onAttachSub = async (e, f, props) => {
    let formData = new FormData();
    formData.append("dir", "campaign-contract/" + f);
    formData.append("image", e.target.files[0]);
    let image_url_res = await api.post("upload-image", formData);
    props.onChange(image_url_res.data.file_name);
  };

  onChangeTab = (e, newTab) => {
    this.setState({ tab: newTab });
  };

  setSnackBarMessage = (msg) => {
    this.setState({openSnack: true, snackMessage: msg});
  }

  render() {
    const {
      contract,
      campaign,
      tab,
    } = this.state;

    return (
      <Container id="campaign-contract" maxWidth="xl">
        <Paper className="spaceBottom">
          <Typography variant="h5" className="padding">
            <Link to={`/campaign/${campaign.id}`} style={{ display: "inline-block" }}>
              {campaign.name}
            </Link>
          </Typography>
          
          <Tabs value={tab} onChange={this.onChangeTab}>
            <Tab label="Contract Data" />
            <Tab label="Editor" />
            <Tab label="Investor" />
            <Tab label="BD/Analyst" />
          </Tabs>
        </Paper>

        <Fragment>
          { !contract && 
            <div className="loading">
              <CircularProgress />
            </div>
          }
          {tab === 0 && contract &&
            <ContractData data={this.state} setSnackBarMessage={this.setSnackBarMessage}/>
          }
          {tab === 1 && contract &&
            <ContractEditor data={this.state} setSnackBarMessage={this.setSnackBarMessage}/>
          }
          {tab === 2 && this.renderContracts()}
          {tab === 3 && (
            <DataReminder campaign_id={campaign.id} />
          )}
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={this.state.openSnack}
            autoHideDuration={6000}
            onClose={() => this.setState({ openSnack: false })}
          >
            <div className={this.state.snackType ? "success-alert" : "error-alert"}>
              <Typography>{this.state.snackMessage}</Typography>
            </div>
          </Snackbar>

        </Fragment>
      </Container>
    );
  }

  onPreviewContract = async (investment) => {
    let preview_link = `${hp.spacesUrl}/campaign-contract/${investment.id}.pdf`;
    window.open(preview_link, "_blank");
  };
  onRefreshContract = async (id) => {
    this.setState({ refresh_loading: true });
    const res = await api.post(`contract/refresh/${id}`);
    this.setState({ refresh_loading: false });
    const { data } = res;
    if (data.success) {
      this.contractTable.current && this.contractTable.current.onQueryChange();
    } else {
      alert(data.message);
    }
  };

  onSendContract = async (investment) => {
    this.sendContract(investment);
    return;
  };
  sendContract = async (investment) => {
    this.setState({ send_loading: true })
    await api.post(`esign/send/${investment.id}`, {campaign_contract_id: this.state.contract.id}).then(res => {
      this.setState({ openSnack: true, snackType: true, snackMessage: 'Contract sent!' });
      console.log(res);
    }).catch(error => {
      this.setState({ openSnack: true, snackType: false, snackMessage: error.data.message });
    });
    this.contractTable.current && this.contractTable.current.onQueryChange();
    this.setState({ send_loading: false });
  };

  Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  openEversignLogs = async (investment, refresh = false) => {
    if (!refresh && this.state.eversignDocument && this.state.eversignDocument.document_hash === investment.document_hash) {
      this.setState({eversignLogOpen: true});
    } else if (!this.state.eversignDocument || refresh === true) {
      this.setState({eversignDocument: null});
      const res = await api.get(`eversign/document/${investment.document_hash}`);
      if (res.response && res.response.data.success === false) {
        console.log('error');
      }else{
        this.setState({ eversignLogOpen: true, eversignDocument: res.data });
        console.log(this.state.eversignDocument);
      }
    }
  }

  contractLogs = async (investment) => {
    await api.get(`esign/documents/${investment.document_hash}?mekari_access_token=TdmCL8y8p2vD1cBhVID7klLFD31qT3dS`).then(res => {
      console.log(res);
      this.setState({ eversignLogOpen: true, eversignDocument: res.data.data });
    }).catch(err => {
      console.log(err);
    })    
  }

  getEversigner = (signer_id) => {
    let email = ''
    if (this.state.eversignDocument && signer_id) {
      this.state.eversignDocument.signers.map((signer, index) => {
        if (signer.id === signer_id) {
          email = signer.email;
        }
        return signer;
      });
    }
    return email;
  }

  renderContracts() {
    const { id, campaign, send_loading } = this.state;
    if (campaign) {
      return (
        <div>
          <MaterialTable
            tableRef={this.contractTable}
            title={`Contracts for ${campaign.name}`}
            columns={[
              {
                title: "No",
                editable: "never",
                render: (rowData) => (rowData ? rowData.tableData.id + 1 : ""),
              },
              {
                title: "Investor Name",
                render: (rowData) =>
                  rowData.user.firstname + " " + rowData.user.lastname,
              },
              { title: "Investor Email", field: "user.email" },
              { title: "Funding Amount", field: "amount" },
              { title: "Invested Date", field: "created_at", type: "date" },
              { title: "Sent", field: "sent_contract", type: "boolean" },
              {
                title: "Actions",
                width: 200,
                render: (rowData) => (
                  <Fragment>
                    {!send_loading ? (
                      <IconButton 
                        onClick={() => this.onSendContract(rowData)}
                        disabled={rowData.sent_contract}
                      >
                        <Icon>send</Icon>
                      </IconButton>
                    ) : (
                      <IconButton>
                        <CircularProgress size={23} />
                      </IconButton>
                    )}
                    <IconButton 
                      onClick={e => this.contractLogs(rowData)}
                      disabled={!rowData.sent_contract}
                    >
                      <Icon>list</Icon>
                    </IconButton>
                  </Fragment>
                ),
              },
            ]}
            options={{
              pageSize: 20,
              pageSizeOptions: [5, 10, 20, 50],
              actionsColumnIndex: -1,
            }}
            data={(query) =>
              new Promise(async (resolve) => {
                const result = await api.get(
                  `campaign-investment?campaign_id=${id}&limit=${
                    query.pageSize
                  }&offset=${query.page * query.pageSize}&search=${
                    query.search
                  }&user_id=${this.state.id}`
                );
                const { data } = result;
                resolve({
                  data: data.data,
                  page: query.page,
                  totalCount: data.total,
                });
              })
            }
            actions={[
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                onClick: () =>
                  this.contractTable.current &&
                  this.contractTable.current.onQueryChange(),
              },
            ]}
          />

          {
            this.state.eversignDocument && this.state.eversignLogOpen &&
            <Dialog
              open={this.state.eversignLogOpen}
              TransitionComponent={this.Transition}
              keepMounted
              onClose={(e) => this.setState({eversignLogOpen: false}) }
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                Sign Document 
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <h4>Title : {this.state.eversignDocument.filename}</h4>
                  <h4>Signer</h4>
                  <ol>
                    {this.state.eversignDocument.signers.map((value, index) => {
                      return <li key={index}>{value.email} {value.status === 'completed' && <strong style={{color: 'green'}}> [{value.status}] </strong>}</li>
                    })}
                  </ol>
                  {/* <h4>Logs</h4>
                  <List>
                    {this.state.eversignDocument.log.map((value, index) => {
                      return (
                        <ListItem key={index}>
                          <ListItemAvatar>
                            <Avatar>
                              <WorkIcon />
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText 
                            primary={`${value.event} - ${this.getEversigner(value.signer)}`} 
                            secondary={moment(new Date(value.timestamp * 1000)).format('YYYY-MM-DD HH:mm:ss')} 
                          />
                        </ListItem>
                      )
                    })}
                  </List> */}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {/* <Button onClick={ e => this.openEversignLogs(null, true) } color="primary">
                  Refresh
                </Button> */}
                <Button onClick={(e) => this.setState({eversignLogOpen: false}) } color="primary">
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          }
        </div>
      );
    }
  }
}

export default CampaignContract;
