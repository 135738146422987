import React, { Component, Fragment } from 'react';
import { Editor } from "@tinymce/tinymce-react";
import {
  Switch,
  CircularProgress,
  Paper,
  Tooltip,
  FormControlLabel,
  Typography,
  Button,
  Dialog,
  DialogContent,
  TextField,
  Grid,
} from "@material-ui/core";
import {
  Close,
  InsertDriveFileOutlined,
  NoteAddOutlined,
} from "@material-ui/icons";
import { Autocomplete } from "@material-ui/lab";
import api from "services/api";
import hp from "services/hp";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";

const TINY_APIKEY = process.env.REACT_APP_TINY_APIKEY

const filterOptions = createFilterOptions({
  stringify: (option) =>
    option.user.firstname +
    " " +
    option.user.lastname +
    " > " +
    option.campaign.name,
});

class CampaignEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {...this.props.data};
  }

  onChangeSwitch = (e) => {
    let { state } = this;
    state[e.target.name] = e.target.checked;
    this.setState({ state });
  };

  handleEditorChange = (content, editor) => {
    let { contract } = this.state;
    contract.content = content;
    this.setState({ contract });
  };

  handleAttachmentEditorChange = (content, editor) => {
    console.log(content);
    let { contract } = this.state;
    contract.attachment_content = content;
    this.setState({ contract });
  };

  handleEditorChangeHedging = (content, editor) => {
    let { contract } = this.state;
    contract.hedging_content = content;
    this.setState({ contract });
  };

  openSavedTemplate = async () => {
    this.setState({ open_saved_template: true });
    let saved_templates_res = await api.get(`contract-template/all`);
    let saved_templates = saved_templates_res.data;
    this.setState({ saved_templates });
  };

  closeSavedTemplate = () => {
    this.setState({
      open_saved_template: false,
      saved_templates: null,
      selected_st: null,
      show_rename: false,
      show_save: false,
      save_name: "",
    });
  };

  onSelectST = (template) => {
    const { selected_st } = this.state;
    if (selected_st && template.id === selected_st.id) {
      this.setState({ selected_st: null });
    } else {
      this.setState({ selected_st: template });
    }
    this.setState({ show_rename: false });
  };

  toggleRename = () => {
    this.setState({ show_rename: !this.state.show_rename });
  };

  toggleHide = async () => {
    let { selected_st } = this.state;
    selected_st.is_show = !selected_st.is_show;
    this.setState({ selected_st });
    await api.post(`contract-template/${selected_st.id}`, {
      is_show: selected_st.is_show,
    });
    this.setState({ selected_st: null, show_rename: false });
  };

  onChangeRenameText = (e) => {
    let { selected_st } = this.state;
    selected_st.name = e.target.value;
    this.setState({ selected_st });
  };

  onChangeSaveText = (e) => {
    this.setState({ save_name: e.target.value });
  };

  onChange = (e, f) => {
    let { contract } = this.state;
    contract[f] = e.target.value;
    this.setState({contract});
  };

  onUse = async () => {
    let { selected_st, contract } = this.state;
    let r = window.confirm(
      "Your existing content will be replaced. Please Cancel and save it if neccessary."
    );
    if (r) {
      let use_saved_template_res = await api.get(
        `contract-template/${selected_st.id}`
      );
      let use_saved_template = use_saved_template_res.data;
      contract.content = use_saved_template.content;
      contract.sign_content = use_saved_template.sign_content;
      contract.attachment_content = use_saved_template.attachment_content;
      contract.signer_json = use_saved_template.signer_json;
      contract.hedging_content = use_saved_template.hedging_content === null ? "" : use_saved_template.hedging_content;
      contract.hedging_sign_content = use_saved_template.hedging_sign_content === null ? "" : use_saved_template.hedging_sign_content;
      contract.hedging_signer_json = use_saved_template.hedging_signer_json === null ? "" : use_saved_template.hedging_signer_json;      

      this.setState({
        contract,
        open_saved_template: false,
        selected_st: null,
        show_rename: false,
        show_save: false,
        save_name: "",
      });
    }
  };

  onSaveToggle = () => {
    this.setState({
      show_save: !this.state.show_save,
      selected_st: null,
      show_rename: false,
      save_name: "",
    });
  };

  onSaveConfirm = async () => {
    let { contract, save_name } = this.state;
    await api.post(`contract-template`, {
      content: contract.content,
      sign_content: contract.sign_content,
      attachment_content: contract.attachment_content,
      hedging_content: contract.hedging_content,
      hedging_sign_content: contract.hedging_sign_content,
      name: save_name,
    });
    this.setState({
      open_saved_template: false,
      openSuccessSnack: true,
      save_name: "",
      show_save: false,
    });
  };

  onUpdate = async (preview = false) => {
    let { id, contract, preview_investment_id } = this.state;
    let {content, sign_content, attachment_content, signer_json, hedging_signer_json, hedging_content, hedging_sign_content} = contract;
    await api.post(`campaign-contract/${id}`, {content, sign_content, attachment_content, signer_json, hedging_signer_json, hedging_content, hedging_sign_content}).then(res => {
      this.props.setSnackBarMessage('Successfully updated');
      
      if (preview) {
        window.open(`${hp.apiBaseUrl}/preview-esign-contract/${
          preview_investment_id
        }?campaign_contract_id=${contract.id}&token=${localStorage.getItem(
          "token"
        )}`);
      }
    }).catch(err => {
      alert('Error');
    });
  };

  render() {
    const {
      contract,
      investments,
      preview_investment_id,
      open_saved_template,
      saved_templates,
      saved_template_show_hidden,
      selected_st,
      show_rename,
      show_save,
      save_name,
    } = this.state;

    return (
      <Fragment>
        <Paper className="spaceBottom padding">
          <Grid container spacing={2}>
            <Grid item xs={12} md={2} className="v-align-center">
              <Tooltip title="Template">
                <Button color="primary" variant="contained" className="btn-full" onClick={this.openSavedTemplate}>
                  Template
                </Button>
              </Tooltip>
              <Dialog onClose={this.closeSavedTemplate} open={open_saved_template} fullWidth={true} maxWidth="md" >
                <DialogContent
                  onClose={this.closeSavedTemplate}
                  className="dialog-title"
                >
                  <Typography variant="h5">Saved Templates</Typography>
                  <Button
                    onClick={this.closeSavedTemplate}
                    variant="outlined"
                    disableElevation
                  >
                    <Close />
                  </Button>
                </DialogContent>
                <DialogContent
                  dividers
                  style={{ height: "50%", textAlign: "center" }}
                >
                  {saved_templates ? (
                    <div>
                      <div className="template" onClick={this.onSaveToggle}>
                        <NoteAddOutlined
                          style={{ fontSize: 80 }}
                          color="primary"
                        />
                        <Typography>
                          Save current one as a template
                        </Typography>
                      </div>
                      {saved_templates.map((template, i) => (
                        <Fragment key={i}>
                          {(template.is_show ||
                            (saved_template_show_hidden &&
                              !template.is_show)) && (
                            <div
                              className="template"
                              onClick={() => this.onSelectST(template)}
                              style={{
                                borderColor:
                                  selected_st &&
                                  template.id === selected_st.id
                                    ? "#2f628a"
                                    : "transparent",
                              }}
                            >
                              <InsertDriveFileOutlined
                                style={{ fontSize: 80 }}
                                color="primary"
                              />
                              <Typography>{template.name}</Typography>
                            </div>
                          )}
                        </Fragment>
                      ))}
                    </div>
                  ) : (
                    <div className="loading">
                      <CircularProgress />
                    </div>
                  )}
                </DialogContent>
                <DialogContent
                  dividers
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={saved_template_show_hidden}
                        onChange={this.onChangeSwitch}
                        color="primary"
                        name="saved_template_show_hidden"
                      />
                    }
                    label="Show Hidden"
                  />
                  <div>
                    {selected_st && (
                      <Typography
                        style={{ display: "inline-block", marginRight: 10 }}
                      >
                        Created at {selected_st.created_at}
                      </Typography>
                    )}
                    <Button
                      disabled={selected_st ? false : true}
                      variant="outlined"
                      color="primary"
                      size="large"
                      onClick={this.toggleRename}
                    >
                      Rename
                    </Button>
                    <Button
                      disabled={selected_st ? false : true}
                      variant="outlined"
                      color="primary"
                      size="large"
                      style={{ margin: "auto 10px" }}
                      onClick={this.toggleHide}
                    >
                      {" "}
                      {selected_st && !selected_st.is_show
                        ? "Unhide"
                        : "Hide"}
                    </Button>
                    <Button
                      disabled={selected_st ? false : true}
                      variant="contained"
                      color="primary"
                      size="large"
                      onClick={this.onUse}
                    >
                      Use
                    </Button>
                  </div>
                </DialogContent>
                {show_rename && selected_st && (
                  <DialogContent
                    dividers
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      label="Rename"
                      variant="outlined"
                      value={selected_st.name}
                      style={{ width: "78%" }}
                      onChange={this.onChangeRenameText}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ width: "20%" }}
                      onClick={this.onRename}
                    >
                      Change
                    </Button>
                  </DialogContent>
                )}
                {show_save && (
                  <DialogContent
                    dividers
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <TextField
                      label="Save New Template Name"
                      variant="outlined"
                      value={save_name}
                      style={{ width: "78%" }}
                      onChange={this.onChangeSaveText}
                    />
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ width: "20%" }}
                      onClick={this.onSaveConfirm}
                    >
                      Save
                    </Button>
                  </DialogContent>
                )}
              </Dialog>
            </Grid>
            {investments && (
              <Grid item xs={12} md={6} className="v-align-center">
                <Autocomplete
                  id="select-investment"
                  options={investments}
                  getOptionLabel={(option) =>
                    "Investmen ID " +
                    option.id +
                    " > " +
                    option.user.firstname +
                    " " +
                    option.user.lastname +
                    " > " +
                    option.campaign.name
                  }
                  style={{ width: "100%" }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select a Preview Investment"
                      variant="outlined"
                    />
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      this.setState({ preview_investment_id: newValue.id });
                    }
                  }}
                  filterOptions={filterOptions}
                />
              </Grid>
            )}
            <Grid item xs={12} md={2} className="v-align-center">
              {preview_investment_id ? (
                <Button
                  variant="contained"
                  fullWidth
                  className="btn-full"
                  color="secondary"
                  onClick={() => this.onUpdate(true)}
                >
                  Save & Preview
                </Button>
              ) : (
                <Tooltip title="Please select a Preview Investment">
                  <span style={{width: '100%', height: '100%'}}>
                    <Button
                      variant="contained"
                      fullWidth
                      className="btn-full"
                      color="secondary"
                      disabled
                    >
                      Save & Preview
                    </Button>
                  </span>
                </Tooltip>
              )}
            </Grid>
            <Grid item xs={12} md={2} className="v-align-center">
              <Button
                variant="contained"
                type="submit"
                fullWidth
                className="btn-full"
                color="primary"
                onClick={() => this.onUpdate()}
              >
                Save
              </Button>
            </Grid>
          </Grid>

        </Paper>

        <Paper className="spaceBottom padding">
          <h3 style={{textAlign: 'center'}}>Main Content</h3>
          <Editor
            apiKey={TINY_APIKEY}
            value={contract.content}
            id="main-content"
            onEditorChange={this.handleEditorChange}
            init={{
              height: 500,
              menubar:
                "file edit view insert format tools table help custom",
              menu: {
                custom: {
                  title: "Dynamic Data",
                  items:
                    "company usermenu campaignmenu masterpayoutmenu contractautomaticmenu contractgeneralmenu contractassetmenu contractassetsgdmenu contractinvoicemenu",
                },
              },
              plugins: [
                "advlist autolink lists link image charmap anchor pagebreak",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar: "undo redo | formatselect fontselect fontsizeselect | bold italic underline forecolor backcolor | image searchreplace | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | pagebreak | fullscreen",
              automatic_uploads: false,
              images_upload_url:
                "https://api.kapitalboost.com/api/admin/upload-image",
              content_style:
                "code { color: white; background-color: #2f628a !important; }; ",
                pagebreak_separator: '<div style="page-break-before: always; clear:both"/></div>',
              setup: function (editor) {

                // editor.ui.registry.addNestedMenuItem("company", {
                //   text: "Company",
                //   getSubmenuItems: function () {
                //     return [
                //       {
                //         type: "menuitem",
                //         text: "Name",
                //         onAction: function () {
                //           editor.insertContent(
                //             "<code>{{campaign.company_name}}</code>&nbsp;"
                //           );
                //         },
                //       },
                //       {
                //         type: "menuitem",
                //         text: "Director",
                //         onAction: function () {
                //           editor.insertContent(
                //             "<code>{{campaign.company_director}}</code>&nbsp;"
                //           );
                //         },
                //       },
                //       {
                //         type: "menuitem",
                //         text: "Director's email address",
                //         onAction: function () {
                //           editor.insertContent(
                //             "<code>{{campaign.company_director_email}}</code>&nbsp;"
                //           );
                //         },
                //       },
                //     ];
                //   },
                // });

                editor.ui.registry.addNestedMenuItem("usermenu", {
                  text: "User Data",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Firstname",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.firstname}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Lastname",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.lastname}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Fullname",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.fullname}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Nationality",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.nationality}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Email",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.email}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Name in IC",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.name_in_ic}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "IC Type",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.ic_type}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "NRIC",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.nric}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "IC Country",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.ic_country}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "IC Option",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.ic_option}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Country",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.country}}</code>&nbsp;"
                          );
                        },
                      },
                    ];
                  },
                });

                editor.ui.registry.addNestedMenuItem("campaignmenu", {
                  text: "Campaign Data",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Total Margin (%)",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.total_margin_percent}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Target Margin SGD",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.target_margin_sgd}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Target Margin IDR",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.target_margin_idr}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Real Margin SGD",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.real_margin_sgd}}</code>&nbsp;"
                          );
                        },
                      }
                    ];
                  },
                });

                editor.ui.registry.addNestedMenuItem(
                  "masterpayoutmenu",
                  {
                    text: "Master Payout Data",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Table (EN)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.table}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Table (ID)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.table_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Percentage 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.percentage_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Percentage 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.percentage_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Date 1 (EN)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.date_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Date 1 (ID)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.date_1_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Date 2 (EN)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.date_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Date 2 (ID)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.date_2_id}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );

                editor.ui.registry.addNestedMenuItem(
                  "contractautomaticmenu",
                  {
                    text: "Contract Data (Automatically Generated)",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Target Funding Amount / Asset Cost",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.target_funding_amount}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Wakalah Fee (Kapital Boost)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.wakalah_fee_kapital_boost}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Wakalah Fee (Investor)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.wakalah_fee_investor}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Payout (SGD)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_payout_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Date (ENG)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.date_eng}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Date (ID)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.date_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "BG Table 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.bg_table}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "BG Table 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.bg_table_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "LPC",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.LPC}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Campaign No",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.campaign_no}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Campaign Acro",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.campaign_acronim}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Month in Roman",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.month_in_roman}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin fees (IDR)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin fees (SGD)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        
                      ];
                    },
                  }
                );

                editor.ui.registry.addNestedMenuItem(
                  "contractgeneralmenu",
                  {
                    text: "Contract Data (General)",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Company Code",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_code}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Company Name En",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_name_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Signee 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.signee_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Signee 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.signee_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Email 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.email_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Email 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.email_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "ID Number 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.id_number_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "ID Number 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.id_number_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Company Registration Number",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_registration_no_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Company Registration Number ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_registration_no_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Customer Name En",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.customer_name_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Customer Name ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.customer_name_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Alamat Perusahaan (Company Address)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.transfer_info_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Transfer Info ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.transfer_info_ba}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Syarat 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.other_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Other ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.other_ba}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Exchange Rate",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.exchange_rate}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "UKM Phone Number",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.payment_method_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Syarat 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.payment_method_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin Fee Percentage",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_percentage}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin Fee IDR (Target)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_amount_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin Fee SGD (Target)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_amount_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Agent Fee",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.agent_fee}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );

                editor.ui.registry.addNestedMenuItem('contractassetmenu', {
                  text: 'Contract Data (Asset)',
                  getSubmenuItems: function () {
                    return [
                      {
                        type: 'menuitem',
                        text: 'Asset to be purchased',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_to_be_purchased}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Asset to be purchased ID',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_to_be_purchased_id}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Campaign Country Currency',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.campaign_country_currency}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Variable Funding',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_cost_in_campaign_country}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Purchase cost contribution date / Disbursement date',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.purchase_cost_contribution_date}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Investment (SGD)',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.amount}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Investment (Rp.)',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.amount_idr}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Agency Agreement No.',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.agency_agreement_no}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Return Amount',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.payout}}</code>&nbsp;');
                        }
                      }
                    ]
                  }
                });

                editor.ui.registry.addNestedMenuItem('contractassetsgdmenu', {
                  text: 'Contract Data (Asset, SGD)',
                  getSubmenuItems: function () {
                    return [
                      {
                        type: 'menuitem',
                        text: 'Total Payout',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_total_payout}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Investor Margin',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_investor_margin}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Percentage Contribution',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_percentage_contribution}}</code>&nbsp;');
                        }
                      },
                    ]
                  }
                });

                editor.ui.registry.addNestedMenuItem(
                  "contractinvoicemenu",
                  {
                    text: "Contract Data (Invoice Financing)",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Maturity Date",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.maturity_date}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Repayment Date",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.repayment_date}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Receivable Amount as per invoice",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.receivable_amount_invoice}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "receivable amount in sgd",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.receivable_amount_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Sub-Agency Fee (%)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.sub_agent_fee}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Sub-Agency Fee (Rp)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.sub_agent_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Agency Fee (Rp)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.agent_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Agency Fees (Rp)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_agent_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "AKTA No",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.akta_no}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "AKTA No ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.akta_no_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Underlying Document",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.underlying_document}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Underlying Document ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.underlying_document_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Sub Agent fee SGD",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.sub_agent_fee_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Agent fee SGD",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_agent_fee_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );
              },
            }}
          />
        </Paper>

        <Paper className="spaceBottom padding">
          <h3 style={{textAlign: 'center'}}>Sign Content</h3>
          <Editor
            disabled={true}
            apiKey={TINY_APIKEY}
            value={contract.sign_content}
            init={{
              height: 500,
              menubar:
                "file edit view insert format tools table help custom",
              menu: {
                custom: {
                  title: "Dynamic Data",
                  items:
                    "usermenu campaignmenu masterpayoutmenu contractautomaticmenu contractgeneralmenu contractassetmenu contractassetsgdmenu contractinvoicemenu",
                },
              },
              plugins: [
                "advlist autolink lists link image charmap anchor pagebreak",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar: "undo redo | formatselect fontselect fontsizeselect | bold italic underline forecolor backcolor | image searchreplace | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | pagebreak | fullscreen",
              automatic_uploads: false,
              images_upload_url:
                "https://api.kapitalboost.com/api/admin/upload-image",
              content_style:
                "code { color: white; background-color: #2f628a !important; }; ",
              pagebreak_separator: '<div style="page-break-before: always; clear:both"/></div>',
              setup: function (editor) {
                editor.ui.registry.addNestedMenuItem("usermenu", {
                  text: "User Data",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Firstname",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.firstname}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Lastname",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.lastname}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Fullname",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.fullname}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Nationality",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.nationality}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Email",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.email}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Name in IC",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.name_in_ic}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "IC Type",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.ic_type}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "NRIC",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.nric}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "IC Country",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.ic_country}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "IC Option",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.ic_option}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Country",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.country}}</code>&nbsp;"
                          );
                        },
                      },
                    ];
                  },
                });

                editor.ui.registry.addNestedMenuItem("campaignmenu", {
                  text: "Campaign Data",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Total Margin (%)",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.total_margin_percent}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Target Margin SGD",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.target_margin_sgd}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Target Margin IDR",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.target_margin_idr}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Real Margin SGD",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.real_margin_sgd}}</code>&nbsp;"
                          );
                        },
                      }
                    ];
                  },
                });

                editor.ui.registry.addNestedMenuItem(
                  "masterpayoutmenu",
                  {
                    text: "Master Payout Data",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Table",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.table}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Table",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.table_id}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );

                editor.ui.registry.addNestedMenuItem(
                  "contractautomaticmenu",
                  {
                    text: "Contract Data (Automatically Generated)",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Target Funding Amount / Asset Cost",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.target_funding_amount}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Wakalah Fee (Kapital Boost)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.wakalah_fee_kapital_boost}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Wakalah Fee (Investor)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.wakalah_fee_investor}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Payout (SGD)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_payout_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Payout (IDR)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_payout_idr}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );

                editor.ui.registry.addNestedMenuItem(
                  "contractgeneralmenu",
                  {
                    text: "Contract Data (General)",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Company Code",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_code}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Company Name En",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_name_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Signee 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.signee_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Signee 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.signee_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Email 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.email_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Email 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.email_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "ID Number 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.id_number_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "ID Number 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.id_number_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Company Registration Number",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_registration_no_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Company Registration Number ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_registration_no_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Customer Name En",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.customer_name_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Customer Name ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.customer_name_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Alamat Perusahaan (Company Address)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.transfer_info_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Transfer Info ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.transfer_info_ba}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Other En",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.other_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Other ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.other_ba}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Exchange Rate",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.exchange_rate}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "UKM Phone Number",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.payment_method_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Syarat 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.payment_method_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin Fee Percentage",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_percentage}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin Fee Amount",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_amount}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Agent Fee",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.agent_fee}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );

                editor.ui.registry.addNestedMenuItem('contractassetmenu', {
                  text: 'Contract Data (Asset)',
                  getSubmenuItems: function () {
                    return [
                      {
                        type: 'menuitem',
                        text: 'Asset to be purchased',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_to_be_purchased}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Asset to be purchased ID',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_to_be_purchased_id}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Campaign Country Currency',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.campaign_country_currency}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Variable Funding',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_cost_in_campaign_country}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Purchase cost contribution date / Disbursement date',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.purchase_cost_contribution_date}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Investment (SGD)',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.amount}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Investment (Rp.)',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.amount_idr}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Agency Agreement No.',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.agency_agreement_no}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Return Amount',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.payout}}</code>&nbsp;');
                        }
                      }
                    ]
                  }
                });

                editor.ui.registry.addNestedMenuItem('contractassetsgdmenu', {
                  text: 'Contract Data (Asset, SGD)',
                  getSubmenuItems: function () {
                    return [
                      {
                        type: 'menuitem',
                        text: 'Total Payout',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_total_payout}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Investor Margin',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_investor_margin}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Percentage Contribution',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_percentage_contribution}}</code>&nbsp;');
                        }
                      },
                    ]
                  }
                });

                editor.ui.registry.addNestedMenuItem(
                  "contractinvoicemenu",
                  {
                    text: "Contract Data (Invoice Financing)",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Maturity Date",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.maturity_date}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Repayment Date",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.repayment_date}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Receivable Amount as per invoice",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.receivable_amount_invoice}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "receivable amount in sgd",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.receivable_amount_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Sub-Agency Fee (%)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.sub_agent_fee}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Sub-Agency Fee (Rp)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.sub_agent_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Agency Fee (Rp)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.agent_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Agency Fees (Rp)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_agent_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "AKTA No",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.akta_no}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "AKTA No ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.akta_no_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Underlying Document",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.underlying_document}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Underlying Document ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.underlying_document_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Sub Agent fee SGD",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.sub_agent_fee_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Agent fee SGD",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_agent_fee_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );
              },
            }}
          />
        </Paper>

        <Paper className="spaceBottom padding">
          <h3 style={{textAlign: 'center'}}>Attachment Content</h3>
          <Editor
            apiKey={TINY_APIKEY}
            value={contract.attachment_content}
            id="attachment-content"
            onEditorChange={this.handleAttachmentEditorChange}
            init={{
              height: 500,
              menubar:
                "file edit view insert format tools table help custom",
              menu: {
                custom: {
                  title: "Dynamic Data",
                  items:
                    "usermenu campaignmenu masterpayoutmenu contractautomaticmenu contractgeneralmenu contractassetmenu contractassetsgdmenu contractinvoicemenu",
                },
              },
              plugins: [
                "advlist autolink lists link image charmap anchor pagebreak",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar: "undo redo | formatselect fontselect fontsizeselect | bold italic underline forecolor backcolor | image searchreplace | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | pagebreak | fullscreen",
              automatic_uploads: false,
              images_upload_url:
                "https://api.kapitalboost.com/api/admin/upload-image",
              content_style:
                "code { color: white; background-color: #2f628a !important; }; ",
              pagebreak_separator: '<div style="page-break-before: always; clear:both"/></div>',
              setup: function (editor) {
                editor.ui.registry.addNestedMenuItem("usermenu", {
                  text: "User Data",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Firstname",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.firstname}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Lastname",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.lastname}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Fullname",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.fullname}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Nationality",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.nationality}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Email",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.email}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Name in IC",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.name_in_ic}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "IC Type",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.ic_type}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "NRIC",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.nric}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "IC Country",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.ic_country}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "IC Option",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.ic_option}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Country",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.country}}</code>&nbsp;"
                          );
                        },
                      },
                    ];
                  },
                });

                editor.ui.registry.addNestedMenuItem("campaignmenu", {
                  text: "Campaign Data",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Total Margin (%)",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.total_margin_percent}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Target Margin SGD",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.target_margin_sgd}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Target Margin IDR",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.target_margin_idr}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Real Margin SGD",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.real_margin_sgd}}</code>&nbsp;"
                          );
                        },
                      }
                    ];
                  },
                });

                editor.ui.registry.addNestedMenuItem(
                  "masterpayoutmenu",
                  {
                    text: "Master Payout Data",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Table",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.table}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Table",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.table_id}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );

                editor.ui.registry.addNestedMenuItem(
                  "contractautomaticmenu",
                  {
                    text: "Contract Data (Automatically Generated)",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Target Funding Amount / Asset Cost",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.target_funding_amount}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Wakalah Fee (Kapital Boost)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.wakalah_fee_kapital_boost}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Wakalah Fee (Investor)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.wakalah_fee_investor}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Payout (SGD)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_payout_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Payout (IDR)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_payout_idr}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );

                editor.ui.registry.addNestedMenuItem(
                  "contractgeneralmenu",
                  {
                    text: "Contract Data (General)",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Company Code",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_code}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Company Name En",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_name_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Signee 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.signee_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Signee 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.signee_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Email 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.email_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Email 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.email_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "ID Number 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.id_number_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "ID Number 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.id_number_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Company Registration Number",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_registration_no_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Company Registration Number ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_registration_no_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Customer Name En",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.customer_name_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Customer Name ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.customer_name_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Alamat Perusahaan (Company Address)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.transfer_info_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Transfer Info ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.transfer_info_ba}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Other En",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.other_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Other ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.other_ba}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Exchange Rate",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.exchange_rate}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "UKM Phone Number",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.payment_method_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Syarat 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.payment_method_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin Fee Percentage",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_percentage}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin Fee Amount",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_amount}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Agent Fee",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.agent_fee}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );

                editor.ui.registry.addNestedMenuItem('contractassetmenu', {
                  text: 'Contract Data (Asset)',
                  getSubmenuItems: function () {
                    return [
                      {
                        type: 'menuitem',
                        text: 'Asset to be purchased',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_to_be_purchased}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Asset to be purchased ID',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_to_be_purchased_id}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Campaign Country Currency',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.campaign_country_currency}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Variable Funding',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_cost_in_campaign_country}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Purchase cost contribution date / Disbursement date',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.purchase_cost_contribution_date}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Investment (SGD)',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.amount}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Investment (Rp.)',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.amount_idr}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Agency Agreement No.',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.agency_agreement_no}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Return Amount',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.payout}}</code>&nbsp;');
                        }
                      }
                    ]
                  }
                });

                editor.ui.registry.addNestedMenuItem('contractassetsgdmenu', {
                  text: 'Contract Data (Asset, SGD)',
                  getSubmenuItems: function () {
                    return [
                      {
                        type: 'menuitem',
                        text: 'Total Payout',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_total_payout}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Investor Margin',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_investor_margin}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Percentage Contribution',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_percentage_contribution}}</code>&nbsp;');
                        }
                      },
                    ]
                  }
                });

                editor.ui.registry.addNestedMenuItem(
                  "contractinvoicemenu",
                  {
                    text: "Contract Data (Invoice Financing)",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Maturity Date",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.maturity_date}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Repayment Date",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.repayment_date}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Receivable Amount as per invoice",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.receivable_amount_invoice}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "receivable amount in sgd",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.receivable_amount_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Sub-Agency Fees (%)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.sub_agent_fee}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Sub-Agency Fee (Rp)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.sub_agent_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Agency Fee (Rp)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.agent_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Agency Fees (Rp)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_agent_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "AKTA No",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.akta_no}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "AKTA No ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.akta_no_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Underlying Document",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.underlying_document}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Underlying Document ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.underlying_document_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Sub Agent fee SGD",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.sub_agent_fee_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Agent fee SGD",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_agent_fee_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );
              },
            }}
          />
        </Paper>

        <Paper className="spaceBottom padding">
          <h3 style={{textAlign: 'center'}}>Hedging Content</h3>
          <Editor
            apiKey={TINY_APIKEY}
            value={contract.hedging_content}
            id="hedging-content"
            onEditorChange={this.handleEditorChangeHedging}
            init={{
              height: 500,
              menubar:
                "file edit view insert format tools table help custom",
              menu: {
                custom: {
                  title: "Dynamic Data",
                  items:
                    "company usermenu campaignmenu masterpayoutmenu contractautomaticmenu contractgeneralmenu contractassetmenu contractassetsgdmenu contractinvoicemenu",
                },
              },
              plugins: [
                "advlist autolink lists link image charmap anchor pagebreak",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar: "undo redo | formatselect fontselect fontsizeselect | bold italic underline forecolor backcolor | image searchreplace | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | pagebreak | fullscreen",
              automatic_uploads: false,
              images_upload_url:
                "https://api.kapitalboost.com/api/admin/upload-image",
              content_style:
                "code { color: white; background-color: #2f628a !important; }; ",
                pagebreak_separator: '<div style="page-break-before: always; clear:both"/></div>',
              setup: function (editor) {
                editor.ui.registry.addNestedMenuItem("usermenu", {
                  text: "User Data",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Firstname",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.firstname}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Lastname",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.lastname}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Fullname",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.fullname}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Nationality",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.nationality}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Email",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.email}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Name in IC",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.name_in_ic}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "IC Type",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.ic_type}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "NRIC",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.nric}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "IC Country",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.ic_country}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "IC Option",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.ic_option}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Country",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.country}}</code>&nbsp;"
                          );
                        },
                      },
                    ];
                  },
                });

                editor.ui.registry.addNestedMenuItem("campaignmenu", {
                  text: "Campaign Data",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Total Margin (%)",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.total_margin_percent}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Target Margin SGD",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.target_margin_sgd}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Target Margin IDR",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.target_margin_idr}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Real Margin SGD",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.real_margin_sgd}}</code>&nbsp;"
                          );
                        },
                      }
                    ];
                  },
                });

                editor.ui.registry.addNestedMenuItem(
                  "masterpayoutmenu",
                  {
                    text: "Master Payout Data",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Table (EN)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.table}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Table (ID)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.table_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Percentage 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.percentage_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Percentage 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.percentage_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Date 1 (EN)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.date_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Date 1 (ID)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.date_1_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Date 2 (EN)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.date_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Date 2 (ID)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.date_2_id}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );

                editor.ui.registry.addNestedMenuItem(
                  "contractautomaticmenu",
                  {
                    text: "Contract Data (Automatically Generated)",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Target Funding Amount / Asset Cost",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.target_funding_amount}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Wakalah Fee (Kapital Boost)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.wakalah_fee_kapital_boost}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Wakalah Fee (Investor)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.wakalah_fee_investor}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Payout (SGD)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_payout_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Date (ENG)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.date_eng}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Date (ID)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.date_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "BG Table 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.bg_table}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "BG Table 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.bg_table_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "LPC",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.LPC}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Campaign No",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.campaign_no}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Campaign Acro",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.campaign_acronim}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Month in Roman",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.month_in_roman}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin fees (IDR)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin fees (SGD)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        
                      ];
                    },
                  }
                );

                editor.ui.registry.addNestedMenuItem(
                  "contractgeneralmenu",
                  {
                    text: "Contract Data (General)",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Company Code",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_code}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Company Name En",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_name_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Signee 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.signee_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Signee 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.signee_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Email 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.email_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Email 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.email_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "ID Number 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.id_number_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "ID Number 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.id_number_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Company Registration Number",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_registration_no_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Company Registration Number ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_registration_no_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Customer Name En",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.customer_name_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Customer Name ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.customer_name_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Alamat Perusahaan (Company Address)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.transfer_info_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Transfer Info ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.transfer_info_ba}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Syarat 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.other_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Other ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.other_ba}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Exchange Rate",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.exchange_rate}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "UKM Phone Number",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.payment_method_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Syarat 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.payment_method_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin Fee Percentage",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_percentage}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin Fee IDR (Target)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_amount_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin Fee SGD (Target)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_amount_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Agent Fee",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.agent_fee}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );

                editor.ui.registry.addNestedMenuItem('contractassetmenu', {
                  text: 'Contract Data (Asset)',
                  getSubmenuItems: function () {
                    return [
                      {
                        type: 'menuitem',
                        text: 'Asset to be purchased',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_to_be_purchased}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Asset to be purchased ID',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_to_be_purchased_id}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Campaign Country Currency',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.campaign_country_currency}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Variable Funding',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_cost_in_campaign_country}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Purchase cost contribution date / Disbursement date',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.purchase_cost_contribution_date}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Investment (SGD)',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.amount}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Investment (Rp.)',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.amount_idr}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Agency Agreement No.',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.agency_agreement_no}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Return Amount',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.payout}}</code>&nbsp;');
                        }
                      }
                    ]
                  }
                });

                editor.ui.registry.addNestedMenuItem('contractassetsgdmenu', {
                  text: 'Contract Data (Asset, SGD)',
                  getSubmenuItems: function () {
                    return [
                      {
                        type: 'menuitem',
                        text: 'Total Payout',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_total_payout}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Investor Margin',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_investor_margin}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Percentage Contribution',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_percentage_contribution}}</code>&nbsp;');
                        }
                      },
                    ]
                  }
                });

                editor.ui.registry.addNestedMenuItem(
                  "contractinvoicemenu",
                  {
                    text: "Contract Data (Invoice Financing)",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Maturity Date",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.maturity_date}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Repayment Date",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.repayment_date}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Receivable Amount as per invoice",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.receivable_amount_invoice}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "receivable amount in sgd",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.receivable_amount_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Sub-Agency Fee (%)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.sub_agent_fee}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Sub-Agency Fee (Rp)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.sub_agent_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Agency Fee (Rp)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.agent_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Agency Fees (Rp)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_agent_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "AKTA No",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.akta_no}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "AKTA No ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.akta_no_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Underlying Document",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.underlying_document}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Underlying Document ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.underlying_document_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Sub Agent fee SGD",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.sub_agent_fee_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Agent fee SGD",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_agent_fee_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );
              },
            }}
          />
        </Paper>

        <Paper className="spaceBottom padding">
          <h3 style={{textAlign: 'center'}}>Hedging Sign Content</h3>
          <Editor
            disabled={true}
            apiKey={TINY_APIKEY}
            value={contract.hedging_sign_content}
            id="hedging-sign-content"
            init={{
              height: 500,
              menubar:
                "file edit view insert format tools table help custom",
              menu: {
                custom: {
                  title: "Dynamic Data",
                  items:
                    "company usermenu campaignmenu masterpayoutmenu contractautomaticmenu contractgeneralmenu contractassetmenu contractassetsgdmenu contractinvoicemenu",
                },
              },
              plugins: [
                "advlist autolink lists link image charmap anchor pagebreak",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              toolbar: "undo redo | formatselect fontselect fontsizeselect | bold italic underline forecolor backcolor | image searchreplace | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | pagebreak | fullscreen",
              automatic_uploads: false,
              images_upload_url:
                "https://api.kapitalboost.com/api/admin/upload-image",
              content_style:
                "code { color: white; background-color: #2f628a !important; }; ",
                pagebreak_separator: '<div style="page-break-before: always; clear:both"/></div>',
              setup: function (editor) {
                editor.ui.registry.addNestedMenuItem("usermenu", {
                  text: "User Data",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Firstname",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.firstname}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Lastname",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.lastname}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Fullname",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.fullname}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Nationality",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.nationality}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Email",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.email}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Name in IC",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.name_in_ic}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "IC Type",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.ic_type}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "NRIC",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.nric}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "IC Country",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.ic_country}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "IC Option",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.ic_option}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Country",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{user.country}}</code>&nbsp;"
                          );
                        },
                      },
                    ];
                  },
                });

                editor.ui.registry.addNestedMenuItem("campaignmenu", {
                  text: "Campaign Data",
                  getSubmenuItems: function () {
                    return [
                      {
                        type: "menuitem",
                        text: "Total Margin (%)",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.total_margin_percent}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Target Margin SGD",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.target_margin_sgd}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Target Margin IDR",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.target_margin_idr}}</code>&nbsp;"
                          );
                        },
                      },
                      {
                        type: "menuitem",
                        text: "Real Margin SGD",
                        onAction: function () {
                          editor.insertContent(
                            "<code>{{campaign.real_margin_sgd}}</code>&nbsp;"
                          );
                        },
                      }
                    ];
                  },
                });

                editor.ui.registry.addNestedMenuItem(
                  "masterpayoutmenu",
                  {
                    text: "Master Payout Data",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Table (EN)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.table}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Table (ID)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.table_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Percentage 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.percentage_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Percentage 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.percentage_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Date 1 (EN)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.date_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Date 1 (ID)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.date_1_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Date 2 (EN)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.date_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Payout Date 2 (ID)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{masterpayout.date_2_id}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );

                editor.ui.registry.addNestedMenuItem(
                  "contractautomaticmenu",
                  {
                    text: "Contract Data (Automatically Generated)",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Target Funding Amount / Asset Cost",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.target_funding_amount}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Wakalah Fee (Kapital Boost)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.wakalah_fee_kapital_boost}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Wakalah Fee (Investor)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.wakalah_fee_investor}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Payout (SGD)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_payout_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Date (ENG)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.date_eng}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Date (ID)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.date_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "BG Table 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.bg_table}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "BG Table 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.bg_table_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "LPC",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.LPC}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Campaign No",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.campaign_no}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Campaign Acro",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.campaign_acronim}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Month in Roman",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.month_in_roman}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin fees (IDR)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin fees (SGD)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        
                      ];
                    },
                  }
                );

                editor.ui.registry.addNestedMenuItem(
                  "contractgeneralmenu",
                  {
                    text: "Contract Data (General)",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Company Code",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_code}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Company Name En",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_name_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Signee 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.signee_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Signee 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.signee_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Email 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.email_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Email 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.email_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "ID Number 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.id_number_1}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "ID Number 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.id_number_2}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Company Registration Number",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_registration_no_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Company Registration Number ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.company_registration_no_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Customer Name En",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.customer_name_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Customer Name ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.customer_name_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Alamat Perusahaan (Company Address)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.transfer_info_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Transfer Info ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.transfer_info_ba}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Syarat 1",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.other_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Other ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.other_ba}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Exchange Rate",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.exchange_rate}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "UKM Phone Number",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.payment_method_en}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Syarat 2",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.payment_method_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin Fee Percentage",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_percentage}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin Fee IDR (Target)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_amount_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Admin Fee SGD (Target)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.admin_fee_amount_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Agent Fee",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.agent_fee}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );

                editor.ui.registry.addNestedMenuItem('contractassetmenu', {
                  text: 'Contract Data (Asset)',
                  getSubmenuItems: function () {
                    return [
                      {
                        type: 'menuitem',
                        text: 'Asset to be purchased',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_to_be_purchased}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Asset to be purchased ID',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_to_be_purchased_id}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Campaign Country Currency',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.campaign_country_currency}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Variable Funding',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_cost_in_campaign_country}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Purchase cost contribution date / Disbursement date',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.purchase_cost_contribution_date}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Investment (SGD)',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.amount}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Investment (Rp.)',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.amount_idr}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Agency Agreement No.',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.agency_agreement_no}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Return Amount',
                        onAction: function () {
                          editor.insertContent('<code>{{investment.payout}}</code>&nbsp;');
                        }
                      }
                    ]
                  }
                });

                editor.ui.registry.addNestedMenuItem('contractassetsgdmenu', {
                  text: 'Contract Data (Asset, SGD)',
                  getSubmenuItems: function () {
                    return [
                      {
                        type: 'menuitem',
                        text: 'Total Payout',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_total_payout}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Investor Margin',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_investor_margin}}</code>&nbsp;');
                        }
                      },
                      {
                        type: 'menuitem',
                        text: 'Percentage Contribution',
                        onAction: function () {
                          editor.insertContent('<code>{{contract.asset_percentage_contribution}}</code>&nbsp;');
                        }
                      },
                    ]
                  }
                });

                editor.ui.registry.addNestedMenuItem(
                  "contractinvoicemenu",
                  {
                    text: "Contract Data (Invoice Financing)",
                    getSubmenuItems: function () {
                      return [
                        {
                          type: "menuitem",
                          text: "Maturity Date",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.maturity_date}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Repayment Date",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.repayment_date}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Receivable Amount as per invoice",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.receivable_amount_invoice}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "receivable amount in sgd",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.receivable_amount_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Sub-Agency Fee (%)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.sub_agent_fee}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Sub-Agency Fee (Rp)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.sub_agent_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Agency Fee (Rp)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.agent_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Agency Fees (Rp)",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_agent_fee_idr}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "AKTA No",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.akta_no}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "AKTA No ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.akta_no_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Underlying Document",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.underlying_document}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Underlying Document ID",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.underlying_document_id}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Sub Agent fee SGD",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.sub_agent_fee_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                        {
                          type: "menuitem",
                          text: "Total Agent fee SGD",
                          onAction: function () {
                            editor.insertContent(
                              "<code>{{contract.total_agent_fee_sgd}}</code>&nbsp;"
                            );
                          },
                        },
                      ];
                    },
                  }
                );
              },
            }}
          />
        </Paper>

      </Fragment>
    );
  }
}

export default CampaignEditor;