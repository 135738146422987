import 'date-fns';
import {format} from 'date-fns';
import React, { Component } from "react";
import api from "services/api";
import DateFnsUtils from '@date-io/date-fns';
import { Modal, Paper, Grid, Button } from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
  } from '@material-ui/pickers';
  import GetAppIcon from '@material-ui/icons/GetApp';


let baseUrl = process.env.REACT_APP_BASE_URL
// let baseUrl = 'https://api.kapitalboost.com';
// if (process.env.REACT_APP_IS_STAGING) {
//     baseUrl = 'https://stagingapi.kapitalboost.com';
// }else{
//     baseUrl = process.env.NODE_ENV !== "production"
//         ? "http://localhost:8000"
//         : "https://api.kapitalboost.com";
// }

class ExportInvestments extends Component {
    constructor() {
        super()

        this.state = {
            open: false,
            loading: false,
            start_date: null,
            end_date: null,
            error: false
        }

        this.linkRef = React.createRef();
    }

    handleClose = () => {
        this.props.handleClose()
    }

    export = async () => {
        this.setState({loading: true})
        this.setState({error: false})

        const start_date = format(this.state.start_date, 'yyyy-MM-dd')
        const end_date = format(this.state.end_date, 'yyyy-MM-dd')

        const {data} = await api.get(`investments/export?start_date=${start_date}&end_date=${end_date}`);
        
        if (typeof data.error !== 'undefined') {
            this.setState({error: true})
        } else {
            window.location.href = baseUrl + data.url
        }

        this.setState({loading: false})
    }

    handleDateChange = (key, date) => {
        this.setState({[key]: date})
    }

    render() {
        const {start_date, end_date, loading, error} = this.state

        return (
            <Modal
                open={this.props.open}
                onClose={this.handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                <Paper style={{width: '30%', height: 'auto', position: 'absolute', top: '30%', left: '35%', padding: '20px'}}>
                    <h2 style={{padding: '0'}}>Export data investments</h2>
                    {error && (
                        <Alert severity="error">ops, failed to export data</Alert>
                    )}

                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy-MM-dd"
                                    margin="normal"
                                    id="date-picker-inline"
                                    label="Select start date"
                                    value={start_date}
                                    onChange={(date) => this.handleDateChange('start_date', date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                                </Grid>
                            <Grid item xs={6}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Select end date"
                                    format="yyyy-MM-dd"
                                    value={end_date}
                                    onChange={(date) => this.handleDateChange('end_date', date)}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </MuiPickersUtilsProvider>
                    
                    <br />
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<GetAppIcon />}
                        onClick={() => this.export()}
                        disabled={loading}
                    >
                        {loading ? "Loading...":"Download"}
                    </Button>
                </Paper>
            </Modal>
        )
    }
}

export default ExportInvestments