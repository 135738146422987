import React, { Component } from "react";

import {
  Paper,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  TextField,
  Grid,
  Select,
} from "@material-ui/core";
import api from "services/api";
import hp from "services/hp";

class ContractData extends Component {
  constructor(props) {
    super(props);
    this.state = {...props.data};
  }

  componentDidMount() {
    this.autoCalculateData(this.state.campaign, this.state.contract, false)
  }

  autoCalculateData = (campaign, contract, onCustom = true) => {
    // console.log(campaign, contract)
    let total_funding = campaign.investments_count;
    let fee_kb = total_funding * (contract.sub_agent_fee / 100);
    let fee_return = total_funding * (contract.campaign.return / 100);
    let total_payout = parseFloat(total_funding) + parseFloat(fee_kb) + parseFloat(fee_return);

    this.setState({ fee_kb, fee_return, total_payout, total_funding });
  }

  onChangeFeeKb = (val) => {
    if(! isNaN(parseFloat(val))) {
      let fee_kb = val;
      let fee_return = this.state.total_funding * (this.state.contract.campaign.return / 100);
      let total_payout = parseFloat(this.state.total_funding) + parseFloat(fee_kb) + parseFloat(fee_return);
  
      this.setState({ fee_kb, fee_return, total_payout });
    } else {
      this.setState({ fee_kb: val });
    }
  }

  onChange = (e, f) => {
    let { contract } = this.state;
    contract[f] = e.target.value;
    this.setState({ contract });
  };

  onUpdate = async (e) => {
    e.preventDefault();
    let { id, contract } = this.state;
    let _contract = { ...contract };
    delete _contract.campaign;
    delete _contract.updated_at;
    const res = await api.post(`campaign-contract/${id}`, _contract);
    const { data } = res;
    if (data.error) {
      alert(data.message);
      return;
    }
    
    this.props.setSnackBarMessage('Successfully updated');
  };

  render() {
    const {
      contract,
      fee_kb,
      fee_return,
      total_payout,
      total_funding
    } = this.state;
    
    return (
      <form onSubmit={this.onUpdate}>
        <Paper className="padding spaceBottom" square>
          <Typography variant="h6">Automatically Generated</Typography>

          <Grid container spacing={4} className="space">
            <Grid item xs={6}>
              <TextField
                label="contract_No."
                value={contract.contract_no ? contract.contract_no : ""}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Current Funding Amount"
                value={hp.format_money(
                  total_funding
                )}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Wakalah Fee (Kapital Boost)"
                value={fee_kb}
                fullWidth
                margin="normal"
                onChange={(e) => {
                  this.onChangeFeeKb(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Wakalah Fee (Investor)"
                value={hp.format_money(fee_return)}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Total Payout"
                value={hp.format_money(total_payout)}
                fullWidth
                margin="normal"
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Total Payout (IDR)"
                value={
                  contract.exchange_rate
                    ? hp.format_money_id(
                        total_payout * contract.exchange_rate
                      )
                    : "Please enter Exchange Rate below"
                }
                fullWidth
                margin="normal"
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper className="padding spaceBottom">
          <Grid container spacing={4} className="space">
            <Grid item xs={6}>
              {[
                ["Company Name", "company_name_en"],
                ["Signee 1", "signee_1"],
                ["Email 1", "email_1"],
                ["ID Number 1", "id_number_1"],
                // ["Job Position 1", "position_1"],
                ["Customer Name (English)", "customer_name_en"],
                ["Admin Fee (%)", "admin_fee_percentage"],
                ["Exchange Rate", "exchange_rate"],
                // [
                //   "Company Registration No.",
                //   "company_registration_no_en",
                // ],
              ].map((item, i) => (
                <TextField
                  key={i}
                  label={item[0]}
                  value={contract[item[1]] ? contract[item[1]] : ""}
                  onChange={(e) => this.onChange(e, item[1])}
                  fullWidth
                  margin="normal"
                />
              ))}

              {/* <FormControl fullWidth margin="normal">
                <InputLabel>
                  Company Registration Type (English)
                </InputLabel>
                <Select
                  value={
                    contract.company_registration_type_en
                      ? contract.company_registration_type_en
                      : ""
                  }
                  onChange={(e) =>
                    this.onChange(e, "company_registration_type_en")
                  }
                >
                  <MenuItem value="" disabled>
                    Please Select One
                  </MenuItem>
                  <MenuItem value="Company Registration No.">
                    Company Registration No.
                  </MenuItem>
                  <MenuItem value="Single Business No.">
                    Single Business No.
                  </MenuItem>
                </Select>
              </FormControl> */}

              <TextField
                label="Alamat Perusahaan (Company Address)"
                value={
                  contract.transfer_info_en
                    ? contract.transfer_info_en
                    : ""
                }
                onChange={(e) => this.onChange(e, "transfer_info_en")}
                multiline
                rows={3}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Syarat 1"
                value={contract.other_en ? contract.other_en : ""}
                onChange={(e) => this.onChange(e, "other_en")}
                multiline
                rows={3}
                fullWidth
                margin="normal"
              />
              <TextField
                label="UKM Phone Number"
                value={
                  contract.payment_method_en
                    ? contract.payment_method_en
                    : ""
                }
                onChange={(e) => this.onChange(e, "payment_method_en")}
                multiline
                rows={3}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              {[
                // ["Company Name ID", "company_name_ba"],
                ["Signee 2", "signee_2"],
                ["Email 2", "email_2"],
                ["ID Number 2", "id_number_2"],
                // ["Job Position 2", "position_2"],
                ["Customer Name (Bahasa)", "customer_name_ba"],
                // ["Admin Fee (AMT)", "admin_fee_amount"],
                // ["Agent Fee (%)", "agent_fee"],
                // [
                //   "Company Registration No. ID",
                //   "company_registration_no_ba",
                // ],
              ].map((item, i) => (
                <>
                  {item[1] === "signee_2" ? (
                    <>
                      <div style={{height: '70px'}}>&nbsp;</div>
                      <TextField
                        key={i}
                        label={item[0]}
                        value={contract[item[1]] ? contract[item[1]] : ""}
                        onChange={(e) => this.onChange(e, item[1])}
                        fullWidth
                        margin="normal"
                      />
                    </>
                  ) : (
                    <TextField
                      key={i}
                      label={item[0]}
                      value={contract[item[1]] ? contract[item[1]] : ""}
                      onChange={(e) => this.onChange(e, item[1])}
                      fullWidth
                      margin="normal"
                    />
                    )}
                </>
                
                ))}
                <TextField
                  label="Agent Fee (%)"
                  value={contract.campaign.return}
                  fullWidth
                  margin="normal"
                  disabled={true}
                />
                <TextField
                  label="Variable Funding"
                  value={contract.asset_cost_local}
                  fullWidth
                  margin="normal"
                  onChange={(e) => this.onChange(e, 'asset_cost_local')}
                />
              {/* <FormControl fullWidth margin="normal">
                <InputLabel>
                  Company Registration Type (Bahasa)
                </InputLabel>
                <Select
                  value={
                    contract.company_registration_type_ba
                      ? contract.company_registration_type_ba
                      : ""
                  }
                  onChange={(e) =>
                    this.onChange(e, "company_registration_type_ba")
                  }
                >
                  <MenuItem value="" disabled>
                    Please Select One
                  </MenuItem>
                  <MenuItem value="Nomor Tanda Daftar Perusahaan (TDP)">
                    Nomor Tanda Daftar Perusahaan (TDP)
                  </MenuItem>
                  <MenuItem value="Nomor Induk Berusaha (NIB)">
                    Nomor Induk Berusaha (NIB)
                  </MenuItem>
                </Select>
              </FormControl> */}

              {/* <div style={{height: '70px'}}>&nbsp;</div> */}

              <TextField
                label="Bilyet Giro Information (Bahasa)"
                value={
                  contract.transfer_info_ba
                    ? contract.transfer_info_ba
                    : ""
                }
                onChange={(e) => this.onChange(e, "transfer_info_ba")}
                multiline
                rows={3}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Other Requirements (Bahasa)"
                value={contract.other_ba ? contract.other_ba : ""}
                onChange={(e) => this.onChange(e, "other_ba")}
                multiline
                rows={3}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Syarat 2"
                value={
                  contract.payment_method_ba
                    ? contract.payment_method_ba
                    : ""
                }
                onChange={(e) => this.onChange(e, "payment_method_ba")}
                multiline
                rows={3}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper className="spaceBig padding">
          <Typography variant="h6">Asset Financing</Typography>
          <Grid container spacing={4} className="space">
            <Grid item xs={6}>
              {[
                ["Asset to be purchased", "purchase_asset_en"],
                [
                  "Campaign Country Currency",
                  "campaign_local_currency",
                ],
                [
                  "Purchase cost contribution date / Disbursement date",
                  "purchase_date",
                ],
              ].map((item, i) => (
                <TextField
                  key={i}
                  label={item[0]}
                  value={contract[item[1]] ? contract[item[1]] : ""}
                  onChange={(e) => this.onChange(e, item[1])}
                  fullWidth
                  margin="normal"
                />
              ))}
            </Grid>
            <Grid item xs={6}>
              {[
                ["Asset to be purchased ID", "purchase_asset_ba"],
              ].map((item, i) => (
                <TextField
                  key={i}
                  label={item[0]}
                  value={contract[item[1]] ? contract[item[1]] : ""}
                  onChange={(e) => this.onChange(e, item[1])}
                  fullWidth
                  margin="normal"
                />
              ))}
            </Grid>
          </Grid>
        </Paper>

        <Paper className="spaceBig padding">
          <Typography variant="h6">Invoice Financing</Typography>
          <Grid container spacing={4} className="space">
            <Grid item xs={6}>
              {[
                ["Maturity Date", "maturity_date"],
                [
                  "Receivable Amount as per invoice : (number only)",
                  "receivable_amount_invoice",
                ],
                ["Sub-Agency Fees (%)", "sub_agent_fee"],
              ].map((item, i) => (
                <TextField
                  key={i}
                  label={item[0]}
                  value={contract[item[1]] ? contract[item[1]] : ""}
                  onChange={(e) => this.onChange(e, item[1])}
                  fullWidth
                  margin="normal"
                />
              ))}
              <TextField
                label="AKTA No"
                value={contract.akta_no_en ? contract.akta_no_en : ""}
                onChange={(e) => this.onChange(e, "akta_no_en")}
                multiline
                rows={3}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Underlying Document"
                value={
                  contract.underlying_document_en
                    ? contract.underlying_document_en
                    : ""
                }
                onChange={(e) =>
                  this.onChange(e, "underlying_document_en")
                }
                multiline
                rows={3}
                fullWidth
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              {[
                ["Repayment Date", "repayment_date"],
                [
                  "Receivable Amount in SGD : (number only)",
                  "receivable_amount_sgd",
                ],
              ].map((item, i) => (
                <TextField
                  key={i}
                  label={item[0]}
                  value={contract[item[1]] ? contract[item[1]] : ""}
                  onChange={(e) => this.onChange(e, item[1])}
                  fullWidth
                  margin="normal"
                />
              ))}
              <div style={{height: '70px'}}>&nbsp;</div>
              <TextField
                label="AKTA No ID"
                value={contract.akta_no_ba ? contract.akta_no_ba : ""}
                onChange={(e) => this.onChange(e, "akta_no_ba")}
                multiline
                rows={3}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Underlying Document ID"
                value={
                  contract.underlying_document_ba
                    ? contract.underlying_document_ba
                    : ""
                }
                onChange={(e) =>
                  this.onChange(e, "underlying_document_ba")
                }
                multiline
                rows={3}
                fullWidth
                margin="normal"
              />
            </Grid>
          </Grid>
        </Paper>

        <Paper className="spaceBig padding">
          <Button
            variant="contained"
            type="submit"
            fullWidth
            className="spaceBig"
            color="primary"
          >
            Update
          </Button>
        </Paper>

      </form>
    )
  }
}

export default ContractData;