import React, { Component, Fragment } from "react";
import {
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  Icon,
  ListItemText,
} from "@material-ui/core";
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import hp from "services/hp";
import api from "services/api";
import Canvas from './canvas';

class PreviewTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      images: [],
      images_url: null,
      previewProgress: { images: 0, thumbnails: 0},
      previewContainer: null,
      canvasContainers: [],
      showPreview: true,
      previewLoading: true,
      editSigner: false,
      mouseDown: false,
      canvases: {
        0: [], // investor
        1: [], // kb director
        2: [], // company director
      },
      ctxes: {
        0: [], // investor
        1: [], // kb director
        2: [], // company director
      },
      signers: [
        {
          page: null,
          startX: 0,
          startY: 0,
          width: 0,
          height: 0,
          canvasWidth: 0,
          canvasHeight: 0,
        }, // 0 => investor
        {
          page: null,
          startX: 0,
          startY: 0,
          width: 0,
          height: 0,
          canvasWidth: 0,
          canvasHeight: 0,
        }, // 1 => kb director
        {
          page: null,
          startX: 0,
          startY: 0,
          width: 0,
          height: 0,
          canvasWidth: 0,
          canvasHeight: 0,
        }, // 2 => company director
      ],
    }
    this.parent = props.parent;
  }

  async componentDidMount() {
    if (this.parent.state.template.signer_json !== null) {
      const oldSigners = JSON.parse(this.parent.state.template.signer_json);
      const {signers} = this.state;
      signers.map((signer, selectedSigner) => {
        if (oldSigners[selectedSigner].page != null) {
          signers[selectedSigner] = oldSigners[selectedSigner];
        }
        return signer;
      });
      this.setState({signers});
    }
    if (!this.parent.state.preview.isPreviewed) {
      this.previewTemplate();
    }

    window.onresize = async (e) => {
      this.resetCanvas();
    }
  }

  async componentDidUpdate() {
    if (!this.parent.state.preview.isPreviewed && this.state.previewLoading == false) {
      this.previewTemplate();
    }
  }

  componentWillUnmount() {
    window.onresize = null;
  }
  
  loadContract = async () => {
    const template = this.parent.getTemplate();
    const investment = this.parent.getSelectedOption('investment');
    const { preview } = this.parent.state;
    const postData = {
      investment_id: investment ? investment.id : null,
      ...template,
    };

    preview.path_url = null;
    this.parent.setState({ preview });
    
    await api.post(`contract-template-preview/prepare`, postData).then(async res => {
      preview.path_url = res.data.url;
      preview.total = res.data.page_count;
      preview.main_total = res.data.main_count;
      preview.signer_total = res.data.signer_count;
      preview.attachment_total = res.data.attachment_count;
      preview.isPreviewed = true;
      await this.parent.setState({preview});
    });
    this.setState({showPreview: true, previewLoading: false});
  }

  previewTemplate = async () => {
    await this.setState({showPreview: false, previewLoading: true});
    await this.loadContract();
  }

  filterOptions = createFilterOptions({
    stringify: (option) => `${option.acronim} - ${option.name}`,
  });

  goToPage = (page, additional_y = 0) => {
    const {canvasContainers, previewContainer} = this.state;
    previewContainer.scrollTo({
      left: 0, 
      top: canvasContainers[page].offsetTop - previewContainer.offsetTop + Math.max(additional_y - 100, 0),
      behavior: 'smooth',
    });
    // canvasContainers[page].scrollIntoView({behavior: 'smooth', block: 'center'});
  }
  
  recalculateSignersData = async () => {
    const { signers } = this.state;
    await signers.map((signer, selectedSigner) => {
      const canvas = this.state.canvases[selectedSigner][signer.page + this.parent.state.preview.main_total];
      console.log(canvas);
      if (canvas) {
        signers[selectedSigner].startX = canvas.width * (signers[selectedSigner].startX / signers[selectedSigner].canvasWidth);
        signers[selectedSigner].startY = canvas.height * (signers[selectedSigner].startY / signers[selectedSigner].canvasHeight);
        signers[selectedSigner].width = canvas.width * (signers[selectedSigner].width / signers[selectedSigner].canvasWidth);
        signers[selectedSigner].height = canvas.height * (signers[selectedSigner].height / signers[selectedSigner].canvasHeight);
        signers[selectedSigner].canvasWidth = canvas.width;
        signers[selectedSigner].canvasHeight = canvas.height;
      }
    });
    await this.setState({signers});
    await this.resetSign();
  }

  setSign = (selectedSigner) => {
    const signer = this.state.signers[selectedSigner];
    if (signer) {
      if (this.state.ctxes[selectedSigner][signer.page +  this.parent.state.preview.main_total]) {
        this.state.ctxes[selectedSigner][signer.page +  this.parent.state.preview.main_total].strokeRect(signer.startX, signer.startY, signer.width, signer.height);
      }
    }
  }

  resetSign = () => {
    this.state.signers.map((signer, selectedSigner) => {
      this.setSign(selectedSigner);
    })
  }

  resetCanvas = async () => {
    const { signers, canvases, canvasContainers, ctxes } = this.state;
    await signers.map((item, signer) => {
      if (canvases[signer].length && canvasContainers.length) {
        canvases[signer].map((canvas, page) => {
          if (!canvasContainers[page]) {
            // console.log(`canvasContainers[${page}] not found, trying to resetCanvas again..`);
            setTimeout(() => {
              this.resetCanvas();
            }, 1000);
            return canvas;
          }
          canvases[signer][page].width = canvasContainers[page].offsetWidth;
          canvases[signer][page].height = canvasContainers[page].offsetHeight;
          ctxes[signer][page] = canvases[signer][page].getContext("2d");
          ctxes[signer][page].strokeStyle = signer === 0 ? "orange" : signer === 1 ? "blue" : "green";
          ctxes[signer][page].lineWidth = 2;
          this.resetCanvasEvents(canvas, page);
          // let {width, height} = canvases[signer][page];
          // console.log(`set signer ${signer} canvases[${page}]`, {width, height});
          return canvas;
        });
      }
      return item;
    });
    await this.setState({ctxes, canvases});
    await this.recalculateSignersData();
  }

  resetCanvasEvents = (canvas, page) => {
    canvas.onmousedown = (e, mouseDown = true, isChanged = true) => {
      if (this.state.editSigner) {
        e.preventDefault();
        const { preview } = this.parent.state;
        const { selectedSigner, signers } = this.state;
        
        preview.isPreviewed = !isChanged;
        signers[selectedSigner].startX = e.clientX - this.state.canvasContainers[page].offsetLeft;
        signers[selectedSigner].startY = e.clientY - this.state.canvasContainers[page].offsetTop + this.state.previewContainer.scrollTop + window.scrollY;
        this.parent.setState({ preview, isChanged });
        this.setState({ mouseDown, signers });
      }
    }
    canvas.onmouseup = (e, mouseDown = false) => {
      if (this.state.editSigner) {
        this.setState({ mouseDown });
        // console.log(this.state.signers[this.state.selectedSigner]);
      }
    }
    canvas.onmousemove = (e) => {
      if (this.state.editSigner) {
        const clientX = e.clientX - this.state.canvasContainers[page].offsetLeft;
        const clientY = e.clientY - this.state.canvasContainers[page].offsetTop + this.state.previewContainer.scrollTop + window.scrollY;
        if (this.state.mouseDown) {
          const { template } = this.parent.state;
          const { selectedSigner, signers } = this.state;
          this.clearSign(this.state.selectedSigner,signers[selectedSigner].page);
          signers[selectedSigner].page = page;
          signers[selectedSigner].width = clientX - signers[selectedSigner].startX;
          signers[selectedSigner].height = clientY - signers[selectedSigner].startY;
          signers[selectedSigner].canvasWidth = canvas.width;
          signers[selectedSigner].canvasHeight = canvas.height;
          
          this.state.ctxes[selectedSigner][page].clearRect(0, 0, canvas.width, canvas.height);
          template.signer_json = JSON.stringify(signers);
          this.setState({signers});
          this.parent.setState({template});
          this.setSign(selectedSigner);
        }
      }
    }
  }

  // setSign = (selectedSigner) => {
  //   const signer = this.state.signers[selectedSigner];
  //   if (signer) {
  //     if (this.state.ctxes[selectedSigner][signer.page]) {
  //       this.state.ctxes[selectedSigner][signer.page].strokeRect(signer.startX, signer.startY, signer.width, signer.height);
  //     }
  //   }
  // }

  // resetSign = () => {
  //   this.state.signers.map((signer, selectedSigner) => {
  //     this.setSign(selectedSigner);
  //     return signer;
  //   })
  // }

  clearSign = (selectedSigner, page) => {
    const signer = this.state.signers[selectedSigner];
    const canvas = this.state.canvases[selectedSigner][page];
    if (!signer) {
      alert('Error: No signer selected.');
      return;
    }
    if (canvas) {
      this.state.ctxes[selectedSigner][page].clearRect(0, 0, canvas.width, canvas.height);
    }
  }

  // recalculateSignersData = async () => {
  //   const { signers } = this.state;
  //   await signers.map((signer, selectedSigner) => {
  //     const canvas = this.state.canvases[selectedSigner][signer.page];
  //     if (canvas) {
  //       signers[selectedSigner].startX = canvas.width * (signers[selectedSigner].startX / signers[selectedSigner].canvasWidth);
  //       signers[selectedSigner].startY = canvas.height * (signers[selectedSigner].startY / signers[selectedSigner].canvasHeight);
  //       signers[selectedSigner].width = canvas.width * (signers[selectedSigner].width / signers[selectedSigner].canvasWidth);
  //       signers[selectedSigner].height = canvas.height * (signers[selectedSigner].height / signers[selectedSigner].canvasHeight);
  //       signers[selectedSigner].canvasWidth = canvas.width;
  //       signers[selectedSigner].canvasHeight = canvas.height;
  //       this.clearSign(selectedSigner, signer.page);
  //     }
  //     return signer;
  //   });
  //   await this.setState({signers});
  //   await this.resetSign();
  // }

  // goToPage = (page, additional_y = 0) => {
  //   const {canvasContainers, previewContainer} = this.state;
  //   previewContainer.scrollTo({
  //     left: 0, 
  //     top: canvasContainers[page].offsetTop - previewContainer.offsetTop + Math.max(additional_y - 100, 0),
  //     behavior: 'smooth',
  //   });
  //   // canvasContainers[page].scrollIntoView({behavior: 'smooth', block: 'center'});
  // }
  
  render() {
    return (
      <Grid container>
        <Grid container className="space" spacing={2}>
          <Grid item xs={12} md={10} id="preview-container" className="preview-container" ref={ref => this.state.previewContainer = ref}>
          {
            !this.state.showPreview && this.state.previewLoading && <div className="space">{hp.Loading({
              style: {
                color: 'white',
              }
            })}</div>
          }
          {
            this.state.showPreview && this.parent.state.preview.path_url !== null && 
            <Fragment>
              {/* {this.state.previewProgress.images < 100 && <LinearProgress variant="determinate" value={this.state.previewProgress.images} />} */}
              {[...Array(this.parent.state.preview.total)].map((item, page) => <Canvas key={page} src={`${this.parent.state.preview.path_url}/${page}.png`} page={page} parent={this}/>)}
            </Fragment>
          }
          </Grid>
          <Grid item xs={12} md={2} className="preview-container">
            {
              this.state.showPreview && this.parent.state.preview.path_url !== null && 
              <Fragment>
                {[...Array(this.parent.state.preview.total)].map((item, page) =>  
                  <div key={page} className="w-100 pos-rel">
                    <span className="page-number" onClick={e => this.goToPage(page)}>
                      <span className="page-number-item">{page+1}</span>
                    </span>
                    <img className="w-100" src={`${this.parent.state.preview.path_url}/${page}.png`} alt='img'></img>
                  </div>
                )}
              </Fragment>
            }
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default PreviewTemplate;