import React from "react";
import { Link } from "react-router-dom";

import clsx from "clsx";
import {
  List,
  ListItemText,
  Icon,
  ListItem,
  ListItemIcon,
  Divider,
  Drawer,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import api from "services/api";

import KBLogo from "images/kapitalboost-logo.png";

const drawerWidth = 200;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 20,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    paddingLeft: 0,
    ...theme.mixins.toolbar,
  },
}));

const MiniDrawer = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(
    localStorage.getItem("sidebar_open")
      ? localStorage.getItem("sidebar_open") === "true"
        ? true
        : false
      : true
  );
  const [logout_open, setLogoutOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setOpen(!open);
    localStorage.setItem("sidebar_open", !open);
  };

  const handleLogoutOpen = () => {
    setLogoutOpen(true);
  };
  const handleLogoutClose = () => {
    setLogoutOpen(false);
  };
  const onLogout = async () => {
    let res = await api.post("logout");
    if (res.error) {
      alert(res.message);
      return;
    }
    localStorage.clear();
    window.location.reload();
  };

  return (
    <div id="sidebar" className={classes.root}>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <img src={KBLogo} alt="img" width="125px" />
          <IconButton onClick={handleDrawerToggle}>
            {open ? <ChevronLeft /> : <ChevronRight />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {[
            ["Dashboard", "dashboard", ""],
            ["Campaigns", "apps", "campaign"],
            ["Contract Template", "content_paste", "contract-template"],
            ["Enquiries", "question_answer", "enquiry"],
            ["Get Funded", "accessibility", "get-funded"],
            ["Investments", "attach_money", "investment"],
            ["Referal", "supervisor_account", "referal"],
            ["Withdrawal", "call_missed_outgoing", "withdraw"],
            // ["Summary Payout", "local_atm", "summary-payout"],
            ["Blogs", "create", "blog"],
            ["Users", "people", "user"],
            ["Affiliations", "people", "affiliation"],
            ["FAQs", "live_help", "faq"],
            ["Contact Form", "emoji_people", "contact"],
            ["Admins", "account_circle", "admin"],
            ["Banks", "account_balance", "banks"],
            ["Banners", "view_carousel", "banners"],
            ["Partners", "group_work", "partner"],
            ["Mobile Notification", "notifications_active", "mobile-notif"],
          ].map((menu, i) => (
            <ListItem component={Link} to={`/${menu[2]}`} button key={i}>
              <ListItemIcon>
                <Icon>{menu[1]}</Icon>
              </ListItemIcon>
              <ListItemText primary={menu[0]} />
            </ListItem>
          ))}
          <ListItem button onClick={handleLogoutOpen}>
            <ListItemIcon>
              <Icon>exit_to_app</Icon>
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </Drawer>
      <div id="app">{props.children}</div>
      <Dialog open={logout_open} onClose={handleLogoutClose}>
        <DialogTitle id="alert-dialog-title">
          {"Are you sure want to logout?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleLogoutClose} color="primary">
            No
          </Button>
          <Button onClick={onLogout} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MiniDrawer;
