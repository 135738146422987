import React from "react";
import { CircularProgress, Snackbar, Typography } from "@material-ui/core";
import {
  isFuture,
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import api from "services/api";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

let url = process.env.REACT_APP_API_URL;
let baseUrl = process.env.REACT_APP_BASE_URL;

// if (process.env.REACT_APP_IS_STAGING) {
//   url = "https://stagingapi.kapitalboost.com/api/admin";
//   baseUrl = "https://stagingapi.kapitalboost.com";
// } else {
//   url =
//     process.env.NODE_ENV !== "production"
//       ? "http://localhost:8000/api/admin/"
//       : "https://api.kapitalboost.com/api/admin/";
//   baseUrl =
//     process.env.NODE_ENV !== "production"
//       ? "http://localhost:8000"
//       : "https://api.kapitalboost.com";
// }

const hp = {
  Loading: () => (
    <div className="loading">
      <CircularProgress />
    </div>
  ),
  alert: (
    open = false,
    success = true,
    message,
    onClose,
    position = { vertical: "top", horizontal: "right" },
    duration = 3000
  ) => {
    return (
      <Snackbar
        anchorOrigin={position}
        open={open}
        autoHideDuration={duration}
        onClose={onClose}
      >
        <div className={success ? "success-alert" : "error-alert"}>
          <Typography>{message}</Typography>
        </div>
      </Snackbar>
    );
  },
  is_login: () => {
    if (localStorage.getItem("token") === null) {
      return false;
    }
    return true;
  },
  get_admin: async () => {
    let admin = localStorage.getItem("admin");
    console.log(admin);
    let id = localStorage.getItem("id");
    if (admin === null && id !== null) {
      let admin_res = await api.get(`admin_profile`);
      let admin = admin_res.data;
      console.log(admin);
      localStorage.setItem("admin", JSON.stringify(admin));
      return admin;
    }
    //  console.log(admin.email);
    return JSON.parse(admin);
  },
  save_admin: (admin) => {
    localStorage.setItem("user", JSON.stringify(admin));
  },
  get_day_left: (campaign) => {
    let day_left = 0;
    let left_unit = "Days";
    let expiry = new Date(campaign.expiry_datetime);
    let now = new Date();
    if (
      !campaign.investments_count ||
      parseInt(campaign.investments_count) < campaign.total_invest_amount
    ) {
      if (isFuture(expiry)) {
        day_left = differenceInCalendarDays(expiry, now);
        if (day_left === 0) {
          day_left = differenceInHours(expiry, now);
          left_unit = "Hours";
          if (day_left === 0) {
            day_left = differenceInMinutes(expiry, now);
            left_unit = "Minutes";
          }
        }
      }
    }
    return [day_left, left_unit];
  },
  get_countries: async () => {
    let countries;
    if (localStorage.getItem("countries") === null) {
      let countries_res = await api.get("misc/country");
      countries = countries_res.data;
      localStorage.setItem("countries", JSON.stringify(countries));
    } else {
      countries = JSON.parse(localStorage.getItem("countries"));
    }
    return countries;
  },
  get_users: async () => {
    let users_res = await api.get("user?limit=0");
    let users = users_res.data.data;
    return users;
  },

  get_investments: async (campaign_id = null) => {
    const params = {
      limit: 0,
    };

    if (campaign_id) {
      params.campaign_id = campaign_id;
    }
    let investments_res = await api.get("investment", { params });
    let investment = investments_res.data.data;
    return investment;
  },

  get_campaigns: async () => {
    let campaigns_res = await api.get("campaign?limit=0");
    let campaigns = campaigns_res.data.data;
    return campaigns;
  },

  format_status: (status) => {
    let s = '';
    switch (status) {
      case "new":
        s = "New";
      break;
      case "reviewing":
        s = "Waiting to be reviewed";
      break;
      case "approved":
        s = "Approved";
      break;
      case "blacklisted":
        s = "Blacklisted";
      break;
      case "rejected":
        s = "Rejected";
      break;
      default:
        s = "{status}";
      break;
    }
    return s;
  },
  format_campaign_type: (type) => {
    let t = '';
    switch (type) {
      case "sme":
        t = "SME Crowdfunding";
        break;
      case "donation":
        t = "Donation";
        break;
      case "private":
        t = "Private";
        break;
      default:
        t = '{type}';
        break;
    }
    return t;
  },
  format_payment: (method) => {
    let m = '';
    switch (method) {
      case "bank-transfer":
        m = "Bank Transfer";
        break;
      case "xfers":
        m = "Xfers";
        break;
      case "paypal":
        m = "Paypal";
        break;
      case "kb-wallet":
        m = "KB Wallet";
        break;
      default:
        m = '{method}';
        break;
    }
    return m;
  },
  format_money: (amount) => {
    let formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "SGD",
    });
    return formatter.format(amount);
  },
  format_money_symbol: (amount) => {
    let formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    });
    return formatter.format(amount);
  },
  format_money_id: (amount) => {
    let formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "IDR",
    });
    return formatter.format(amount);
  },
  apiUrl: url,
  apiBaseUrl: baseUrl,
  publicUrl:
    process.env.NODE_ENV !== "production"
      ? "http://localhost:5001"
      : "https://kapitalboost.com",
  spacesUrl: "https://kapitalboost.sgp1.digitaloceanspaces.com",

  saveUsers: (users) => {
    localStorage.setItem("users", JSON.stringify(users));
  },
  getUsers: () => {
    return JSON.parse(localStorage.getItem("users"));
  },
  removeUsers: () => {
    localStorage.removeItem("users");
  },
  saveCampaigns: (campaigns) => {
    localStorage.setItem("campaigns", JSON.stringify(campaigns));
  },
  getCampaigns: () => {
    return JSON.parse(localStorage.getItem("campaigns"));
  },
  removeCampaigns: () => {
    localStorage.removeItem("campaigns");
  },
};

export default hp;
