import React, { Component } from "react";

import {
  Container,
  Typography,
} from "@material-ui/core";
import api from "services/api";
import hp from "services/hp";
import MaterialTable from "material-table";

class Page extends Component {
  constructor() {
    super();
    this.state = {
      search: "",
      isSuperAdmin: false,
    };
    this.tableRef = React.createRef();
  }

  onDetailUser = async (event, rowData) => {
    const admin_res = await api.get(`admin_profile`);
    const admin = admin_res.data;
    if (admin.role === "super admin") {
      this.setState({ isSuperAdmin: true });
      window.open(`/user/${rowData.id}`);
    } else {
      alert("Sorry, you do not have access to view this page.");
    }
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Container id="users" maxWidth="xl">
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            { title: "ID", field: "id" },
            { title: "First Name", field: "firstname" },
            { title: "Last Name", field: "lastname" },
            { title: "Email", field: "email" },
            { title: "Country", field: "country" },
            { title: "Phone", field: "phone_no" },
            {
              title: "Status",
              field: "status",
              lookup: {
                new: "New",
                reviewing: "Reviewing",
                approved: "Approved",
                blacklisted: "Blacklisted",
                rejected: "Rejected",
              },
              render: (rowData) => (
                <Typography>{hp.format_status(rowData.status)}</Typography>
              ),
            },
            {
              title: "Wallet Amount",
              field: "wallet_amount",
              filtering: true,
              render: (rowData) => (
                <Typography>
                  {hp.format_money(rowData.wallet_amount)}
                </Typography>
              ),
            },
            { title: "Registered on", field: "created_at", type: "datetime" },
          ]}
          title="Users"
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100, 200],
            filtering: true,
            search: true,
          }}
          data={(query) =>
            new Promise(async (resolve) => {
              let filters = [];
              if (query.filters.length > 0) {
                query.filters.map((data) => {
                  filters.push({ column: {field : data.column.field}, value: data.value });
                  return data;
                });
                filters = JSON.stringify(filters);
              }
              
              const result = await api.get(`user`, {
                params: query,
              });
              const { data } = result;
              const _users = data.data; //.filter(user => user.lastname.toLowerCase().includes(query.search.toLowerCase()))
              resolve({
                data: _users, //.filter(user => user.lastname.toLowerCase().includes(query.search.toLowerCase())),
                page: query.page,
                totalCount: data.total,
              });
            })
          }
          onRowClick={this.onDetailUser}
          actions={[
            {
              icon: "refresh",
              tooltip: "Refresh Data",
              isFreeAction: true,
              onClick: () =>
                this.tableRef.current && this.tableRef.current.onQueryChange(),
            },
          ]}
        />
      </Container>
    );
  }
}

export default Page;
