import React, { Component } from 'react'

import { Box, Button, Container, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Snackbar, TextField, Typography, makeStyles } from '@material-ui/core'
import api from 'services/api'
import MaterialTable from "material-table";
import { SendOutlined } from '@material-ui/icons';
import Check from '@material-ui/icons/Check';
import { Alert } from '@material-ui/lab';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class CreateMessage extends Component {
   state = {
    title: null,
    message: null,
    file: null,
    type: null,
    campaign_id: null,
    blog_id: null,
    openNotif: false,
    notif_severity: 'success',
    notif_message: '',
    notif_position: 'center',
    all_campaigns: [],
    blog_options: []
   }

    componentDidMount() {
        window.scrollTo(0, 0)
        this.onInit()
    }

    onInit = async () => {
        const all_campaigns_res = await api.get("misc/campaign");
        const all_campaigns = all_campaigns_res.data;

        const blogs_res = await api.get("misc/blog");
        const blog_options = blogs_res.data;

        this.setState({ all_campaigns, blog_options });
    }

    handleChangeNotif = (val, message="", severity="success", position="right") => {
        let { state } = this;
        state['openNotif'] = val;
        state['notif_message'] = message
        state['notif_severity'] = severity
        state['notif_position'] = position

        this.setState({ state });
    }

    onAttach = (e, f) => {
        let { state } = this;
        let validated = false;

        if (e.target.files[0]) {
            const fileSize = e.target.files[0].size / 1024
            validated = fileSize > 1000
            
            if (validated === false) {
                state[f] = e.target.files[0];
                this.setState({ state });
            } else {
                this.handleChangeNotif(true, "Image size must be 1 MB or smaller", 'error', 'center')
                // this.setState({ notif_message: "Image size must be 1 MB or smaller", notif_severity: 'error', openNotif: true })
            }
        }

    };

   handleChange = (e, f) => {
        let { state } = this;
        state[f] = e.target.value;
        this.setState({ state });
    }

    onSubmit = async (e) => {
        e.preventDefault();
        const { title, message, file, type, campaign_id, blog_id } = this.state;

        let formData = new FormData();
        formData.append('title', title)
        formData.append('message', message)
        formData.append('file', file)
        formData.append('type', type)
        formData.append('id', type === 'campaign' ? campaign_id : type === 'blog' ? blog_id : null)

        try {
            const res = await api.post(`mobile-message`, formData);
            let { data } = res;
            
            if (data.error) {
                alert(data.message);
                return;
            }
            
            this.handleChangeNotif(true, data.message, "success", "right")

            setTimeout(() => {
                window.location.href = "/mobile-notif"
            }, 500);
          } catch (e) {
            console.log(e);
            this.handleChangeNotif(true, "Failed to save the data, something went wrong!", "error", "right")
          }
    };
    

   render() {
        const {title, message, file, openNotif, notif_message, notif_severity, notif_position, all_campaigns, blog_options, type, campaign_id, blog_id} = this.state;

        return (
            <Container id="enquiry" maxWidth="xl" >
                <Paper square className="padding spaceBottom">
                    <form noValidate autoComplete="off" onSubmit={this.onSubmit}>
                        <Grid container className={'flexGrow'} spacing={2} >
                            <Grid item xs={12}>
                                <Grid container justifyContent="center" spacing={3} style={{justifyContent: 'center'}}>
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <Grid item xs={8}><h2>Create a new message</h2></Grid>
                                            <Grid item xs={4} style={{textAlign: 'right'}}>
                                                <Button
                                                    color="default"
                                                    href='/mobile-notif'
                                                    startIcon={<ArrowBackIosIcon />}
                                                    style={{width: '80px', float: 'right', margin: '15px 0'}}
                                                >
                                                    Back
                                                </Button>
                                            </Grid>
                                        </Grid>
                                        <br />
                                        <br />
                                        <TextField id="standard-basic" label="TITLE" placeholder='Enter the title' fullWidth margin="normal" value={title} onChange={(e) => this.handleChange(e, "title")} />
                                        <TextField id="filled-basic" label="MESSAGE" placeholder='Enter the message' multiline rows={5} fullWidth margin="normal" value={message} onChange={(e) => this.handleChange(e, "message")} />

                                        <Grid item xs={6}>
                                            <Typography className="bold space">
                                                Image File
                                            </Typography>
                                            
                                            <input
                                                accept="image/*"
                                                id="profile-nric-file"
                                                type="file"
                                                style={{ display: "none" }}
                                                onChange={(e) => this.onAttach(e, "file")}
                                            />
                                            <label htmlFor="profile-nric-file">
                                                <Button
                                                    variant="contained"
                                                    component="span"
                                                    size='small'
                                                >
                                                    Browse
                                                </Button>
                                                <small style={{display: 'block', fontStyle: 'italic', marginTop: '5px'}}>max file size is 1MB</small>

                                                {file && (
                                                    <Typography style={{color: '#16a085', marginTop: '10px'}}>Attached: {file.name}</Typography>
                                                )}
                                            </label>
                                        </Grid>

                                        <Grid container spacing={3}>
                                            <Grid item xs={6}>
                                                <FormControl fullWidth margin="normal">
                                                    <InputLabel>Action Type</InputLabel>
                                                    <Select
                                                        labelId="type"
                                                        id="type"
                                                        style={{width: '100%'}}
                                                        onChange={(e) => this.handleChange(e, 'type')}
                                                    >
                                                        <MenuItem value=""><em>None</em></MenuItem>
                                                        <MenuItem value={`campaign`}>Campagin</MenuItem>
                                                        <MenuItem value={`blog`}>Blog</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            
                                            {type === 'campaign' ? (
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth margin="normal">
                                                        <InputLabel>Select Campaign</InputLabel>
                                                        <Select
                                                            onChange={(e) => this.handleChange(e, 'campaign_id')}
                                                            style={{width: '100%'}}
                                                        >
                                                            {all_campaigns.map((all_campaign, i) => (
                                                                <MenuItem value={all_campaign.id} key={i}>
                                                                    {all_campaign.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            ) : type === 'blog' ? (
                                                <Grid item xs={6}>
                                                    <FormControl fullWidth margin="normal">
                                                        <InputLabel>Select Blog</InputLabel>
                                                        <Select
                                                            onChange={(e) => this.handleChange(e, 'blog_id')}
                                                            style={{width: '100%'}}
                                                        >
                                                            {blog_options.map((blog, i) => (
                                                                <MenuItem value={blog.id} key={i}>
                                                                    {blog.title}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            ) : (
                                                <>&nbsp;</>
                                            )}
                                        </Grid>
                                        <small style={{display: 'block', fontStyle: 'italic', marginTop: '5px'}}>Leave it blank if you don't want any action on the notification</small>
                                        
                                        <Grid container spacing={3} style={{marginTop: '20px'}}>
                                            <Grid item xs={4}>
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    startIcon={<Check />}
                                                    style={{width: '100%'}}
                                                    type="submit"
                                                >
                                                    Save Message
                                                </Button>
                                            </Grid>
                                            
                                            <Grid item xs={3}>

                                            <Button
                                                variant="contained"
                                                color="default"
                                                href='/mobile-notif'
                                            >
                                                Cancel
                                            </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                    
                </Paper>
                <Snackbar anchorOrigin={{ vertical: "top", horizontal: notif_position }} open={openNotif} autoHideDuration={6000} onClose={() => this.handleChangeNotif(false, notif_message, notif_severity, notif_position)}>
                    <Alert onClose={() => this.handleChangeNotif(false, notif_message, notif_severity, notif_position)} severity={notif_severity}>
                        {notif_message}
                    </Alert>
                </Snackbar>
            </Container>
        )
   }
}

export default CreateMessage
