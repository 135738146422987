import React from 'react'
import { Alert } from '@material-ui/lab';
import {
    Container,
    NativeSelect,
    CircularProgress,
    Paper,
    Snackbar,
    Tabs,
    Tab,
    MenuItem,
    FormControl,
    Typography,
    Button,
    InputLabel,
    TextField,
    Grid,
    Select,
  } from "@material-ui/core";

import api from "services/api";

import { reasons, autocheck_bad_reasons } from "utils/basisid";

class BasisId extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            basisid: props.basisid,
            form_basisid: {
                reason: props.basisid ? props.basisid.autocheck_bad_reasons:"",
                status: props.basisid ? props.basisid.status:""
            },
            openSnack: false,
            snackType: true,
            snackMessage: '',
        }
    }

    onChange = (e, f) => {
        let { form_basisid } = this.state;
        form_basisid[f] = e.target.value;
        this.setState({ form_basisid });
    };

    onSubmit = async (e) => {
        e.preventDefault();
        let { basisid, form_basisid } = this.state;
        
        const res = await api.post(`basisid/update-status/${basisid.id}`, form_basisid);
        
        const { data } = res;
        if (data.error) {
          alert(data.message);
          return;
        }
        
        this.setSnackBarMessage('Successfully updated');
    };

    setSnackBarMessage = (msg) => {
        this.setState({openSnack: true, snackMessage: msg});
    }

    render() {
        const { basisid, form_basisid } = this.state;
        const data_reasons = basisid && basisid.reason !== null ? basisid.reason.split(","):[];
        const data_autocheck_bad_reasons = basisid && basisid.autocheck_bad_reasons !== null ? basisid.autocheck_bad_reasons.split(","):[];
        const status_text = {
            0: "Declined",
            1: "Approved",
            2: "Requested",
            3: "Waiting"
        };

        return (
            <Paper className="padding">

                {basisid ? (
                    <>
                        <h2>Result From BasisID</h2>

                        {basisid.status == 1 && (
                            <Alert severity="success">
                                Data Approved
                            </Alert>
                        )}

                        {basisid.status == 2 && (
                            <Alert severity="warning">
                                Please check dashboard basis id to get more details.
                            </Alert>
                        )}

                        {basisid.status == 3 && (
                            <Alert severity="info">
                                On checking..
                            </Alert>
                        )}


                        <p>Status : <b>{status_text[basisid.status]}</b></p>
                        <p>User Hash : {basisid.user_hash}</p>

                        {basisid.status == 0 && (
                            <>
                            <h3>Reasons</h3>
                                <ul>
                                    {data_reasons.map((item, i) => (
                                        <li key={i}>{reasons[item]}</li>
                                    ))}
                                </ul>

                                <h4>Autocheck Bad Reasons</h4>
                                <ul>
                                    {data_autocheck_bad_reasons.map((item, i) => (
                                        <li key={i} dangerouslySetInnerHTML={{__html: autocheck_bad_reasons[item]}}></li>
                                    ))}
                                </ul>
                            </>
                        )}

                        <br />
                        <hr />
                        
                        <h2>Form Status</h2>
                        <form onSubmit={this.onSubmit}>
                            <FormControl fullWidth margin="normal">
                                <InputLabel>Status</InputLabel>
                                <Select
                                    value={form_basisid.status ? form_basisid.status : ""}
                                    onChange={(e) => this.onChange(e, "status")}
                                >
                                    <MenuItem value="3">Registered</MenuItem>
                                    <MenuItem value="1">Approved</MenuItem>
                                    <MenuItem value="2">Requested</MenuItem>
                                    <MenuItem value="0">Declined</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl fullWidth margin="normal">
                                <TextField
                                    margin="normal"
                                    label="Reason"
                                    type="text"
                                    fullWidth
                                    value={form_basisid.reason}
                                    onChange={(e) => this.onChange(e, "reason")}
                                    multiline
                                />
                            </FormControl>

                            <Button
                                variant="contained"
                                type="submit"
                                fullWidth
                                className="spaceBig"
                                color="primary"
                                disabled={basisid.status != 3}
                            >
                                Save
                            </Button>
                        </form>
                    </>
                ) : (
                    <center>
                        <h2>
                            Haven't Registered yet
                        </h2>
                    </center>
                )}

                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={this.state.openSnack}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ openSnack: false })}
                >
                    <div className={this.state.snackType ? "success-alert" : "error-alert"}>
                    <Typography>{this.state.snackMessage}</Typography>
                    </div>
                </Snackbar>
            </Paper>
        )
    }
}

export default BasisId;