import React, { Component } from "react";

import {
  CircularProgress,
  Paper,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import api_public from "services/api_public";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";

import Logo from "images/kapitalboost-logo.png";

class Page extends Component {
  state = {
    loading: false,
    email: "",
    password: "",
    newPassword: "",
    confirmNewPassword: "",
    errorMessage: false,
    message: "",
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onChange = (e, f) => {
    let { state } = this;
    state[f] = e.target.value;
    this.setState({ state });
  };
  onResetPassword = async (e) => {
    e.preventDefault();
    const {
      email,
      password,
      newPassword,
      confirmNewPassword,
    } = this.state;
    this.setState({ loading: true });
    const res = await api_public.post("reset-password", {
      email,
      password,
      newPassword,
      confirmNewPassword,
    });
    const { data } = res;
    this.setState({ loading: false });
    if (data.error_email) {
      alert(data.message);
      window.location.href = "/create-new-password";
    } else if (data.currentPasswordIsNotCorrect) {
      alert(data.message);
      window.location.href = "/create-new-password";
    } else if (data.confirmNewPasswordIsNotCorrect) {
      alert(data.message);
      window.location.href = "/create-new-password";
    } else if (data.password_rules) {
      alert(data.message.newPassword);
      window.location.href = "/create-new-password";
    }
    else {
      alert("Password has been change, please login!");
      window.location.href = "/";
    }
  };

  render() {
    const { loading } = this.state;
    
    return (
      <div id="login">
        <Paper elevation={3} id="paper">
          <div className="padding">
            <div className="center space">
              <img src={Logo} width="70%" alt="img"/>
            </div>

            <Typography className="spaceBig center">
              Please create new password
            </Typography>

            <form onSubmit={this.onResetPassword}>
              <TextField
                autoFocus
                margin="normal"
                label="email"
                type="email"
                fullWidth
                required
                onChange={(e) => this.onChange(e, "email")}
              />
              <TextField
                autoFocus
                margin="normal"
                label="Current Password"
                type="password"
                fullWidth
                required
                onChange={(e) => this.onChange(e, "password")}
              />

              <TextField
                margin="normal"
                label="New Password"
                type="password"
                fullWidth
                required
                onChange={(e) => this.onChange(e, "newPassword")}
              />
              <TextField
                margin="normal"
                label="Confirm New Password"
                type="password"
                fullWidth
                required
                onChange={(e) => this.onChange(e, "confirmNewPassword")}
              />

              {loading ? (
                <Button
                  margin="normal"
                  variant="contained"
                  color="primary"
                  fullWidth
                  className="space spaceBottomX"
                >
                  <CircularProgress color="inherit" size={25} />
                </Button>
              ) : (
                <Button
                  margin="normal"
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  className="space spaceBottomX"
                >
                  Reset Password
                </Button>
              )}
            </form>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withGoogleReCaptcha(Page);
