import React, { Component } from "react";

import {
  Container,
  Button,
  TextField,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import api from "services/api";
import MaterialTable, { MTableToolbar } from "material-table";

class Page extends Component {
  state = {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Container id="partners" maxWidth="xl">
        <MaterialTable
          columns={[
            {
              title: "No",
              render: (rowData) => (rowData ? rowData.tableData.id + 1 : ""),
            },
            { 
              title: "active", 
              render: (data) => {
                if (data.active) {
                  return <CheckIcon style={{ color: 'green' }}/>
                }
                return <CloseIcon color="error"/>
              } 
            },
            {
              title: "Name",
              field: "name",
              width: "10%",
              editComponent: (props) => (
                <TextField
                  type="text"
                  value={props.value}
                  onChange={(e) => props.onChange(e.target.value)}
                  multiline
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              ),
            },
            {
              title: "Url",
              render: (rowData) => (
                <span>{process.env.REACT_APP_LP_URL}/campaign?page=1&a={rowData.id}</span>
              ),
            },
            {
              title: "Affiliation",
              field: "affiliation_count",
            },
            { title: "Created at", field: "created_at" },
          ]}
          title="Partners"
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: "0px 10px" }}>
                  <Button
                    type="button"
                    variant="contained"
                    style={{
                      marginLeft: 5,
                      backgroundColor: "#0069d9",
                      color: "#fff",
                    }}
                    startIcon={<AddIcon />}
                    onClick={() => this.props.history.push("partners-add")}
                  >
                    Add Partner
                  </Button>
                </div>
              </div>
            ),
          }}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100, 200],
            actionsColumnIndex: -1,
            filtering: true,
          }}
          data={(query) =>
            new Promise(async (resolve) => {
              let field = query.orderBy ? query.orderBy.field : "";
              let filters = [];
              if (query.filters.length > 0) {
                let column = {};
                let value = [];
                query.filters.map((data) => {
                  column.field = data.column.field;
                  value = data.value;
                  filters.push({ column: column, value: value });
                  return data;
                });
                filters = JSON.stringify(filters);
              }
              const result = await api.get(`partner?limit=${
                query.pageSize
              }&offset=${query.page * query.pageSize}&search=${query.search}
                     &orderDirection=${
                       query.orderDirection
                     }&field=${field}&filters=${filters}`);
              const { data } = result;
              resolve({
                data: data.data,
                page: query.page,
                totalCount: data.total,
              });
            })
          }
          onRowClick={(e, rowData) =>
            this.props.history.push(`/partner/${rowData.id}`)
          }
          editable={{
            onRowDelete: (oldData) =>
              new Promise(async (resolve) => {
                await api.delete(`partner/${oldData.id}`);
                console.log(oldData.id);
                resolve();
              }),
          }}
        />
      </Container>
    );
  }
}

export default Page;
