import React, { Component } from "react";
import AsyncSelect from 'react-select/async';

class CustomAsyncSelect extends Component {

    render() {
        return (
            <div style={{ minWidth: 250 }}>
                <AsyncSelect
                    closeMenuOnSelect={true}
                    isClearable={true}// For Single Select
                    cacheOptions
                    loadOptions={this.props.loadOptions}
                    defaultOptions
                    onInputChange={(e) => this.props.onInputChange(e)}
                    value={this.props.value}
                    onChange={(option) => this.props.onChange(option)}
                    isMulti={this.props.isMulti}
                    placeholder = {this.props.placeholder ? this.props.placeholder  : 'Search...'}
                    styles={selectStyles}
                    options={this.props.options}
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            neutral0: '#ffffff',
                            neutral50: '#333333',
                        },
                    })} />
            </div>
        )
    }

}
export default CustomAsyncSelect

const selectStyles = {
    option: (styles, { isFocused }) => {
        return {
            ...styles,
            backgroundColor: isFocused ? '#333333' : null,
            color: isFocused ? 'white' : null,
        };
    },
    singleValue: (styles) => ({ ...styles, color: 'black' }),
};