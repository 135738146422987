import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactSelect from "react-select";
import {
  Container,
  Paper,
  Snackbar,
  Tabs,
  Tab,
  Typography,
  Button,
  InputLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
} from "@material-ui/core";
import { AddBox } from "@material-ui/icons";
import api from "services/api";
import hp from "services/hp";
import MaterialTable from "material-table";
import CustomFilter from "components/CustomFilter";
import CustomAsyncSelect from "components/CustomAsyncSelect";
import { Alert, AlertTitle } from "@material-ui/lab";

class Page extends Component {
  constructor() {
    super();
    this.state = {
      users: [],
      is_refresh: false,
      addDialogOpen: false,
      userOptions: [],
      bankAccountOptions: [],
      selectedBankTab: 0,
      all_banks: [],
      proof: null,
      hasError: false,
      errorMessages: ["Something went wrong."],
      openSuccessSnack: false,
      bank: {
        account_name: "",
        account_number: "",
        bank_id: null,
        country: "-",
        currency: "-",
        bank_code: "-",
        bic_code: "-",
        iban_code: "-",
      },
      isSuperAdmin: false,
    };
    this.withdrawTable = React.createRef();
    this.useStyle = {
      addBtn: {
        margin: ".5rem",
        float: "right",
        zIndex: 1,
      },
    };

    // new withdraw
    this.selectedUser = null;
    this.selectedBankAccount = null;
    this.amount = 0.0;
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
  }

  onStart = async () => {
    let { is_refresh, users } = this.state;
    if (is_refresh) {
      hp.removeUsers();
    }
    if (hp.getUsers()) {
      users = hp.getUsers();
    } else {
      var _users = await hp.get_users();
      _users.forEach((element) => {
        users.push({
          id: element.id,
          name: element.firstname + " " + element.lastname,
        });
      });
      hp.saveUsers(users);
    }
    this.setState({ users, is_refresh: false });
    await api.get("select/banks").then((res) => {
      const array = Object.keys(res.data).map((key) => res.data[key]);
      this.setState({ all_banks: array });
    });
    const admin_res = await api.get(`admin_profile`);
    const admin = admin_res.data;
    if (admin.role === "super admin") {
      this.setState({ isSuperAdmin: true });
    }
  };

  isNotSuperAdmin() {
    alert("Sorry, you do not have access to view this page.");
  }

  onAttachSub = async (e, props) => {
    if (!props) {
      this.setState({ proof: "Uploading..." });
    }
    let formData = new FormData();
    formData.append("dir", "user-withdraw");
    formData.append("image", e.target.files[0]);
    let image_url_res = await api.post("upload-image", formData);
    if (props) {
      props.onChange(image_url_res.data.file_name);
    } else {
      this.setState({ proof: image_url_res.data.file_name });
    }
  };

  loadSelectOptions = (inputValue, type) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        switch (type) {
          case "user":
            resolve(
              this.state.userOptions.filter((i) =>
                i.label.toLowerCase().includes(inputValue.toLowerCase())
              )
            );
            break;

          case "bank-account":
            if (inputValue) {
              resolve(
                this.state.bankAccountOptions.filter((i) =>
                  i.label.toLowerCase().includes(inputValue.toLowerCase())
                )
              );
            } else {
              resolve(this.state.bankAccountOptions);
            }
            break;

          default:
            resolve([]);
            break;
        }
      }, 1000);
    });
  };

  onSelectChangeInput = async (keyword, type) => {
    try {
      switch (type) {
        case "user":
          if (keyword.length > 2) {
            const response = await api.get(`user/search?name=${keyword}`);
            const items = response.data;
            this.setState({
              userOptions: items.map((item) => {
                return {
                  value: item.id,
                  // label: item.firstname + " " + item.lastname,
                  label:
                    item.firstname +
                    " " +
                    item.lastname +
                    " (" +
                    item.ic_name +
                    ")",
                };
              }),
            });
          } else {
            this.setState({ userOptions: [] });
          }
          break;

        case "bank-account":
          //
          break;

        default:
          break;
      }
    } catch (error) {}
  };

  onChange = async (value, type) => {
    switch (type) {
      case "user":
        this.setState({ bankAccountOptions: [] });
        this.selectedUser = value;
        if (this.selectedUser) {
          const response = await api.get(`bank`, {
            params: {
              user_id: this.selectedUser ? this.selectedUser.value : null,
            },
          });
          const items = response.data.data;
          this.setState({
            bankAccountOptions: items.map((item) => {
              return {
                value: item.id,
                label:
                  item.bank_name +
                  " - " +
                  item.account_number +
                  " (" +
                  item.account_name +
                  ")",
              };
            }),
          });
        }
        break;

      case "bank-account":
        this.selectedBankAccount = value;
        break;

      case "amount":
        if (value.target.value > -1) {
          this.amount = value.target.value;
        } else {
          value.target.value = 0;
        }
        break;

      default:
        break;
    }
  };

  bankTabOnChange = (e, newValue) => {
    this.selectedBankAccount = null;
    this.setState({
      selectedBankTab: newValue,
      bank: {
        account_name: "",
        account_number: "",
        bank_id: null,
        country: "-",
        currency: "-",
        bank_code: "-",
        bic_code: "-",
        iban_code: "-",
      },
    });
  };

  submitWithdraw = async () => {
    try {
      await api
        .post("withdraw", {
          user_id: this.selectedUser ? this.selectedUser.value : null,
          bank_account_id: this.selectedBankAccount
            ? this.selectedBankAccount.value
            : null,
          amount: this.amount,
          bank: this.state.bank,
          proof: this.state.proof,
        })
        .then((response) => {
          if (response.isAxiosError) {
            this.setError(response.response.data.message /* , 5000 */);
          } else {
            this.resetNewWithdrawal();
            this.withdrawTable.current.retry();
            this.addDialogClickClose();
            this.setState({ openSuccessSnack: true });
          }
        })
        .catch((errors) => {
          console.log("catch, axios");
          console.log(errors);
        });
    } catch (error) {
      console.log("catch, try");
      console.log(error);
    }
  };

  resetNewWithdrawal = () => {
    this.selectedUser = null;
    this.selectedBankAccount = null;
    this.amount = 0.0;
    this.bankTabOnChange(null, 0);
    this.setState({ proof: null });
  };

  setError = (messages, timeout = 0) => {
    this.setState({ hasError: true, errorMessages: messages });
    if (timeout) {
      setTimeout(() => {
        this.resetError();
      }, timeout);
    }
  };

  addDialogClickOpen = () => {
    this.setState({ addDialogOpen: true });
  };
  addDialogClickClose = () => {
    this.setState({ addDialogOpen: false });
  };

  resetError = () => {
    this.setState({ hasError: false, errorMessages: [] });
  };

  render() {
    const { users, addDialogOpen, isSuperAdmin } = this.state;

    return (
      <Container id="withdraw" maxWidth="xl">
        <Paper>
          <Button
            color="primary"
            style={this.useStyle.addBtn}
            onClick={this.addDialogClickOpen}
          >
            <AddBox color="action" />
          </Button>

          <Dialog
            open={addDialogOpen}
            onClose={this.addDialogClickClose}
            fullWidth={true}
            maxWidth="md"
          >
            <DialogTitle id="alert-dialog-title">
              Add new withdrawal
            </DialogTitle>
            <DialogContent
              style={{
                minHeight: "400px",
              }}
            >
              <Grid container spacing={2}>
                <Grid item sm={12} md={6}>
                  <div style={{ width: "100%", marginBottom: "20px" }}>
                    <InputLabel
                      id="select-user-label"
                      style={{ margin: "10px 0" }}
                    >
                      User
                    </InputLabel>
                    <CustomAsyncSelect
                      cacheOptions
                      loadOptions={(e) => this.loadSelectOptions(e, "user")}
                      defaultOptions
                      // options={this.state.userOptions}
                      onInputChange={(e) => this.onSelectChangeInput(e, "user")}
                      onChange={(e) => this.onChange(e, "user")}
                      value={this.selectedUser}
                      placeholder="Search... ( min. 3 characters )"
                      id="select-user"
                    />
                  </div>
                  <div style={{ width: "100%", marginBottom: "20px" }}>
                    <InputLabel style={{ margin: "10px 0" }}>Amount</InputLabel>
                    <TextField
                      style={{ width: "100%" }}
                      type="number"
                      defaultValue={this.amount}
                      onChange={(e) => this.onChange(e, "amount")}
                    />
                  </div>
                  <div style={{ width: "100%", marginBottom: "20px" }}>
                    <InputLabel style={{ margin: "10px 0" }}>Proof</InputLabel>
                    <div>
                      {this.state.proof && (
                        <div>
                          <img
                            src={this.state.proof}
                            alt={this.state.proof}
                            style={{ width: "100%", textAlign: "center" }}
                          />
                        </div>
                      )}
                      <input
                        accept="image/*"
                        id={`new-withdraw-proof-file`}
                        type="file"
                        style={{ display: "none" }}
                        onChange={(e) => this.onAttachSub(e, null)}
                      />
                      <label htmlFor="new-withdraw-proof-file">
                        <Button
                          variant="contained"
                          color="secondary"
                          component="span"
                          className="spaceBig"
                        >
                          Browse
                        </Button>
                      </label>
                    </div>
                  </div>
                </Grid>
                <Grid item sm={12} md={6}>
                  {this.selectedUser && (
                    <div
                      style={{
                        width: "100%",
                        margin: "10px 0 20px",
                        border: "1px solid lightgrey",
                      }}
                    >
                      <Tabs
                        value={this.state.selectedBankTab}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={this.bankTabOnChange}
                        aria-label="disabled tabs example"
                      >
                        <Tab label="Select Bank Account" />
                        <Tab label="New Bank Account" />
                      </Tabs>
                      <div style={{ padding: "20px" }}>
                        {this.state.selectedBankTab === 0 && (
                          <div style={{ width: "100%", marginBottom: "20px" }}>
                            <ReactSelect
                              className="basic-single"
                              classNamePrefix="select"
                              defaultValue={this.selectedBankAccount}
                              onChange={(e) => this.onChange(e, "bank-account")}
                              isLoading={false}
                              isClearable={true}
                              name="bank-account"
                              options={this.state.bankAccountOptions}
                            />
                          </div>
                        )}
                        {this.state.selectedBankTab === 1 && (
                          <div style={{ width: "100%", marginBottom: "20px" }}>
                            <div
                              style={{ width: "100%", marginBottom: "20px" }}
                            >
                              <InputLabel style={{ margin: "10px 0" }}>
                                Bank
                              </InputLabel>
                              <ReactSelect
                                defaultValue={this.state.bank.bank_id}
                                onChange={(option) => {
                                  const { bank } = this.state;
                                  bank.bank_id = option.value;
                                  bank.country = option.country;
                                  bank.currency = option.currency;
                                  bank.bank_code = option.bank_code;
                                  bank.bic_code = option.bic_code;
                                  bank.iban_code = option.iban_code;
                                  this.setState({ bank });
                                  console.log(this.state.bank);
                                }}
                                options={this.state.all_banks}
                              />
                            </div>
                            <div
                              style={{ width: "100%", marginBottom: "20px" }}
                            >
                              <InputLabel style={{ margin: "10px 0" }}>
                                Country
                              </InputLabel>
                              <TextField
                                disabled
                                style={{ width: "100%" }}
                                value={this.state.bank.country}
                                variant="filled"
                              />
                            </div>
                            <div
                              style={{ width: "100%", marginBottom: "20px" }}
                            >
                              <InputLabel style={{ margin: "10px 0" }}>
                                Account name
                              </InputLabel>
                              <TextField
                                style={{ width: "100%" }}
                                defaultValue={this.state.bank.account_name}
                                onChange={(e) => {
                                    const {bank} = this.state;
                                    bank.account_name = e.target.value;
                                    this.setState({bank});
                                  }
                                }
                              />
                            </div>
                            <div
                              style={{ width: "100%", marginBottom: "20px" }}
                            >
                              <InputLabel style={{ margin: "10px 0" }}>
                                Account number
                              </InputLabel>
                              <TextField
                                style={{ width: "100%" }}
                                defaultValue={this.state.bank.account_number}
                                onChange={(e) => {
                                    const {bank} = this.state;
                                    bank.account_number = e.target.value;
                                    this.setState({bank});
                                  }
                                }
                              />
                            </div>
                            <div
                              style={{ width: "100%", marginBottom: "20px" }}
                            >
                              <InputLabel style={{ margin: "10px 0" }}>
                                Currency
                              </InputLabel>
                              <TextField
                                disabled
                                style={{ width: "100%" }}
                                value={this.state.bank.currency}
                                variant="filled"
                              />
                            </div>
                            <div
                              style={{ width: "100%", marginBottom: "20px" }}
                            >
                              <InputLabel style={{ margin: "10px 0" }}>
                                BIC Code
                              </InputLabel>
                              <TextField
                                disabled
                                style={{ width: "100%" }}
                                value={this.state.bank.bic_code}
                                variant="filled"
                              />
                            </div>
                            <div
                              style={{ width: "100%", marginBottom: "20px" }}
                            >
                              <InputLabel style={{ margin: "10px 0" }}>
                                IBAN Code
                              </InputLabel>
                              <TextField
                                disabled
                                style={{ width: "100%" }}
                                value={this.state.bank.iban_code}
                                variant="filled"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Grid>
              </Grid>
              {this.state.hasError === true && (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  <ul style={{ padding: 0 }}>
                    {this.state.errorMessages.map((element) => {
                      return <li>{element}</li>;
                    })}
                  </ul>
                </Alert>
              )}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.submitWithdraw}
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>

          <MaterialTable
            tableRef={this.withdrawTable}
            title="KB Wallet Withdrawal"
            columns={[
              {
                title: "No",
                field: "id",
                editable: "never",
                render: (rowData) => (rowData ? rowData.tableData.id + 1 : ""),
              },
              {
                title: "User",
                field: "user.id",
                editable: "never",
                render: (rowData) => (
                  <div>
                    {isSuperAdmin ? (
                      <Link to={`/user/${rowData.user.id}`}>
                        {rowData.user.ic_name}
                      </Link>
                    ) : (
                      <Link onClick={this.isNotSuperAdmin}>
                        {rowData.user.ic_name}
                      </Link>
                    )}
                  </div>
                ),
                filterComponent: (props) => (
                  <CustomFilter {...props} data={users} />
                ),
              },
              {
                title: "Amount",
                field: "amount",
                type: "currency",
                editable: "never",
              },
              {
                title: "Bank Name",
                field: "bank.bank_name",
                editable: "never",
              },
              {
                title: "Account Name",
                field: "bank.account_name",
                editable: "never",
              },
              {
                title: "Account Number",
                field: "bank.account_number",
                editable: "never",
              },
              {
                title: "SWIFT / BIC Code",
                field: "bank.bank.bic_code",
                editable: "never",
                filtering: false,
              },
              {
                title: "IBAN Code",
                field: "bank.bank.iban_code",
                editable: "never",
                filtering: false,
              },
              { title: "Status", field: "status", type: "boolean" },
              {
                title: "Date Requested",
                field: "created_at",
                editable: "never",
                type: "datetime",
              },
              {
                title: "Proof",
                field: "proof",
                render: (rowData) =>
                  rowData &&
                  rowData.proof && (
                    <a href={rowData.proof}>
                      <img src={rowData.proof} height="100px" />
                    </a>
                  ),
                filtering: false,
                editComponent: (props) => (
                  <div>
                    {props.rowData.proof && (
                      <div>
                        <img src={props.rowData.proof} height="100px" />
                      </div>
                    )}
                    <input
                      accept="image/*"
                      id={`withdraw-proof-file`}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => this.onAttachSub(e, props)}
                    />
                    <label htmlFor="withdraw-proof-file">
                      <Button
                        variant="contained"
                        color="secondary"
                        component="span"
                        className="spaceBig"
                      >
                        Browse
                      </Button>
                    </label>
                  </div>
                ),
              },
            ]}
            options={{
              pageSize: 10,
              pageSizeOptions: [5, 10, 20, 50],
              actionsColumnIndex: -1,
              filtering: true,
            }}
            data={(query) =>
              new Promise(async (resolve) => {
                let field = query.orderBy ? query.orderBy.field : "";
                let filters = [];
                if (query.filters.length > 0) {
                  query.filters.map((data) => {
                    let column = {};
                    let value = [];
                    column.field = data.column.field;
                    if (data.column.field === "user.id") {
                      if (data.value.length > 0) {
                        data.value.map((v) => {
                          value.push(v.id);
                          return v;
                        });
                      }
                    } else {
                      value = data.value;
                    }
                    filters.push({ column: column, value: value });
                    return data;
                  });
                  filters = JSON.stringify(filters);
                }
                const result = await api.get(`withdraw?limit=${
                  query.pageSize
                }&offset=${query.page * query.pageSize}&search=${query.search}
                &orderDirection=${
                  query.orderDirection
                }&field=${field}&filters=${filters}`);
                const { data } = result;
                console.log(data);
                resolve({
                  data: data.data,
                  page: query.page,
                  totalCount: data.total,
                });
              })
            }
            editable={{
              onRowUpdate: (newData) =>
                new Promise(async (resolve) => {
                  delete newData.created_at;
                  delete newData.updated_at;
                  delete newData.user;
                  await api.put(`withdraw/${newData.id}`, newData);
                  this.setState({ openSuccessSnack: true });
                  resolve();
                }),
            }}
            actions={[
              {
                icon: "refresh",
                tooltip: "Refresh Data",
                isFreeAction: true,
                onClick: () => {
                  this.setState({is_refresh : true});
                  this.withdrawTable.current &&
                    this.withdrawTable.current.onQueryChange();
                  this.onStart();
                },
              },
            ]}
          />
        </Paper>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.openSuccessSnack}
          autoHideDuration={6000}
          onClose={() => this.setState({ openSuccessSnack: false })}
        >
          <div className="success-alert">
            <Typography>Successfully updated</Typography>
          </div>
        </Snackbar>
      </Container>
    );
  }
}

export default Page;
