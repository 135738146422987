import React, { Component } from "react";

import {
  Container,
  CircularProgress,
  Paper,
  Snackbar,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import api from "services/api";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: {},
      category: "",
      question: "",
      answer: "",
      add_loading: false,
      openSuccessSnack: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  onAdd = async (e) => {
    e.preventDefault();
    const { partner } = this.state;
    this.setState({ add_loading: true });
    const res = await api.post(`partner`, {
      name: partner.name,
    });
    const { data } = res;
    this.setState({ add_loading: false });
    if (data.error) {
      alert(data.message);
    }
    window.scrollTo(0, 0);
    this.setState({ openSuccessSnack: true });
    this.props.history.push(`/partner`);
  };

  onChange = (e, f) => {
    let { partner } = this.state;
    partner[f] = e.target.value;
    console.log(partner[f]);
    this.setState({ partner });
  };

  render() {
    const { partner, add_loading } = this.state;
    return (
      <Container id="faq" maxWidth="xl">
        <Paper className="padding">
          <Typography>Add Partner</Typography>
          <form onSubmit={this.onAdd}>
            <TextField
              margin="normal"
              label="Partner"
              type="text"
              fullWidth
              required
              value={partner.name}
              placeholder="Type Partner here"
              onChange={(e) => this.onChange(e, "name")}
            />
            {add_loading ? (
              <Button
                margin="normal"
                variant="contained"
                color="primary"
                fullWidth
                className="space"
              >
                <CircularProgress color="default" size={25} />
              </Button>
            ) : (
              <Button
                variant="contained"
                type="submit"
                fullWidth
                className="spaceBig"
                color="primary"
              >
                Add
              </Button>
            )}
          </form>
        </Paper>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.openSuccessSnack}
          autoHideDuration={6000}
          onClose={() => this.setState({ openSuccessSnack: false })}
        >
          <div className="success-alert">
            <Typography>Successfully updated</Typography>
          </div>
        </Snackbar>
      </Container>
    );
  }
}

export default Page;
