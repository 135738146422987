import React, { Component } from "react";

import {
  Container,
} from "@material-ui/core";
import api from "services/api";
import MaterialTable from "material-table";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = { isSuperAdmin: false, role: "" };
    this.tableRef = React.createRef();
  }
  onStart = async () => {
    const admin_res = await api.get(`admin_profile`);
    const admin = admin_res.data;
    if (admin.role === "super admin") {
      console.log(admin.role);
      const role = "Super Admin";
      console.log(role);
      this.setState({ isSuperAdmin: true, role });
    } else {
      const role = "Admin";
      console.log(role);
      this.setState({ role });
    }
    console.log(admin);
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
  }

  render() {
    const { isSuperAdmin, role } = this.state;
    console.log(isSuperAdmin);
    console.log(role);
    return (
      <Container id="Admins" maxWidth="xl">
        <MaterialTable
          tableRef={this.tableRef}
          columns={[
            { title: "No", field: "id" },
            { title: "Name", field: "name" },
            { title: "Email", field: "email" },
            {
              title: "Password",
              field: "password",
              editable: "onAdd",
              filtering: false,
            },
            {
              title: "Role",
              field: "role",
              lookup: {
                "super admin": role,
                admin: "Admin",
              },
            },
            {
              title: "Created on",
              field: "created_at",
              editable: "never",
              type: "datetime",
            },
          ]}
          title="Admins"
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100, 200],
            actionsColumnIndex: -1,
            filtering: true,
          }}
          data={(query) =>
            new Promise(async (resolve) => {
              try {
                let field = query.orderBy ? query.orderBy.field : "";
                let filters = [];
                if (query.filters.length > 0) {
                  let column = {};
                  let value = [];
                  query.filters.map((data) => {
                    column.field = data.column.field;
                    value = data.value;
                    filters.push({ column: column, value: value });
                    return data;
                  });
                  filters = JSON.stringify(filters);
                }
                const result = await api.get(`admin?limit=${
                  query.pageSize
                }&offset=${query.page * query.pageSize}&search=${query.search}
                        &orderDirection=${
                          query.orderDirection
                        }&field=${field}&filters=${filters}`);
                const { data } = result;
                resolve({
                  data: data.data,
                  page: query.page,
                  totalCount: data.total,
                });
              } catch (e) {}
            })
          }
          onRowClick={(e, rowData) =>
            this.props.history.push(`/admin/${rowData.id}`)
          }
          editable={{
            onRowAdd: (newData) =>
              new Promise(async (resolve) => {
                await api.post("admin", newData);
                resolve();
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve) => {
                await api.delete(`admin/${oldData.id}`);
                resolve();
              }),
          }}
          actions={[
            {
              icon: "refresh",
              tooltip: "Refresh Data",
              isFreeAction: true,
              onClick: () =>
                this.tableRef.current && this.tableRef.current.onQueryChange(),
            },
          ]}
        />
      </Container>
    );
  }
}

export default Page;
