import React, { Component } from "react";

class Page extends Component {
    render() {
        return (
            <div id="login">

            <div align="center" className="padding">
                <h1>Page Not Found</h1>

                <a href="/">Go Back</a>
            </div>
            </div>
        );
    }
}

export default Page;