import React, { Component } from "react";

import {
  Container,
  Button,
} from "@material-ui/core";
import api from "services/api";
import MaterialTable, { MTableToolbar } from "material-table";
import SaveAltIcon from "@material-ui/icons/SaveAlt";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      campaign: "", //props.location.state.campaign
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
  }

  onStart = async () => {
    const { id } = this.state;
    let campaign_res = await api.get(`campaign/${id}`);
    let campaign = campaign_res.data;
    this.setState({ id, campaign });
  };

  exportToExcel = async (id) => {
    const { campaign } = this.state;
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const dataInvestments = await api.get(`campaign-investment-export/${id}`);
    const ws = XLSX.utils.json_to_sheet(dataInvestments.data.data);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([excelBuffer], {
      type: fileType,
    });
    return FileSaver.saveAs(
      blob,
      `KB-investment-report-for-${campaign.name}` + fileExtension
    );
  };

  render() {
    const { id, campaign } = this.state;
    return (
      <Container id="investment-report" maxWidth="xl">
        <MaterialTable
          columns={[
            {
              title: "No",
              render: (rowData) => (rowData ? rowData.tableData.id + 1 : ""),
            },
            { title: "Investor Name", field: "user.ic_name" },
            { title: "IC Number", field: "user.nric" },
            { title: "ID Type", field: "user.ic_type" },
            { title: "Nationality", field: "user.nationality" },
            { title: "Email", field: "user.email" },
            { title: "Amount Invested", field: "amount" },
            {
              title: "Profit",
              render: (rowData) =>
                (
                  Math.round(
                    rowData.amount *
                      (1 + rowData.campaign_return.return * 0.01) *
                      100
                  ) / 100
                ).toFixed(2),
            },
          ]}
          title={`${campaign.name}'s Investments Report`}
          components={{
            Toolbar: (props) => (
              <div>
                <MTableToolbar {...props} />
                <div style={{ padding: "0px 10px" }}>
                  <Button
                    type="button"
                    variant="contained"
                    style={{
                      marginLeft: 5,
                      backgroundColor: "#28a745",
                      color: "#fff",
                    }}
                    startIcon={<SaveAltIcon />}
                    onClick={() => this.exportToExcel(id)}
                  >
                    Export to Excel
                  </Button>
                </div>
              </div>
            ),
          }}
          data={(query) =>
            new Promise(async (resolve) => {
              const result = await api.get(
                `campaign-investment?campaign_id=${id}limit=${
                  query.pageSize
                }&offset=${query.page * query.pageSize}&search=${query.search}`
              );
              const { data } = result;
              resolve({
                data: data.data,
                page: query.page,
                totalCount: data.total,
              });
            })
          }
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 30, 50],
            actionsColumnIndex: -1,
            search: false,
            exportButton: false, // default is true
            exportCsv: (columns, data) => {
              const fileType =
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
              const fileExtension = ".xlsx";
              const ws = XLSX.utils.json_to_sheet(data);
              const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
              const excelBuffer = XLSX.write(wb, {
                bookType: "xlsx",
                type: "array",
              });
              const dataInvesment = new Blob([excelBuffer], { type: fileType });
              const fileName = `KB-investment-report-for-${campaign.name}`;
              FileSaver.saveAs(dataInvesment, fileName + fileExtension);
            },
            exportFileName: `KB-investment-report-for-${campaign.name}`,
          }}
          editable={{
            onRowDelete: (oldData) =>
              new Promise(async (resolve) => {
                await api.delete(`campaign-investment/${oldData.id}`);
                resolve();
              }),
          }}
        />
      </Container>
    );
  }
}

export default Page;
