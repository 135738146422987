import React, { Component } from 'react'

import { Container, CircularProgress, Paper, Snackbar, Typography, TextField, } from '@material-ui/core'
import api from 'services/api'

class Page extends Component {

   constructor(props) {
      super(props)
      this.state = {
         id: props.match.params.id,
         contact: null,
         openSuccessSnack: false,
      }
   }

   componentDidMount() {
      window.scrollTo(0, 0)
      this.onStart()
   }
   onStart = async () => {
      const { id } = this.state
      const res = await api.get(`contact/${id}`)
      const contact = res.data
      this.setState({ contact })
   }


   render() {
      const { contact } = this.state
      return (
         <Container id="contact" maxWidth="xl">
            {contact ?
               <Paper className="padding">

                  <TextField margin="normal" label="Name" type="text" fullWidth required value={contact.name} />
                  <TextField margin="normal" label="Email" type="text" fullWidth required value={contact.email} />
                  <TextField margin="normal" label="Message" type="text" fullWidth required value={contact.info} multiline />
                  <TextField margin="normal" label="Submitted on" type="text" fullWidth required value={contact.created_at}  />


               </Paper>
               :
               <div className="loading"><CircularProgress /></div>
            }
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right', }} open={this.state.openSuccessSnack} autoHideDuration={6000} onClose={() => this.setState({ openSuccessSnack: false })}>
               <div className="success-alert" >
                  <Typography>Successfully updated</Typography>
               </div>
            </Snackbar>
         </Container>
      )
   }
}

export default Page
