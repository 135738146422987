import React, { Component } from "react";

class MekariCallback extends Component {
    render() {
        const queryParameters  = new URLSearchParams(window.location.search)
        const code = queryParameters.get('code')
        const locale = queryParameters.get('locale')

        return (
            <div align="center" style={{padding: 25}}>
                <h1>Callback From Mekari Esign</h1>
                <p>Code : {code}</p>
                <p>Locale : {locale}</p>
            </div>
        )
    }
}

export default MekariCallback;