import axios from "axios";

let apiUrl = process.env.REACT_APP_API_URL

let api = axios.create({
  baseURL: apiUrl,
  headers: {
    id: localStorage.getItem("id"),
    Authorization: "Bearer " + localStorage.getItem("token"),
  },
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status == 401) {
      localStorage.removeItem("id");
      localStorage.removeItem("token");
      window.location.reload();
    }
    return Promise.reject(error.response);
  }
);

export default api;
