import React from 'react'
import { Alert } from '@material-ui/lab';
import {
    Container,
    NativeSelect,
    CircularProgress,
    Paper,
    Snackbar,
    Tabs,
    Tab,
    MenuItem,
    FormControl,
    Typography,
    Button,
    InputLabel,
    TextField,
    Grid,
    Select,
  } from "@material-ui/core";

import api from "services/api";
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import { DeleteOutline } from '@material-ui/icons';

class Veriff extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: props.user,
            veriff: props.veriff,
            openSnack: false,
            snackType: true,
            snackMessage: '',
            loading: false,
            deleteLoading: false
        }
    }

    setSnackBarMessage = (msg) => {
        this.setState({openSnack: true, snackMessage: msg});
    }

    importDocuments = async () => {
        this.setState({loading: true})
        
        await api.get(`veriff/import/user/documents/${this.state.user.id}`).then(res => {
            alert("documents imported")
            this.setState({loading: false, user: res.data.user})
        }).catch(err => {
            alert("failed to import documents")
            alert(err.data.message)
            this.setState({loading: false})
        })
    }

    deleteSession = async () => {
        this.setState({deleteLoading: true})
        
        await api.get(`veriff/delete/${this.state.veriff.session_id}`).then(res => {
            alert("veriff session deleted")
            this.setState({deleteLoading: false, veriff: null})
        }).catch(err => {
            alert("failed to delete session veriff")
            alert(err.data.message)
            this.setState({loading: false})
        })
    }

    render() {
        const { user, veriff, loading, deleteLoading } = this.state;

        return (
            <Paper className="padding">

                {veriff ? (
                    <>
                        <h2>Result From Veriff</h2>

                        {veriff.code == 9001 && (
                            <Alert severity="success">
                                {veriff.status}
                            </Alert>
                        )}

                        {veriff.code == "9103" && (
                            <Alert severity="warning">
                                Please check dashboard Veriff to get more details.
                            </Alert>
                        )}

                        {veriff.code == 3 && (
                            <Alert severity="info">
                                On checking..
                            </Alert>
                        )}


                        <p>Session ID : {veriff.session_id}</p>
                        <p>Status : {` `}
                            <b style={{textTransform: "capitalize"}}>
                                {veriff.status !== null ? veriff.status.replace(/_/g, " ") : "-"}
                            </b>
                        </p>
                        <p>Reason : <b>{veriff.reason}</b></p>
                        <br />

                        <Grid container spacing={3}>
                        <Grid item xs={4}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                size="large"
                                href={`https://station.veriff.com/verifications/${veriff.session_id}`} 
                                target='_blank'
                                endIcon={<ScreenShareIcon />}>
                                See the Detail on Veriff Station
                            </Button>
                        </Grid>
                            {(user.nric_file === null && user.nric_file_back === null) && (
                                <Grid item xs={4}>
                                    <Button 
                                        variant="contained" 
                                        color="warning" 
                                        size="large"
                                        target='_blank'
                                        endIcon={<ScreenShareIcon />}
                                        onClick={this.importDocuments}
                                        disabled={loading}>
                                            {loading ? "Loading..." : "Import Documents"}
                                    </Button>
                                </Grid>
                            )}
                            <Grid item xs={4} align="right">
                                <Button 
                                        variant="outlined"
                                        target='_blank'
                                        endIcon={<DeleteOutline />}
                                        onClick={this.deleteSession}
                                        disabled={deleteLoading}>
                                            {deleteLoading ? "Loading..." : "Delete Session"}
                                    </Button>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    <center>
                        <h2>
                            Haven't Registered yet
                        </h2>
                    </center>
                )}

                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "right" }}
                    open={this.state.openSnack}
                    autoHideDuration={6000}
                    onClose={() => this.setState({ openSnack: false })}
                >
                    <div className={this.state.snackType ? "success-alert" : "error-alert"}>
                    <Typography>{this.state.snackMessage}</Typography>
                    </div>
                </Snackbar>
            </Paper>
        )
    }
}

export default Veriff;