import React, { Component } from "react";

import {
  Container,
  Typography,
  Grid,
} from "@material-ui/core";
import api from "services/api";
import MaterialTable from "material-table";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      total: "",
    };
    this.tableRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.sumOfPayoutAmount();
  }

  sumOfPayoutAmount = async () => {
    const { id } = this.state;
    console.log(id);
    const res = await api.get(`sum-payout-amount?id=${id}`);
    console.log(res);
    const total = res.data.data;
    console.log(total);
    this.setState({ total });
  };

  render() {
    const { id } = this.state;
    const { total } = this.state;
    return (
      <div>
        <Container id="get-funded" maxWidth="xl">
          <MaterialTable
            // tableRef={this.tableRef}
            columns={[
              { title: "Investor", field: "investment.user.ic_name", operator: 'like' },
              {
                title: "Payment Type",
                field: "investment.payment_method",
                lookup: {
                  "bank-transfer": "Bank Transfer",
                  xfers: "Xfers",
                  paypal: "Paypal",
                  "kb-wallet": "KB Wallet",
                },
              },
              {
                title: "Date",
                field: "date",
                type: "date",
              },
              {
                title: "Payment Amount",
                field: "amount",
                type: "currency",
                filtering: true,
              },
            ]}
            title="Summary of Payouts"
            options={{
              pageSize: 10,
              pageSizeOptions: [5, 10, 20, 50, 100, 200],
              actionsColumnIndex: -1,
              filtering: true,
              search: true,
            }}
            data={(query) =>
              new Promise(async (resolve) => {
                console.log('dump', query);
                let filters = [];
                query.filters.map((data) => {
                  let column = {};
                  let value = [];
                  column.field = data.column.field;
                  column.operator = data.column.operator;
                  value = data.value;
                  filters.push({ column: column, value: value });
                  return data;
                });
                const result = await api.get(
                  `campaign-master-payout-summary?id=${id}&limit=${query.pageSize}&offset=${query.page * query.pageSize}&search=${query.search}&filters=${JSON.stringify(filters)}`
                );
                const { data } = result;
                resolve({
                  data: data.data,
                  page: query.page,
                  totalCount: data.total,
                });
              })
            }
          />
          <Grid container>
            <Grid item xs={8}></Grid>
            {/* <Grid item xs={4}></Grid> */}
            <Grid item xs={4}>
              <Typography variant="h6" className="bold spaceBottomX">
                Sum of all payout amount = ${total}
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </div>
    );
  }
}

export default Page;
