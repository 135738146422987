import React, {Component, Fragment} from 'react'
import {Link} from 'react-router-dom'

import {Container, CircularProgress, Typography, Grid, Card, CardContent, CardHeader} from '@material-ui/core'
import {Alert} from '@material-ui/lab'
import api from 'services/api'
import hp from 'services/hp'

class Page extends Component {

  constructor() {
    super()
    this.state = {
      dashboards: null
    }
  }

  componentDidMount() {
    window.scrollTo(0,0)
    this.onStart()
  }


  onShow = () => {

  }

  onStart = async() => {

    const dashboards_res = await api.get(`dashboard`)
    const dashboards = dashboards_res.data

    this.setState({ dashboards })
  }

  render() {
    const {dashboards} = this.state
    return (
      <Container maxWidth="lg">


        {dashboards ?
          <Fragment>
            {dashboards.withdraw_count > 0 ?
              <Alert severity="warning" className="spaceBottom">
                <Link to="/withdraw" >You have {dashboards.withdraw_count} new withdrawal request.</Link>
              </Alert>
            :
            <Alert severity="success" className="spaceBottom" >
              You have no new withdrawal request.
            </Alert>
          }
            {dashboards.review_count > 0 ?
              <Alert severity="warning" className="spaceBottom">
                <Link to="/user" >{dashboards.review_count} User are waiting to be reviewed.</Link>
              </Alert>
            :
            <Alert severity="success" className="spaceBottom" >
              No User is waiting to be reviewed
            </Alert>
          }
            <Grid container spacing={2}>
              <Grid item xs={3} >
                <Card   >
                  <CardContent>
                    <CardHeader title="User" className="center" />
                    <Typography>Today: {dashboards.user_1}</Typography>
                    <Typography>Last 7 Days: {dashboards.user_7}</Typography>
                    <Typography>Last 30 Days: {dashboards.user_30}</Typography>
                    <Typography>All Time: {dashboards.user_all}</Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={3} >
                <Card   >
                  <CardContent>
                    <CardHeader title="Investment" className="center" />
                    <Typography>Today: {dashboards.investment_1}</Typography>
                    <Typography>Last 7 Days: {dashboards.investment_7}</Typography>
                    <Typography>Last 30 Days: {dashboards.investment_30}</Typography>
                    <Typography>All Time: {dashboards.investment_all}</Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={3} >
                <Card   >
                  <CardContent>
                    <CardHeader title="Last 3 Days" className="center" />
                    <Typography>Get Funded: {dashboards.get_funded_3}</Typography>
                    <Typography>Contact Us: {dashboards.contact_us_3}</Typography>
                    <Typography>Enquiry: {dashboards.enquiry_3}</Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={3} >
                <Card   >
                  <CardContent>
                    <CardHeader title="Amount invested" className="center" />
                    <Typography>Total: {hp.format_money(dashboards.amount_invested_total)}</Typography>
                    <Typography>Paid: {hp.format_money(dashboards.amount_invested_paid)}</Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={3} >
                <Card  >
                  <CardContent>
                    <CardHeader title="SME Campaigns" className="center" />
                    <Typography>Total: {dashboards.sme_total}</Typography>
                    <Typography>On Going: {dashboards.sme_ongoing}</Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={3} >
                <Card  >
                  <CardContent>
                    <CardHeader title="Donation Campaigns" className="center" />
                    <Typography>Total: {dashboards.donation_total}</Typography>
                    <Typography>On Going: {dashboards.donation_ongoing}</Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={3} >
                <Card  >
                  <CardContent>
                    <CardHeader title="KB Wallet" className="center" />
                    <Typography>Total: {hp.format_money(dashboards.kb_wallet)}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3} >
                <Card  >
                  <CardContent>
                    <CardHeader title="Register From" className="center" />
                    <Typography>Website: {dashboards.register_from.website}</Typography>
                    <Typography>Android: {dashboards.register_from.android}</Typography>
                    <Typography>IOS: {dashboards.register_from.ios}</Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={3} >
                <Card  >
                  <CardContent>
                    <CardHeader title="Investment From" className="center" />
                    <Typography>Website: {dashboards.investment_from.website}</Typography>
                    <Typography>Android: {dashboards.investment_from.android}</Typography>
                    <Typography>IOS: {dashboards.investment_from.ios}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Fragment>
          :
          <div className="loading"><CircularProgress /></div>
        }

      </Container>
    )
  }
}

export default Page
