import React, { Component } from 'react'

import { Container } from '@material-ui/core'
import api from 'services/api'
import MaterialTable from "material-table";

class Page extends Component {

   state = {

   }

   componentDidMount() {
      window.scrollTo(0, 0)
   }

   render() {

      return (
         <Container id="enquiry" maxWidth="xl" >
            <MaterialTable
               columns={[
                  { title: 'No', field: 'id', render: rowData => rowData.tableData.id + 1 },
                  { title: 'Submited on', field: 'created_at', type: 'datetime' },
                  { title: "Name", field: "user.firstname" },
                  { title: "Email", field: "user.email" },
                  { title: 'Campaign Name', field: 'campaign.name' },
                  { title: 'Message', field: 'message', width: '40%', cellStyle: { whiteSpace: 'pre-line' }, },
               ]}
               title="Enquiry"
               options={{
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100, 200],
                  actionsColumnIndex: -1,
                  filtering: true
               }}
               data={query =>
                  new Promise(async (resolve) => {
                     let field = query.orderBy ? query.orderBy.field : ''
                     let filters = []
                     if (query.filters.length > 0) {
                        query.filters.map(data => {
                           let column = {}
                           let value = []
                           column.field = data.column.field
                           value = data.value
                           filters.push({ 'column': column, 'value': value })
                           return data;
                        })
                        filters = JSON.stringify(filters)
                     }
                     const result = await api.get(`enquiry?limit=${query.pageSize}&offset=${query.page * query.pageSize}&search=${query.search}
                     &orderDirection=${query.orderDirection}&field=${field}&filters=${filters}`)
                     const { data } = result
                     resolve({
                        data: data.data,
                        page: query.page,
                        totalCount: data.total,
                     })
                  })
               }
               editable={{
                  onRowDelete: oldData =>
                     new Promise(async (resolve) => {
                        await api.delete(`enquiry/${oldData.id}`)
                        resolve()
                     })
               }}


            />
         </Container>
      )
   }
}

export default Page
