import React from "react";
import "./App.css";
import "react-quill/dist/quill.snow.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
  Redirect,
} from "react-router-dom";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import Wrapper from "components/Wrapper";
import Dashboard from "pages/Dashboard";
import Login from "pages/Login";
import CreateNewPassword from "pages/CreateNewPassword";
import Users from "pages/User/Users";
import User from "pages/User/User";
import FAQs from "pages/FAQ/FAQs";
import FAQ from "pages/FAQ/FAQ";
import Blogs from "pages/Blog/Blogs";
import Blog from "pages/Blog/Blog";
import GetFunded from "pages/GetFunded";
import Withdraw from "pages/Withdraw";
import Enquiry from "pages/Enquiry";
import Campaigns from "pages/Campaign/Campaigns";
import Campaign from "pages/Campaign/Campaign";
import CampaignInvestment from "pages/Campaign/CampaignInvestment";
import CampaignMasterPayout from "pages/Campaign/CampaignMasterPayout";
import CampaignMasterPayoutHistory from "pages/Campaign/CampaignMasterPayoutHistory";
import CampaignMasterPayoutSummary from "pages/Campaign/CampaignMasterPayoutSummary";
import CampaignMasterPayoutReport from "pages/Campaign/CampaignMasterPayoutReport";
import CampaignUpdates from "pages/Campaign/CampaignUpdates";
import CampaignUpdate from "pages/Campaign/CampaignUpdate";
import CampaignContract from "pages/Campaign/CampaignContract";
import Investments from "pages/Investment/Investments";
import Investment from "pages/Investment/Investment";
import Referal from "pages/Referal";
import Contacts from "pages/Contact/Contacts";
import Contact from "pages/Contact/Contact";
import Admins from "pages/Admin/Admins";
import Admin from "pages/Admin/Admin";
import Banks from "pages/Banks";
import Banners from "pages/Banners/Banners";
import ContractTemplate from "pages/ContractTemplate";
import ContractTemplateDetail from "pages/ContractTemplate/show";
import FaqsAdd from "pages/FAQ/FAQsAdd";
import Partners from "pages/Partners/Partners";
import PartnerAdd from "pages/Partners/PartnerAdd";
import Partner from "pages/Partners/Partner";
import Affiliations from "pages/Affiliations/Affiliations";
import PageNotFound from "pages/PageNotFound";
import MekariCallback from "pages/MekariEsign/Callback";
import MobileNotifications from "pages/MobileNotif/MobileNotifications";
import CreateMessage from "pages/MobileNotif/CreateMessage";
import EditMessage from "pages/MobileNotif/EditMessage";

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location, history, match }) =>
        localStorage.getItem("token") ? (
          <Wrapper>
            <Component location={location} history={history} match={match} />
          </Wrapper>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const theme = createMuiTheme({
  typography: {
    fontFamily: ["Roboto"].join(","),
  },
  palette: {
    primary: {
      main: "#1eb2f6",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#335f8c",
      contrastText: "#ffffff",
    },
  },
});

function App() {
  return (
    <Router>
      <div>
        <ThemeProvider theme={theme}>
          <AnimationApp />
        </ThemeProvider>
      </div>
    </Router>
  );
}

function AnimationApp() {
  const location = useLocation();

  return (
    <GoogleReCaptchaProvider reCaptchaKey="6Lf2vN4UAAAAAI_q8fmljTPB_WlNe3PZlaZQozg7">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Switch location={location}>
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute exact path="/user" component={Users} />
          <PrivateRoute path="/user/:id" component={User} />
          <PrivateRoute exact path="/faq" component={FAQs} />
          <PrivateRoute exact path="/faq/:id" component={FAQ} />
          <PrivateRoute exact path="/blog" component={Blogs} />
          <PrivateRoute path="/blog/:id" component={Blog} />
          <PrivateRoute exact path="/get-funded" component={GetFunded} />
          <PrivateRoute exact path="/campaign" component={Campaigns} />
          <PrivateRoute
            exact
            path="/contract-template"
            component={ContractTemplate}
          />
          <PrivateRoute
            exact
            path="/contract-template/:id"
            component={ContractTemplateDetail}
          />
          <PrivateRoute path="/campaign/:id" component={Campaign} />
          <PrivateRoute
            path="/campaign-investment/:id"
            component={CampaignInvestment}
          />
          <PrivateRoute
            path="/campaign-master-payout-history/:id"
            component={CampaignMasterPayoutHistory}
          />
          <PrivateRoute
            path="/campaign-master-payout/:id"
            component={CampaignMasterPayout}
          />
          <PrivateRoute
            path="/campaign-updates/:id"
            component={CampaignUpdates}
          />
          <PrivateRoute
            path="/campaign-update/:id"
            component={CampaignUpdate}
          />
          <PrivateRoute
            path="/campaign-contract/:id"
            component={CampaignContract}
          />
          <PrivateRoute exact path="/investment" component={Investments} />
          <PrivateRoute path="/investment/:id" component={Investment} />
          <PrivateRoute path="/referal" component={Referal} />
          <PrivateRoute exact path="/withdraw" component={Withdraw} />
          <PrivateRoute exact path="/enquiry" component={Enquiry} />
          <PrivateRoute exact path="/contact" component={Contacts} />
          <PrivateRoute path="/contact/:id" component={Contact} />
          <PrivateRoute exact path="/faqs-add" component={FaqsAdd} />
          <PrivateRoute exact path="/partners-add" component={PartnerAdd} />
          <PrivateRoute
            exact
            path="/campaign-master-payout-summary/:id"
            component={CampaignMasterPayoutSummary}
          />
          <PrivateRoute
            exact
            path="/campaign-master-payout-report/:id"
            component={CampaignMasterPayoutReport}
          />
          <PrivateRoute exact path="/admin" component={Admins} />
          <PrivateRoute path="/admin/:id" component={Admin} />

          <PrivateRoute exact path="/banks" component={Banks} />

          <PrivateRoute exact path="/banners" component={Banners} />

          <Route exact path="/login" component={Login} />
          <Route
            exact
            path="/create-new-password"
            component={CreateNewPassword}
          />
          <Route exact path="/esign/callback" component={MekariCallback} />
          <PrivateRoute exact path="/partner" component={Partners} />
          <PrivateRoute exact path="/partner/:id" component={Partner} />
          <PrivateRoute exact path="/affiliation" component={Affiliations} />

          <PrivateRoute exact path="/mobile-notif" component={MobileNotifications} />
          <PrivateRoute exact path="/mobile-notif/create" component={CreateMessage} />
          <PrivateRoute exact path="/mobile-notif/edit/:id" component={EditMessage} />

          {/* 👇️ only match this when no other routes match */}
          <Route exact path="*" component={PageNotFound} />
        </Switch>
      </MuiPickersUtilsProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
