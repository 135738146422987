import React, {Component} from 'react'

import {Container, Chip, Typography, Button} from '@material-ui/core'
import api from 'services/api'
import MaterialTable from "material-table";

class Blogs extends Component {
   
   componentDidMount() {
      window.scrollTo(0,0)
   }

   render() {

      return (
         <Container id="faqs" maxWidth="xl" >
            <MaterialTable
               columns={[
                  { title: 'No', render: rowData => rowData ? rowData.tableData.id + 1 : '' },
                  { title: "Title", field: "title", width: '10%', },
                  { title: "Visitor", field: "viewer", width: '10%', },
                  { title: "Image", field: "image", editComponent: props => ( <Typography>Update later</Typography> ) , render: rowData => <img src={rowData.image} height="100px" alt="img" />,  },
                  { title: "Visibility", field: "is_enable", editComponent: props => ( <Typography>Disabled</Typography> ),
                  render: rowData => rowData.is_enable ? <Button variant="contained" color="primary"  >Enabled</Button> : <Button variant="contained" disable={true} >Disabled</Button> },
                  { title: "Tags", field: "tags", width: '10%' , editComponent: props => ( <Typography>Update later</Typography> ) , render: rowData => rowData.tags && rowData.tags.map( (tag, i) => ( <Chip key={i} label={tag} /> )) },
                  { title: 'Last Update', field: 'updated_at', editable: 'never' },
                  { title: 'Created', field: 'created_at', editable: 'never'  },
               ]}
               title="Blogs"
               options = {{
                  pageSize: 10,
                  pageSizeOptions: [10,20,50,100,200],
                  actionsColumnIndex: -1,
               }}
               data = {query =>
                  new Promise( async(resolve) => {
                     const result = await api.get(`blog?limit=${query.pageSize}&offset=${query.page * query.pageSize}&search=${query.search}`)
                     const {data} = result
                     resolve({
                        data: data.data,
                        page: query.page,
                        totalCount: data.total,
                     })
                  })
               }
               onRowClick={(e, rowData)=> this.props.history.push(`/blog/${rowData.id}`) }
               editable={{
                  onRowAdd: newData =>
                     new Promise (async(resolve) => {
                        await api.post(`blog`, newData)
                        resolve()
                     }),
                  onRowDelete: oldData =>
                  new Promise (async(resolve) => {
                     await api.delete(`blog/${oldData.id}`)
                     resolve()
                  })
               }}

            />
         </Container>
      )
   }
}

export default Blogs
