import React, { Component } from "react";

import {
  Container,
} from "@material-ui/core";
import api from "services/api";
import MaterialTable from "material-table";

class Page extends Component {
  state = {};

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <Container id="faqs" maxWidth="xl">
        <MaterialTable
          columns={[
            {
              title: "No",
              cellStyle: {width: '5%'},
              render: (rowData) => (rowData ? rowData.tableData.id + 1 : ""),
            },
            {
              title: "Investor",
              field: "user.full_name",
            },
            {
              title: "Vendor",
              field: "vendor.name",
            },
            {
              title: "Description",
              render: (data) => {
                if(data.investment_id) {
                  return `Invest to ${data.investment.campaign.name}`
                }
                return 'Registration';
              },
            },
          ]}
          title="Affiliations"
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 20, 50, 100, 200],
            actionsColumnIndex: -1,
            filtering: true,
          }}
          data={(query) =>
            new Promise(async (resolve) => {
              let field = query.orderBy ? query.orderBy.field : "";
              let filters = [];
              if (query.filters.length > 0) {
                let column = null;
                let value = null;
                query.filters.map((data) => {
                  column = data.column.field;
                  value = data.value;
                  filters.push({ column: column, value: value });
                  return data;
                });
                filters = JSON.stringify(filters);
              }
              const result = await api.get(`affiliation?limit=${
                query.pageSize
              }&offset=${query.page * query.pageSize}&search=${query.search}
                     &orderDirection=${
                       query.orderDirection
                     }&field=${field}&filters=${filters}`);
              const { data } = result;
              resolve({
                data: data.data,
                page: query.page,
                totalCount: data.total,
              });
            })
          }
        />
      </Container>
    );
  }
}

export default Page;
