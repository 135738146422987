import React, { Component } from "react";

import {
  Container,
  CircularProgress,
  Paper,
  Snackbar,
  NativeSelect,
  Tabs,
  Tab,
  InputAdornment,
  MenuItem,
  FormControl,
  Typography,
  Button,
  InputLabel,
  TextField,
  Grid,
  Select,
} from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";
import api from "services/api";
import hp from "services/hp";
import MaterialTable from "material-table";
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

import { format } from "date-fns";
import {
  DateTimePicker,
} from "@material-ui/pickers";

import moment from "moment-timezone";
import { Alert } from "@material-ui/lab";

const TINY_APIKEY = process.env.REACT_APP_TINY_APIKEY

class Page extends Component {
  constructor(props) {
    super(props);
    this.renderFlag = true;
    this.state = {
      id: props.match.params.id,
      campaign: null,
      openSuccessSnack: false,
      openErrorsSnack: false,
      errorUpload: null,
      countries: [],
      all_campaigns: null,
      tab: 0,
      funded_investment: 0,
      file_type: null,
      snackMessage: null,
      buttonUpdateLoading: false,
      buttonNotifLoading: false
    };
  }


  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (!this.renderFlag) {
       this.renderFlag = true;
       return false;
     } else {
        return true;
     }
 }

  onStart = async () => {
    const { id } = this.state;
    const res = await api.get(`campaign/${id}`);
    const res_funded_investment = await api.get(
      `campaign_funded_investment/${id}`
    );
    const campaign = res.data;
    const funded_investment = res_funded_investment.data;
    const countries = await hp.get_countries();
    const all_campaigns_res = await api.get("misc/campaign");
    const all_campaigns = all_campaigns_res.data;
    this.setState({ campaign, countries, all_campaigns, funded_investment });
  };

  onUpdate = async (e) => {
    e.preventDefault();
    const { campaign, id } = this.state;

    const res = await api.post(`campaign/${id}`, campaign);
    const { data } = res;
    if (data.error) {
      alert(data.message);
      return;
    }
    window.scrollTo(0, 0);
    this.setState({ openSuccessSnack: true, snackMessage: "Successfully update!" });
    this.onStart();
  };

  onChange = (e, f) => {
    let { campaign } = this.state;
    campaign[f] = e.target.value;
    this.setState({ campaign });
  };
  onAttach = async (e, f) => {
    let { campaign } = this.state;
    let validated = false
    // validation for cover-image
    if (f === "cover_image") {
      const fileSize = e.target.files[0].size / 1024
      validated = fileSize > 1000
    }

    if (validated === false) {
      let formData = new FormData();
      formData.append("dir", "campaign");
      formData.append("image", e.target.files[0]);
      try {
        let image_url_res = await api.post("upload-image", formData);
        this.setState({file_type: image_url_res.data.file_type});
        campaign[f] = image_url_res.data.file_name;
        this.setState({ campaign });
      } catch (error) {
        alert("Failed to upload image");
      }
    } else {
      this.setState({errorUpload: "Image size must be 1 MB or smaller", openErrorSnack: true})
      e.target.value = null
    }

  };
  onAttachSub = async (e, f, props) => {
    let formData = new FormData();
    formData.append("dir", "campaign/" + f);
    formData.append("image", e.target.files[0]);
    let image_url_res = await api.post("upload-image", formData);
    this.renderFlag = false;
    this.setState({file_type: image_url_res.data.file_type});
    props.onChange(image_url_res.data.file_name);
  };
  handleEditorChange = (content, editor) => {
    let { campaign } = this.state;
    campaign.description = content;
    this.setState({ campaign });
  };

  onChangeDate = (date, f) => {
    let { campaign } = this.state;
    campaign[f] = format(new Date(date), "yyyy-MM-dd kk:mm:ss");
    this.setState({ campaign });
  };

  onChangeTab = (e, newTab) => {
    this.setState({ tab: newTab });
  };

  onSendNotif = async (e) => {
    e.preventDefault();
    const { id } = this.state;
    this.setState({ buttonNotifLoading: true });

    const params = {
      page: 'campaign'
    }
    
    const res = await api.post(`campaign/${id}/send-notif`, params);
    const { data } = res;
    
    if (data.error) {
      alert(data.message);
      return;
    }

    window.scrollTo(0, 0);
    this.setState({ openSuccessSnack: true, snackMessage: data.message });
    
    setTimeout(() => {
      this.setState({ openSuccessSnack: true, snackMessage: data.message, buttonNotifLoading: false });
    }, 1000);
    this.onStart();
  };

  render() {
    const {
      id,
      campaign,
      countries,
      all_campaigns,
      tab,
      funded_investment,
      snackMessage,
      buttonNotifLoading,
      errorUpload
    } = this.state;

    return (
      <Container id="campaign" maxWidth="xl">
        <Paper square className="spaceBottom">
          <Tabs value={tab} onChange={this.onChangeTab}>
            <Tab label="Campaign" />
            <Tab label="Gallery" />
            <Tab label="PDFs" />
          </Tabs>
        </Paper>
        {campaign ? (
          <div>
            {tab === 0 && (
              <form onSubmit={this.onUpdate}>
                <Paper className="padding spaceBottom" square>
                  <Grid container spacing={4} className="spaceBig">
                    <Grid item xs={6}>
                      {[
                        ["Campaign Name", "name"],
                        ["Campaign Acronim", "acronim"],
                        ["Item to be purchased", "classification"],
                        ["Industry", "industry"],
                        ["Total Funding Amount", "total_invest_amount"],
                        ["Minimum Investment Amount", "minimum_invest_amount"],
                        ["Tenor", "tenor"],
                        ["Project Return", "return"],
                        ["Campaign Password", "password"],
                      ].map((item, i) => (
                        <TextField
                          key={i}
                          label={item[0]}
                          value={campaign[item[1]]}
                          onChange={(e) => this.onChange(e, item[1])}
                          fullWidth
                          margin="normal"
                        />
                      ))}
                      <TextField
                        label="URL Slug"
                        value={campaign.slug}
                        onChange={(e) => this.onChange(e, "slug")}
                        fullWidth
                        margin="normal"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              https://kapitalboost.com/campaign/
                            </InputAdornment>
                          ),
                        }}
                      />

                      <FormControl fullWidth margin="normal">
                        <InputLabel>Status</InputLabel>
                        <Select
                          value={campaign.is_enable}
                          onChange={(e) => this.onChange(e, "is_enable")}
                        >
                          <MenuItem value={false}>Offline</MenuItem>
                          <MenuItem value={true}>Online</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl fullWidth margin="normal">
                        <InputLabel>Email Requirements</InputLabel>
                        <Select
                          value={campaign.requirement_reminder}
                          onChange={(e) => this.onChange(e, "requirement_reminder")}
                        >
                          <MenuItem value={false}>Disable</MenuItem>
                          <MenuItem value={true}>Active</MenuItem>
                        </Select>
                      </FormControl>

                      <FormControl fullWidth margin="normal">
                        <InputLabel>Email Payout Reminder</InputLabel>
                        <Select
                          value={campaign.send_payout_reminder}
                          onChange={(e) => this.onChange(e, "send_payout_reminder")}
                        >
                          <MenuItem value={false}>Disable</MenuItem>
                          <MenuItem value={true}>Active</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      {[
                        ["Company Name", "company_name"],
                        ["Company Director", "company_director"],
                        ["Company Director's email", "company_director_email"],
                      ].map((item, i) => (
                        <TextField
                          key={i}
                          label={item[0]}
                          value={campaign[item[1]]}
                          onChange={(e) => this.onChange(e, item[1])}
                          fullWidth
                          margin="normal"
                        />
                      ))}
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Project Type</InputLabel>
                        <Select
                          value={campaign.type}
                          onChange={(e) => this.onChange(e, "type")}
                        >
                          <MenuItem value="sme">SME Crowdfunding</MenuItem>
                          <MenuItem value="donation">Donation</MenuItem>
                          <MenuItem value="private">
                            Private Crowdfunding
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>SME Sub Type</InputLabel>
                        <Select
                          value={campaign.subtype}
                          onChange={(e) => this.onChange(e, "subtype")}
                        >
                          <MenuItem value="ASSET PURCHASE FINANCING">
                            ASSET PURCHASE FINANCING
                          </MenuItem>
                          <MenuItem value="INVOICE  FINANCING">
                            INVOICE FINANCING
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Risk</InputLabel>
                        <Select
                          value={campaign.risk}
                          onChange={(e) => this.onChange(e, "risk")}
                        >
                          <MenuItem value="N/A">N/A</MenuItem>
                          <MenuItem value="A">A</MenuItem>
                          <MenuItem value="A-">A-</MenuItem>
                          <MenuItem value="B+">B+</MenuItem>
                          <MenuItem value="B">B</MenuItem>
                          <MenuItem value="B-">B-</MenuItem>
                          <MenuItem value="C+">C+</MenuItem>
                          <MenuItem value="C-">C-</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Country</InputLabel>
                        <NativeSelect
                          value={campaign.country ? campaign.country : ""}
                          onChange={(e) => this.onChange(e, "country")}
                        >
                          <option value=""></option>
                          {countries &&
                            countries.map((country, i) => (
                              <option key={i} value={country.name}>
                                {country.name}
                              </option>
                            ))}
                        </NativeSelect>
                      </FormControl>

                      <TextField
                        label="Funded"
                        value={funded_investment.investments_count}
                        fullWidth
                        margin="normal"
                        defaultValue="0"
                        InputProps={{
                          readOnly: true,
                        }}
                      />

                      <DateTimePicker
                        variant="inline"
                        format="dd MMMM yyyy kk:mm:ss"
                        // timeZone="GMT+8"
                        ampm={false}
                        margin="normal"
                        label="Release Date Time"
                        value={
                          campaign.release_datetime
                            ? new moment.tz(
                                campaign.release_datetime,
                                "Asia/Singapore"
                              ).format()
                            : // ? new Date(campaign.release_datetime)
                              null
                        }
                        onChange={(e) =>
                          this.onChangeDate(e, "release_datetime")
                        }
                        fullWidth
                      />

                      <DateTimePicker
                        variant="inline"
                        format="dd MMMM yyyy kk:mm:ss"
                        ampm={false}
                        margin="normal"
                        label="Closing Date Time"
                        value={
                          campaign.expiry_datetime
                            ? new moment.tz(
                                campaign.expiry_datetime,
                                "Asia/Singapore"
                              ).format()
                            : // ? new Date(campaign.expiry_datetime)
                              null
                        }
                        onChange={(e) =>
                          this.onChangeDate(e, "expiry_datetime")
                        }
                        fullWidth
                      />

                      <TextField
                        variant="outlined"
                        multiline
                        rows="4"
                        label="Snippet"
                        margin="normal"
                        value={campaign.snippet}
                        onChange={(e) => this.onChange(e, "snippet")}
                        fullWidth
                      />

                      <Grid container className="space" spacing={3}>
                        <Grid item xs={6}>
                          <Typography>Logo</Typography>
                          {campaign.logo && (
                            <div>
                              <img src={campaign.logo} width="100%" alt="img"/>
                            </div>
                          )}
                          <input
                            accept="image/*"
                            id="campaign-logo"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => this.onAttach(e, "logo")}
                          />
                          <label htmlFor="campaign-logo">
                            <Button
                              variant="contained"
                              color="secondary"
                              component="span"
                              className="spaceBig"
                            >
                              Browse
                            </Button>
                          </label>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Background</Typography>
                          {campaign.cover_image && (
                            <div>
                              <img src={campaign.cover_image} width="100%" alt="img"/>
                            </div>
                          )}
                          <input
                            accept="image/*"
                            id="campaign-cover-image"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) => this.onAttach(e, "cover_image")}
                          />
                          <br />
                          <label htmlFor="campaign-cover-image">
                            <Button
                              variant="contained"
                              color="secondary"
                              component="span"
                            >
                              Browse
                            </Button>
                            <small style={{display: 'block', fontStyle: 'italic', marginTop: '5px'}}>max file size is 1MB</small>
                          </label>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Editor
                    apiKey={TINY_APIKEY}
                    value={campaign.description}
                    id="campaign-description"
                    init={{
                      height: 500,
                      menubar:
                        "file edit view insert format tools table help custom",
                      menu: {
                        custom: {
                          title: "Dynamic Data",
                          items:
                            "usermenu campaignmenu masterpayoutmenu contractmenu",
                        },
                      },
                      plugins: [
                        "advlist autolink lists link image charmap anchor pagebreak",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect fontsizeselect | bold italic forecolor backcolor | image searchreplace | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | pagebreak | fullscreen",
                      automatic_uploads: false,
                      images_upload_url:
                        "https://api.kapitalboost.com/api/admin/upload-image",
                      content_style:
                        "code { color: white; background-color: #2f628a !important; }; ",
                      fontsize_formats:
                        "8pt 10pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 36pt",
                    }}
                    onEditorChange={this.handleEditorChange}
                  />
                </Paper>

                <Paper className="spaceBottom padding">
                  <Typography>Related Campaigns</Typography>

                  <Grid container spacing={4}>
                    <Grid item xs={4}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Related Campaign 1</InputLabel>
                        <Select
                          value={campaign.related_campaign_id_1}
                          onChange={(e) =>
                            this.onChange(e, "related_campaign_id_1")
                          }
                        >
                          {all_campaigns.map((all_campaign, i) => (
                            <MenuItem value={all_campaign.id} key={i}>
                              {all_campaign.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Related Campaign 2</InputLabel>
                        <Select
                          value={campaign.related_campaign_id_2}
                          onChange={(e) =>
                            this.onChange(e, "related_campaign_id_2")
                          }
                        >
                          {all_campaigns.map((all_campaign, i) => (
                            <MenuItem value={all_campaign.id} key={i}>
                              {all_campaign.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl fullWidth margin="normal">
                        <InputLabel>Related Campaign 3</InputLabel>
                        <Select
                          value={campaign.related_campaign_id_3}
                          onChange={(e) =>
                            this.onChange(e, "related_campaign_id_3")
                          }
                        >
                          {all_campaigns.map((all_campaign, i) => (
                            <MenuItem value={all_campaign.id} key={i}>
                              {all_campaign.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Paper>

                <Paper className="spaceBig padding">
                  <div align="right">
                    <Button
                      variant="contained"
                      type="submit"
                      className="spaceBig"
                      color="primary"
                      style={{marginRight: '1%', width: '70%'}}
                    >
                      Update
                    </Button>
                    <Button
                      variant="outlined"
                      type="button"
                      className="spaceBig"
                      style={{marginRight: '1%', width: '28%'}}
                      onClick={this.onSendNotif}
                      disabled={buttonNotifLoading}
                    >
                      {buttonNotifLoading ? (
                        <>
                          Loading...
                        </>
                      ) : (
                        <>
                          <NotificationsActiveIcon style={{fontSize: '18px', marginRight: '5px'}} />
                          Send Notif
                        </>
                      )}
                    </Button>
                  </div>
                </Paper>
              </form>
            )}

            {tab === 1 && (
              <div className="spaceBottomX">
                <MaterialTable
                  columns={[
                    {
                      title: "No",
                      render: (rowData) =>
                        rowData ? rowData.tableData.id + 1 : "",
                    },
                    { title: "Name", field: "name" },
                    { 
                      title: "Type", 
                      field: "type", 
                      editable: false,
                      render: rowData => <span className={(!rowData.type || rowData.type === 'image') ? 'success-alert' : 'error-alert'}>{!rowData.type ? 'image' : rowData.type}</span>
                    },
                    {
                      title: "Image",
                      field: "link",
                      width: "40%",
                      render: (rowData) => (
                        <div>
                          {
                            (rowData.type && rowData.type.toLowerCase() === 'image' || rowData.type == null) &&
                            <a href={rowData.link} target="_blank" rel="noopener noreferrer">
                              <img src={rowData.link} height="150px" alt="img"/>
                            </a>
                          }
                          {
                            rowData.type && rowData.type.toLowerCase() === 'video' &&
                            <video controls>
                              <source src={rowData.link}/>
                            </video>
                          }
                        </div>
                      ),
                      editComponent: (props) => (
                        <div>
                          {props.rowData.link && (
                            <div>
                              <img src={props.rowData.link} height="150px" alt="img" loading="lazy"/>
                            </div>
                          )}
                          <input
                            accept="image/*,video/*"
                            id={`image-file`}
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e) =>
                              this.onAttachSub(e, "image", props)
                            }
                          />
                          <label htmlFor="image-file">
                            <Button
                              variant="contained"
                              color="secondary"
                              component="span"
                              className="spaceBig"
                            >
                              Browse
                            </Button>
                          </label>
                        </div>
                      ),
                    },
                  ]}
                  title={`Campaign Gallery`}
                  options={{
                    pageSize: 10,
                    pageSizeOptions: [10, 20, 100, 200],
                    actionsColumnIndex: -1,
                    search: false,
                  }}
                  data={(query) =>
                    new Promise(async (resolve) => {
                      const result = await api.get(
                        `campaign-image?campaign_id=${id}&limit=${
                          query.pageSize
                        }&offset=${query.page * query.pageSize}&search=${
                          query.search
                        }`
                      );
                      const { data } = result;
                      resolve({
                        data: data.data,
                        page: query.page,
                        totalCount: data.total,
                      });
                    })
                  }
                  editable={{
                    onRowAdd: (newData) =>
                      new Promise(async (resolve) => {
                        newData.campaign_id = id;
                        newData.type = this.state.file_type;
                        await api.post("campaign-image", newData);
                        this.setState({file_type: null});
                        resolve();
                      }),
                    onRowUpdate: (newData) =>
                      new Promise(async (resolve) => {
                        delete newData.created_at;
                        delete newData.updated_at;
                        newData.type = this.state.file_type;
                        this.renderFlag = true;
                        await api.post(`campaign-image/${newData.id}`, newData);
                        resolve();
                      }),
                    onRowDelete: (oldData) =>
                      new Promise(async (resolve) => {
                        await api.delete(`campaign-image/${oldData.id}`);
                        resolve();
                      }),
                  }}
                />
              </div>
            )}

            {tab === 2 && (
              <MaterialTable
                columns={[
                  {
                    title: "No",
                    render: (rowData) =>
                      rowData ? rowData.tableData.id + 1 : "",
                  },
                  { title: "Name", field: "name" },
                  {
                    title: "Pdf",
                    field: "link",
                    width: "40%",
                    render: (rowData) => (
                      <a href={rowData.link} target="_blank" rel="noopener noreferrer">
                        View PDF
                      </a>
                    ),
                    editComponent: (props) => (
                      <div>
                        {props.rowData.link && (
                          <Typography>Uploaded</Typography>
                        )}
                        <input
                          accept="image/*|application/pdf"
                          id={`pdf-file`}
                          type="file"
                          style={{ display: "none" }}
                          onChange={(e) => this.onAttachSub(e, "pdf", props)}
                        />
                        <label htmlFor="pdf-file">
                          <Button
                            variant="contained"
                            color="secondary"
                            component="span"
                            className="spaceBig"
                          >
                            Browse
                          </Button>
                        </label>
                      </div>
                    ),
                  },
                ]}
                title={`Campaign PDFs`}
                options={{
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 100, 200],
                  actionsColumnIndex: -1,
                  search: false,
                }}
                data={(query) =>
                  new Promise(async (resolve) => {
                    const result = await api.get(
                      `campaign-pdf?campaign_id=${id}&limit=${
                        query.pageSize
                      }&offset=${query.page * query.pageSize}&search=${
                        query.search
                      }`
                    );
                    const { data } = result;
                    resolve({
                      data: data.data,
                      page: query.page,
                      totalCount: data.total,
                    });
                  })
                }
                editable={{
                  onRowAdd: (newData) =>
                    new Promise(async (resolve) => {
                      newData.campaign_id = id;
                      await api.post("campaign-pdf", newData);
                      resolve();
                    }),
                  onRowUpdate: (newData) =>
                    new Promise(async (resolve) => {
                      delete newData.created_at;
                      delete newData.updated_at;
                      await api.post(`campaign-pdf/${newData.id}`, newData);
                      resolve();
                    }),
                  onRowDelete: (oldData) =>
                    new Promise(async (resolve) => {
                      await api.delete(`campaign-pdf/${oldData.id}`);
                      resolve();
                    }),
                }}
              />
            )}
          </div>
        ) : (
          <div className="loading">
            <CircularProgress />
          </div>
        )}
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.openSuccessSnack}
          autoHideDuration={4000}
          onClose={() => this.setState({ openSuccessSnack: false })}
        >
          <div className="success-alert">
            <Typography>{snackMessage}</Typography>
          </div>
        </Snackbar>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.openErrorSnack}
          autoHideDuration={2000}
          onClose={() => this.setState({ openErrorSnack: false })}
        >
          <Alert severity="error">
            <Typography>{errorUpload}</Typography>
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export default Page;
