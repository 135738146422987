import React, { Component } from "react";

import {
  Container,
  TextField,
} from "@material-ui/core";
import api from "services/api";
import MaterialTable from "material-table";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      campaign: "", //props.location.state.campaign
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
  }

  onStart = async () => {
    const { id } = this.state;
    let campaign_res = await api.get(`campaign/${id}`);
    let campaign = campaign_res.data;
    console.log(campaign);
    this.setState({ campaign });
  };

  onAttach = (e) => {};
  onDeleteImage = (i) => {};

  render() {
    const { id, campaign } = this.state;
    return (
      <Container maxWidth="xl">
        <MaterialTable
          columns={[
            { title: "No", field: "id", editable: "never" },
            { title: "Title", field: "title" },
            {
              title: "Content",
              field: "content",
              width: "60%",
              cellStyle: { whiteSpace: "pre-line" },
              editComponent: (props) => (
                <TextField
                  type="text"
                  value={props.value}
                  onChange={(e) => props.onChange(e.target.value)}
                  multiline
                  variant="outlined"
                  style={{ width: "100%" }}
                />
              ),
            },
            {
              title: "Files",
              field: "images",
              render: (rowData) =>
                rowData && rowData.images
                  ? rowData.images.map((image, i) => (
                      <a href={image} key={i} target="_blank" rel="noopener noreferrer">
                        Open
                      </a>
                    ))
                  : "",
              editable: "never",
            },
            { title: "Last Update", field: "updated_at", editable: "never" },
            { title: "Created", field: "created_at", editable: "never" },
          ]}
          title={`${campaign.name}'s Campaign Update`}
          options={{
            pageSize: 20,
            pageSizeOptions: [20, 100, 200],
            actionsColumnIndex: -1,
            search: false,
          }}
          data={(query) =>
            new Promise(async (resolve) => {
              const result = await api.get(
                `campaign-update?campaign_id=${id}&limit=${
                  query.pageSize
                }&offset=${query.page * query.pageSize}&search=${query.search}`
              );
              const { data } = result;
              resolve({
                data: data.data,
                page: query.page,
                totalCount: data.total,
              });
            })
          }
          editable={{
            onRowAdd: (newData) =>
              new Promise(async (resolve) => {
                newData.campaign_id = id;
                await api.post("campaign-update", newData);
                resolve();
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve) => {
                await api.delete(`campaign-update/${oldData.id}`);
                resolve();
              }),
          }}
          actions={[
            {
              icon: "more_horiz",
              tooltip: "Detail",
              onClick: (e, rowData) => {
                window.open(`/campaign-update/${rowData.id}`);
                // this.props.history.push(`/campaign-update/${rowData.id}`, {
                //   campaign,
                // });
              },
            },
          ]}
        />
      </Container>
    );
  }
}

export default Page;
