import React, { Component } from "react";
import { useHistory } from "react-router-dom";

import { Container, Grid, Button, Typography } from "@material-ui/core";
import { History } from "@material-ui/icons";
import api from "services/api";
import hp from "services/hp";
import MaterialTable, { MTableToolbar } from "material-table";
import { format } from "date-fns";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      campaign: "", //props.location.state.campaign
      sum_percentage: 0,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
  }

  onStart = async () => {
    const { id } = this.state;
    let campaign_res = await api.get(`campaign/${id}`);
    let campaign_master_payout_res = await api.get(
      `campaign-master-payout?campaign_id=${id}`
    );
    console.log(campaign_master_payout_res);
    let campaign = campaign_res.data;
    let sum_percentage = campaign_master_payout_res.data;
    console.log(sum_percentage);
    this.setState({ campaign, sum_percentage });
  };

  render() {
    const { id, campaign, sum_percentage } = this.state;
    return (
      <Container id="investment-report" maxWidth="xl">
        <MaterialTable
          columns={[
            {
              title: "No",
              render: (rowData) => (rowData ? rowData.tableData.id + 1 : ""),
            },
            // { title: 'FX', field: 'fx', },
            { title: "Percentage", field: "percentage" },
            {
              title: "Project Return",
              field: "return",
              initialEditValue: campaign.return,
            },
            { title: "Date", field: "date", type: "date" },
            {
              title: "Status",
              field: "status",
              initialEditValue: "on-going",
              lookup: {
                "on-going": "On Going",
                paid: "Paid",
                delayed: "Delayed",
              },
            },
            {
              title: "Payout Amount",
              field: "percentage",
              type: "currency",
              render: (rowData) =>
                hp.format_money(
                  parseInt(rowData.current_amount) *
                    (1 + rowData.return * 0.01) *
                    (rowData.percentage * 0.01) *
                    rowData.fx
                ),
              editComponent: (props) => <div></div>,
            },
          ]}
          title={`${campaign.name}'s Master Payout`}
          options={{
            pageSize: 10,
            pageSizeOptions: [10, 100, 200],
            actionsColumnIndex: -1,
            search: false,
          }}
          data={(query) =>
            new Promise(async (resolve) => {
              const result = await api.get(
                `campaign-master-payout?campaign_id=${id}&limit=${
                  query.pageSize
                }&offset=${query.page * query.pageSize}&search=${query.search}`
              );
              const { data } = result;
              resolve({
                data: data.data,
                page: query.page,
                totalCount: data.total,
              });
            })
          }
          editable={{
            onRowAdd: (newData) =>
              new Promise(async (resolve) => {
                newData.date = format(new Date(newData.date), "yyyy-MM-dd");
                newData.campaign_id = id;
                await api.post("campaign-master-payout", newData);
                resolve();
              }),
            onRowUpdate: (newData) =>
              new Promise(async (resolve) => {
                newData.date = format(new Date(newData.date), "yyyy-MM-dd");
                delete newData.current_amount;
                delete newData.campaign;
                delete newData.created_at;
                delete newData.updated_at;
                await api.post(`campaign-master-payout/${newData.id}`, newData);
                resolve();
              }),
            onRowDelete: (oldData) =>
              new Promise(async (resolve) => {
                await api.delete(`campaign-master-payout/${oldData.id}`);
                resolve();
              }),
          }}
          actions={[
            {
              icon: "more_horiz",
              tooltip: "Summary",
              onClick: (e, rowData) => {
                window.open(
                  `/campaign-master-payout-summary/${rowData.id}`,
                  "_blank"
                );
              },
            },
          ]}
          components={{
            Toolbar: (props) => {
              const history = useHistory();
              const toHistory = () => {
                history.push(`/campaign-master-payout-history/${id}`, {
                  campaign,
                });
              };

              return (
                <div>
                  <MTableToolbar {...props} />
                  <Container id="investment-report" maxWidth="xl">
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <Button
                          variant="contained"
                          color="secondary"
                          startIcon={<History />}
                          onClick={toHistory}
                        >
                          History Update
                        </Button>
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              );
            },
          }}
        />
        <Grid container>
          {/* <Grid item xs={4}></Grid> */}
          {/* <Grid item xs={4}></Grid> */}
          <Grid item>
            <Typography variant="h6" className="bold spaceBottomX">
              Sum of Percentage = {sum_percentage.sum_percentage}%
            </Typography>
          </Grid>
        </Grid>
      </Container>
    );
  }
}

export default Page;
