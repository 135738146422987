import React, { Component } from "react";
import MaterialTable from "material-table";
import {
  Container,
  Snackbar,
  Typography,
  Button,
} from "@material-ui/core";
import api from "services/api";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: props.match.params.id,
      banner: [],
      openSuccessSnack: false,
      message: "",
      background_color: "",
      save_loading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.onStart();
  }

  onStart = async () => {
    const res = await api.get(`banner`);
    const banner = res.data.data;
    console.log(banner);
    this.setState({ banner });
  };

  onAttachBanner = async (e, f, props) => {
    let formData = new FormData();
    formData.append("dir", "banner/" + f);
    formData.append("image", e.target.files[0]);
    // this.setState({ save_loading: true });
    let image_url_res = await api.post("upload-image-banner", formData);
    // this.setState({ save_loading: false });
    console.log(image_url_res);
    if (image_url_res.data.error) {
      alert(image_url_res.data.message);
    }
    props.onChange(image_url_res.data);
  };

  render() {
    const {
      id,
      background_color,
      message,
    } = this.state;
    console.log(background_color);
    return (
      <Container maxWidth="xl">
        <div className="spaceBottomX">
          <MaterialTable
            columns={[
              {
                title: "No",
                field: "id",
                editable: "never",
              },
              {
                title: "Desktop Banner",
                field: "desktop_banner",
                render: (rowData) => (
                  <a href={rowData.desktop_banner} target="_blank" rel="noopener noreferrer">
                    <img src={rowData.desktop_banner} height="150px" alt="img"/>
                  </a>
                ),
                editComponent: (props) => (
                  <div>
                    {props.rowData.desktop_banner && (
                      <div>
                        <img
                          src={props.rowData.desktop_banner}
                          alt="img"
                        />
                      </div>
                    )}
                    <input
                      accept="image/*"
                      id={`image-file`}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        this.onAttachBanner(e, "image_banner_desktop", props)
                      }
                    />
                    <label htmlFor="image-file">
                      <Button
                        variant="contained"
                        color="secondary"
                        component="span"
                        className="spaceBig"
                      >
                        Browse
                      </Button>
                    </label>
                  </div>
                ),
              },
              {
                title: "Mobile Banner",
                field: "mobile_banner",
                render: (rowData) => (
                  <a href={rowData.mobile_banner} target="_blank" rel="noopener noreferrer">
                    <img src={rowData.mobile_banner} height="150px" alt="img"/>
                  </a>
                ),
                editComponent: (props) => (
                  <div>
                    {props.rowData.mobile_banner && (
                      <div>
                        <img src={props.rowData.mobile_banner} height="150px" alt="img"/>
                      </div>
                    )}
                    <input
                      accept="image/*"
                      id={`image-file-mobile-banner`}
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) =>
                        this.onAttachBanner(e, "image_banner_mobile", props)
                      }
                    />
                    <label htmlFor="image-file-mobile-banner">
                      <Button
                        variant="contained"
                        color="secondary"
                        component="span"
                        className="spaceBig"
                      >
                        Browse
                      </Button>
                    </label>
                  </div>
                ),
              },
              {
                title: "Background Color",
                field: "background_color",
                render: (rowData) => (
                  <p style={{ color: rowData.background_color }}>
                    {rowData.background_color}
                  </p>
                ),
                editComponent: (props) => (
                  <div>
                    {props.rowData.background_color ? (
                      <div>
                        <p>{props.rowData.background_color}</p>
                      </div>
                    ) : (
                      <div>
                        <p>Pick a color</p>
                      </div>
                    )}
                    <input
                      type="color"
                      value={props.value}
                      onChange={(e) => props.onChange(e.target.value)}
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                  </div>
                ),
              },
              {
                title: "Action Url",
                field: "action_url",
                render: (rowData) => (
                  <a href={rowData.action_url} target="_blank" rel="noopener noreferrer">
                    <p>{rowData.action_url}</p>
                  </a>
                ),
              },
              {
                title: "Created on",
                field: "created_at",
                editable: "never",
              },
            ]}
            title={`Banners`}
            options={{
              pageSize: 10,
              pageSizeOptions: [10, 20, 100, 200],
              actionsColumnIndex: -1,
              search: true,
            }}
            data={(query) =>
              new Promise(async (resolve) => {
                const result = await api.get(
                  `banner?limit=${query.pageSize}&offset=${
                    query.page * query.pageSize
                  }&search=${query.search}`
                );
                const { data } = result;
                resolve({
                  data: data.data,
                  page: query.page,
                  totalCount: data.total,
                });
              })
            }
            editable={{
              onRowAdd: (newData) =>
                new Promise(async (resolve) => {
                  newData.banner = id;
                  await api.post("banner", newData);
                  resolve();
                  this.setState({ openSuccessSnack: true, message: "added" });
                }),
              onRowUpdate: (newData) =>
                new Promise(async (resolve) => {
                  delete newData.created_at;
                  delete newData.updated_at;
                  await api.post(`banner/${newData.id}`, newData);
                  resolve();
                  this.setState({ openSuccessSnack: true });
                }),
              onRowDelete: (oldData) =>
                new Promise(async (resolve) => {
                  await api.delete(`banner/${oldData.id}`);
                  resolve();
                  this.setState({ openSuccessSnack: true, message: "deleted" });
                }),
            }}
          />
        </div>
        <Snackbar
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={this.state.openSuccessSnack}
          autoHideDuration={6000}
          onClose={() => this.setState({ openSuccessSnack: false })}
        >
          <div className="success-alert">
            <Typography>
              Successfully{" "}
              {message === "deleted"
                ? "deleted"
                : message === "added"
                ? "added"
                : "updated"}
            </Typography>
          </div>
        </Snackbar>
      </Container>
    );
  }
}

export default Page;
