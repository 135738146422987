import React, { Component } from 'react'

import { Container } from '@material-ui/core'
import api from 'services/api'
import MaterialTable from "material-table";

class Page extends Component {

   constructor(props) {
      super(props)
      this.state = {
         id: props.match.params.id,
      }
      this.tableRef = React.createRef();
   }

   componentDidMount() {
      window.scrollTo(0, 0)
   }
  

   render() {
      const { id } = this.state
      return (
         <Container id="Admin" maxWidth="xl" >
            <MaterialTable tableRef={this.tableRef}
               columns={[
                  { title: 'No', render: rowData => rowData ? rowData.tableData.id + 1 : '' },
                  { title: "Device", field: "device" },
                  { title: "Browser", field: "browser", },
                  { title: "Platform", field: 'platform' },
                  { title: 'Latest Update on', field: 'updated_at', editable: 'never' },
                  { title: 'First Login', field: 'created_at', editable: 'never' },
               ]}
               title="Login Devices"
               options={{
                  pageSize: 20,
                  pageSizeOptions: [10, 20, 50, 100, 200],
                  actionsColumnIndex: -1,
               }}
               data={query =>
                  new Promise(async (resolve) => {
                     try {
                        const result = await api.get(`admin/${id}?limit=${query.pageSize}&offset=${query.page * query.pageSize}&search=${query.search}`)
                        const { data } = result
                        resolve({
                           data: data.data,
                           page: query.page,
                           totalCount: data.total,
                        })
                     } catch (e) { }
                  })
               }

               editable={{
                  onRowDelete: oldData =>
                     new Promise(async (resolve) => {
                        await api.delete(`admin/${oldData.id}`)
                        resolve()
                     })
               }}
               actions={[
                  {
                     icon: 'refresh',
                     tooltip: 'Refresh Data',
                     isFreeAction: true,
                     onClick: () => this.tableRef.current && this.tableRef.current.onQueryChange(),
                  },
               ]}

            />
         </Container>
      )
   }
}

export default Page
